import styled from 'styled-components';

interface EstudoProps {
  aberto: boolean;
}

export const Estudo = styled.div<EstudoProps>`
  border-top: ${({ aberto }) =>
    aberto ? '1px solid var(--fundo)' : '1px solid var(--branco)'};
  display: flex;
  justify-content: center;
  transition: all 0.2s ease;
  background: ${({ aberto }) => (aberto ? 'var(--branco)' : 'var(--fundo)')};
  color: ${({ aberto }) => (aberto ? 'var(--fundo)' : 'var(--branco)')};

  &:hover {
    background: var(--branco);
    color: var(--fundo);
  }

  .centralizar {
    display: flex;
    flex-direction: column;
    max-width: 1350px;
    width: 100%;
  }
`;

export const EstudoCima = styled.div<EstudoProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 5rem;
  transition: all 0.2s ease;

  cursor: pointer;

  h1 {
    font-size: 3rem;
    font-weight: 500;
  }

  svg {
    font-size: 4rem;
    transition: all 0.2s ease;
    transform: ${({ aberto }) => (aberto ? 'rotate(180deg);' : 'rotate(0);')};
  }

  @media (max-width: 1089px) {
    h1 {
      font-size: 1.5rem;
    }
    svg {
      font-size: 2rem;
    }
  }

  &:hover {
    opacity: 0.9;
  }
`;

export const EstudoBaixo = styled.div<EstudoProps>`
  display: flex;
  transition: all 0.4s ease;
  max-height: ${({ aberto }) => (aberto ? '150rem' : '0px')};
  color: var(--fundo);
  gap: 2rem;
  padding: ${({ aberto }) => (aberto ? '2rem 5rem' : '0 5rem')};
  flex-wrap: wrap;
  justify-content: center;

  img {
    width: 15rem;
    border-radius: 0.5rem;
    transition: all 0.5s ease;
    margin-top: 0.1rem;

    &:hover {
      z-index: 1;
      transform: scale(2.8);

      @media (max-width: 1089px) {
        transform: scale(1.8);
      }
    }
  }
`;
