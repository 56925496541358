import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Botao } from '../../Components/Botao';
import { Input } from '../../Components/Input';
import { Titulo } from '../../Components/Titulo';
import { db } from '../../config/firebase';
import titulos from '../../utils/titulos.json';
import {
  Container,
  Form,
  Cores,
  Cor,
  ChromePicker,
  Preview
} from './styles';

import { addDoc, collection, doc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore';

interface PessoasProps {
  id: string;
  pessoa: string;
  cor: string;
  ativo: number;
}

export function PaginaAdicionarPessoa() {
  const [pessoa, setPessoa] = useState('');
  const [estaCarregando, setEstaCarregando] = useState(false);
  const [pessoas, setPessoas] = useState<PessoasProps[]>([]);
  const [cor, setCor] = useState('#ffffff');

  function PegarPessoas() {
    const pessoasCollection = collection(db, 'pessoas');

    onSnapshot(pessoasCollection, (snapshot) => {
      const pessoaArray: PessoasProps[] = [];
      snapshot.forEach((doc) => {
        pessoaArray.push({ id: doc.id, pessoa: doc.data().pessoa, cor: doc.data().cor, ativo: doc.data().ativo });
      });
      setPessoas(pessoaArray);
    });
  }

  async function verificaExistenciaPessoa() {
    const caixaBaixaPessoa = pessoa.toLowerCase();
    const q = query(
      collection(db, 'pessoas'),
      where('pessoa', '==', caixaBaixaPessoa)
    );
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  }

  async function lidarComCriacaoPessoa(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setEstaCarregando(true);
    const caixaBaixaPessoa = pessoa.toLowerCase();
    if (pessoa.length >= 2) {
      const pessoaExiste = await verificaExistenciaPessoa();
      if (!pessoaExiste) {
        addDoc(collection(db, 'pessoas'), {
          pessoa: caixaBaixaPessoa,
          ativo: 1,
          cor
        })
          .then(() => {
            addDoc(collection(db, 'logs'), {
              titulo: 'Pessoa adicionada',
              descricao: `Pessoa <span class='titulo'>${caixaBaixaPessoa}</span> foi adicionada`,
              tipo: 'pessoa',
              data: new Date()
            }).then(() => {
              toast.success('Pessoa adicionada com sucesso.');
              setPessoa('');
              setCor('#ffffff');
            });
          })
          .catch(() => {
            toast.error('Aconteceu algum erro. Tente novamente mais tarde.');
          })
          .finally(() => {
            setEstaCarregando(false);
          });
      } else {
        toast.warning(`${caixaBaixaPessoa} já existe.`);
        setEstaCarregando(false);
      }
    } else {
      toast.warning('Insira um nome para a pessoa. Mínimo de 2 caracteres.');
      setEstaCarregando(false);
    }
  }

  function LidarComAtivo(e: string, id: string, nome: string) {
    const pessoaRef = doc(db, 'pessoas', id);

    if (e === '1')
      updateDoc(pessoaRef, {
        ativo: 1
      })
        .then(() => {
          addDoc(collection(db, 'logs'), {
            titulo: 'Pessoa ativada',
            descricao: `Pessoa <span class='info'>${nome}</span> foi <span class='titulo'>ativada</span>.`,
            tipo: 'pessoa',
            data: new Date()
          }).then(() => {
            toast.success('Pessoa ativada com sucesso.');
          });
        })
        .catch(() => {
          toast.error('Aconteceu algum erro. Tente novamente mais tarde.');
        });
    else {
      updateDoc(pessoaRef, {
        ativo: 0
      })
        .then(() => {
          addDoc(collection(db, 'logs'), {
            titulo: 'Pessoa desativada',
            descricao: `Pessoa <span class='info'>${nome}</span> foi <span class='tituloAntigo'>desativada</span>.`,
            tipo: 'pessoa',
            data: new Date()
          }).then(() => {
            toast.success('Pessoa desativada com sucesso.');
          });
        })
        .catch(() => {
          toast.error('Aconteceu algum erro. Tente novamente mais tarde.');
        });
    }
  }

  useEffect(() => {
    document.title = titulos.texto.adicionar_pessoa;
    PegarPessoas();
  }, []);

  return (
    <Container>
      <Titulo>{titulos.texto.adicionar_pessoa}</Titulo>
      <Form onSubmit={lidarComCriacaoPessoa}>
        <Input
          titulo="Nome do pessoa"
          value={pessoa}
          onChange={(e) => setPessoa(e.target.value)}
        />
        <Cores>
          <ChromePicker
            color={cor}
            onChangeComplete={(color) => setCor(color.hex)}
          />
        </Cores>

        {pessoa && (
          <Preview style={{ backgroundColor: cor }}>
            {pessoa}
          </Preview>
        )}
        <Botao
          cor="primario"
          texto="Adicionar pessoa"
          estaCarregando={estaCarregando}
        />
      </Form>

      <Titulo style={{ margin: '1rem 0' }}>Lista de pessoas</Titulo>
      <Cores>
        {pessoas
          .map((pessoa) => (
            <li key={pessoa.id}>
              <Cor cor={pessoa.cor}>
                {pessoa.pessoa}
                <div className='select'>
                  <select
                    id="selecao"
                    value={pessoa.ativo}
                    onChange={(e) => LidarComAtivo(e.target.value, pessoa.id, pessoa.pessoa)}
                    style={{ color: `${pessoa.ativo === 0 ? 'var(--vermelho)' : 'var(--primario)'}` }}
                  >
                    <option value={1} style={{ color: 'var(--primario)' }}>
                      Ativado
                    </option>
                    <option value={0} style={{ color: 'var(--vermelho)' }}>
                      Desativado
                    </option>
                  </select>
                </div>
              </Cor>
            </li>
          ))}
      </Cores>
    </Container>
  );
}
