import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  min-height: 100vh;
  padding: 1rem;
  align-items: center;
  flex-direction: column;


`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 2rem;
  flex-wrap: wrap;
  align-items: center;
`;

export const Util = styled.div`
  display: flex;
  width: 63.5%;
  align-items: center;
  justify-content: right;
  gap: 1rem;
  margin-top: 2rem;

  svg {
    font-size: 1.2rem;
    color: var(--azul);

    cursor: pointer;

    transition: all 0.2s ease;

    &:hover {
      filter: brightness(1.4);
    }
  }

  .svgDelete {
    color: var(--vermelho);
    font-size: 1.4rem;
  }
`;
