import React from 'react';
import { FiLinkedin, FiGithub, FiMail } from 'react-icons/fi';

import { ContatoContainer, Conteudo } from './styles';

export function ContatoSection() {
  return (
    <ContatoContainer id="contato">
      <Conteudo
        href="https://www.linkedin.com/in/gustacastro/"
        target="_blank"
        rel="noreferrer"
      >
        <FiLinkedin />
        <span>/gustacastro</span>
      </Conteudo>
      <Conteudo
        href="https://github.com/gustacastro"
        target="_blank"
        rel="noreferrer"
      >
        <FiGithub />
        <span>/gustacastro</span>
      </Conteudo>
      <Conteudo
        onClick={() =>
          window.open('mailto:gusncastro@gmail.com?subject=&body=')
        }
      >
        <FiMail />
        <span>gusncastro@gmail.com</span>
      </Conteudo>
    </ContatoContainer>
  );
}
