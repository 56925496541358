import styled from 'styled-components';

interface BotaoProps {
  cor: 'vermelho' | 'laranja' | 'verde' | 'azul' | 'cinza' | 'primario';
}

export const Container = styled.button<BotaoProps>`
  padding: 0rem 1rem;
  background: ${({ cor }) => `var(--${cor})`};
  border: none;
  border-radius: 0.2rem;
  height: 3.2rem;

  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--fundo);
  font-size: 1rem;
  gap: 0.5rem;

  svg {
    font-size: 1.4rem;
  }
`;
