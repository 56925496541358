import React, { TextareaHTMLAttributes } from 'react';

import { Container } from './styles';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  texto: string;
  style?: any;
}

export function TextArea({ texto, style, ...rest }: TextAreaProps) {
  return (
    <Container style={style}>
      <textarea {...rest}></textarea>
      <label>{texto}</label>
    </Container>
  );
}
