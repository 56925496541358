import React, { useEffect, useState } from 'react';
import { BiRightArrow } from 'react-icons/bi';
import { TailSpin } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

import { Titulo } from '../../Components/Titulo';
import { db } from '../../config/firebase';
import titulos from '../../utils/titulos.json';
import {
  Container,
  BancosContainer,
  ConteudoContainer,
  Esquerda,
  NomeBanco,
  Valor,
  Direita,
  Carregando
} from './styles';

import { collection, getDocs } from 'firebase/firestore';

interface BancoProps {
  id: string;
  banco: string;
  cor: string;
  valor: string;
  pessoa: string;
}

interface BancoFatura {
  dados: BancoProps[];
}

export function PaginaFaturaAtual() {
  const [bancos, setBancos] = useState([]);
  const [estaCarregando, setEstaCarregando] = useState(true);
  const [valorTotalPorBanco, setValorTotalPorBanco] = useState<{
    [key: string]: number;
  }>({});
  const navigate = useNavigate();

  async function buscarBancos() {
    const bancosCollection = collection(db, 'bancos');
    const bancosQuerySnapshot = await getDocs(bancosCollection);
    const bancosArray: any = [];

    bancosQuerySnapshot.forEach((doc) => {
      const bancoData = doc.data();
      if (bancoData.ativo === 1) {
        bancosArray.push({ id: doc.id, ...bancoData });
      }
    });

    setBancos(bancosArray);

    const promises = bancosArray.map(async (banco: BancoProps) => {
      const dadosCollection = collection(db, `fatura_atual_${banco.banco}`);
      const dadosQuerySnapshot = await getDocs(dadosCollection);
      const dadosArray: any = [];

      dadosQuerySnapshot.forEach((doc) => {
        dadosArray.push({ id: doc.id, ...doc.data() });
      });

      const totalValor = dadosArray.reduce(
        (total: number, banco: BancoProps) => {
          return total + parseFloat(banco.valor);
        },
        0
      );

      setValorTotalPorBanco((prevValorTotal) => ({
        ...prevValorTotal,
        [banco.banco]: totalValor
      }));
    });

    await Promise.all(promises);

    setEstaCarregando(false);
  }

  useEffect(() => {
    buscarBancos();
    document.title = titulos.texto.fatura_atual;
  }, []);

  return (
    <Container>
      <Titulo>{titulos.texto.fatura_atual}</Titulo>

      {estaCarregando ? (
        <Carregando>
          <TailSpin height="50" width="50" color="#ffffff" />
        </Carregando>
      ) : (
        <BancosContainer>
          {bancos.map((banco: BancoProps) => (
            <ConteudoContainer key={banco.id} cor={banco.cor}>
              <Esquerda>
                <NomeBanco>{banco.banco}</NomeBanco>

                <Valor>
                  <span>Total:</span>
                  <h3>
                    {valorTotalPorBanco[banco.banco] !== undefined &&
                      !isNaN(valorTotalPorBanco[banco.banco])
                      ? `R$${valorTotalPorBanco[banco.banco].toFixed(2)}`
                      : 'Valor Inválido'}
                  </h3>
                </Valor>
              </Esquerda>
              <Direita>
                <BiRightArrow
                  onClick={() => navigate(`/faturaatual/${banco.banco}`)}
                />
              </Direita>
            </ConteudoContainer>
          ))}
        </BancosContainer>
      )}
    </Container>
  );
}
