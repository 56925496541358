import styled from 'styled-components';

export const ConteudoContainer = styled.section`
  width: 100%;
  transition: all 0.2s ease;
  background-color: var(--branco);
  display: flex;
  justify-content: center;
`;

export const TecIconsContainer = styled.div`
  color: var(--fundo);
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  transition: all 3s ease;
  padding: 3rem;
  width: 100%;
`;
