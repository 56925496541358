import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Botao } from '../../Components/Botao';
import { Input } from '../../Components/Input';
import { Titulo } from '../../Components/Titulo';
import { db } from '../../config/firebase';
import titulos from '../../utils/titulos.json';
import {
  Container,
  Form,
  Cores,
  Cor,
  ChromePicker,
  Preview
} from './styles';

import { addDoc, collection, doc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore';

interface BancoProps {
  id: string;
  banco: string;
  cor: string;
  ativo: number;
}

export function PaginaAdicionaBanco() {
  const [banco, setBanco] = useState('');
  const [estaCarregando, setEstaCarregando] = useState(false);
  const [bancos, setBancos] = useState<BancoProps[]>([]);
  const [cor, setCor] = useState('#ffffff');

  function PegarBanco() {
    const bancosCollection = collection(db, 'bancos');

    onSnapshot(bancosCollection, (snapshot) => {
      const bancosArray: BancoProps[] = [];
      snapshot.forEach((doc) => {
        bancosArray.push({ id: doc.id, banco: doc.data().banco, cor: doc.data().cor, ativo: doc.data().ativo });
      });
      setBancos(bancosArray);
    });
  }

  async function verificaExistenciaDoBanco() {
    const caixaBaixaBanco = banco.toLowerCase();
    const q = query(
      collection(db, 'bancos'),
      where('banco', '==', caixaBaixaBanco)
    );
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  }

  async function lidarComCriacaoBanco(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setEstaCarregando(true);
    const caixaBaixaBanco = banco.toLowerCase();
    if (banco.length >= 4) {
      const bancoJaExiste = await verificaExistenciaDoBanco();
      if (!bancoJaExiste) {
        addDoc(collection(db, 'bancos'), {
          banco: caixaBaixaBanco,
          ativo: 1,
          cor
        })
          .then(() => {
            addDoc(collection(db, 'logs'), {
              titulo: 'Banco adicionado',
              descricao: `O banco <span class='titulo'>${caixaBaixaBanco}</span> foi adicionado.`,
              tipo: 'banco',
              data: new Date()
            }).then(() => {
              toast.success('Banco adicionado com sucesso.');
              setBanco('');
              setCor('#ffffff');
            });
          })
          .catch(() => {
            toast.error('Aconteceu algum erro. Tente novamente mais tarde.');
          })
          .finally(() => {
            setEstaCarregando(false);
          });
      } else {
        toast.warning(`O banco ${caixaBaixaBanco} já existe.`);
        setEstaCarregando(false);
      }
    } else {
      toast.warning('Insira um nome para o banco. Mínimo de 4 caracteres.');
      setEstaCarregando(false);
    }
  }

  function LidarComAtivo(e: string, id: string, nome: string) {
    const bancoRef = doc(db, 'bancos', id);

    if (e === '1')
      updateDoc(bancoRef, {
        ativo: 1
      })
        .then(() => {
          addDoc(collection(db, 'logs'), {
            titulo: 'Banco ativado',
            descricao: `O banco <span class='info'>${nome}</span> foi <span class='titulo'>ativado</span>.`,
            tipo: 'banco',
            data: new Date()
          }).then(() => {
            toast.success('Banco ativado com sucesso.');
          });
        })
        .catch(() => {
          toast.error('Aconteceu algum erro. Tente novamente mais tarde.');
        });
    else {
      updateDoc(bancoRef, {
        ativo: 0
      })
        .then(() => {
          addDoc(collection(db, 'logs'), {
            titulo: 'Banco desativado',
            descricao: `O banco <span class='info'>${nome}</span> foi <span class='tituloAntigo'>desativado</span>.`,
            tipo: 'banco',
            data: new Date()
          }).then(() => {
            toast.success('Banco desativado com sucesso.');
          });
        })
        .catch(() => {
          toast.error('Aconteceu algum erro. Tente novamente mais tarde.');
        });
    }
  }

  useEffect(() => {
    document.title = titulos.texto.adicionar_banco;
    PegarBanco();
  }, []);

  return (
    <Container>
      <Titulo>{titulos.texto.adicionar_banco}</Titulo>
      <Form onSubmit={lidarComCriacaoBanco}>
        <Input
          titulo="Nome do banco"
          value={banco}
          onChange={(e) => setBanco(e.target.value)}
        />
        <Cores>
          <ChromePicker
            color={cor}
            onChangeComplete={(color) => setCor(color.hex)}
          />
        </Cores>

        {banco && (
          <Preview style={{ backgroundColor: cor }}>
            {banco}
          </Preview>
        )}
        <Botao
          cor="primario"
          texto="Adicionar banco"
          estaCarregando={estaCarregando}
        />
      </Form>

      <Titulo style={{ margin: '1rem 0' }}>Lista de bancos</Titulo>
      <Cores>
        {bancos
          .map((banco) => (
            <li key={banco.id}>
              <Cor cor={banco.cor}>
                {banco.banco}
                <div className='select'>
                  <select
                    id="selecao"
                    value={banco.ativo}
                    onChange={(e) => LidarComAtivo(e.target.value, banco.id, banco.banco)}
                    style={{ color: `${banco.ativo === 0 ? 'var(--vermelho)' : 'var(--primario)'}` }}
                  >
                    <option value={1} style={{ color: 'var(--primario)' }}>
                      Ativado
                    </option>
                    <option value={0} style={{ color: 'var(--vermelho)' }}>
                      Desativado
                    </option>
                  </select>
                </div>
              </Cor>
            </li>
          ))}
      </Cores>
    </Container>
  );
}
