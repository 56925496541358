import { Link } from 'react-scroll';

import styled from 'styled-components';

export const ConteudoContainer = styled.section`
  width: 100%;
  transition: all 0.2s ease;
  background-color: var(--fundo);
  display: flex;
  justify-content: center;
`;

export const HomeContainer = styled.div`
  width: 100%;
  max-width: 1350px;
  height: 100vh;

  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 1rem;

  position: relative;
`;

export const HomeEsquerdo = styled.div`
  flex: 1;
  min-width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;

  @media (max-width: 1089px) {
    h1 {
      font-size: 2rem;
    }
    padding: 0 1.5rem;
  }

  h1 {
    font-weight: 700;
    font-size: 2.7rem;
  }

  h2 {
    font-weight: 500;
    color: var(--primario);
  }

  button {
    margin-top: 2rem;
    align-self: center;
    color: var(--fundo);
    width: 10rem;
  }
`;

export const HomeDireito = styled.div`
  flex: 1;
  min-width: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10rem;

  .img {
    width: 8rem;
    height: 8rem;
  }

  @media (max-width: 1089px) {
    display: none;
  }

  svg {
    margin-bottom: 4rem;
  }
`;

export const Socials = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;

  a {
    color: var(--branco);
    cursor: none;

    svg {
      font-size: 1.2rem;
      transition: all 0.2s ease;
      cursor: pointer;

      &:hover {
        color: var(--primario);
      }
    }
  }
`;

export const ScrollBaixo = styled(Link)`
  position: absolute;
  bottom: 2rem;
  left: 6rem;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  gap: 0.5rem;
  cursor: pointer;

  svg {
    color: var(--primario);
  }

  svg:first-of-type {
    font-size: 1.5rem;
  }

  &:hover {
    transform: scale(1.1);
  }
`;
