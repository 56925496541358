import React, { ReactNode, CSSProperties } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { Container } from './styles';

interface TituloProps {
  children: ReactNode;
  style?: CSSProperties;
  voltar?: boolean;
}

export function Titulo({ children, style, voltar }: TituloProps) {
  const navigate = useNavigate();

  return (
    <Container style={style}>
      {voltar && <MdArrowBack onClick={() => navigate(-1)} />}

      <h1>{children}</h1>
    </Container>
  );
}
