import styled from 'styled-components';

export const ConteudoContainer = styled.section`
  width: 100%;
  transition: all 0.2s ease;
  background-color: var(--fundo);
  display: flex;
  justify-content: center;

  .titulo {
    max-width: 1350px;
    margin: 0 auto;
    font-weight: 600;
    font-size: 1.5rem;
    width: 100%;
    padding: 0 5rem;
  }
`;

export const SobreContainer = styled.section`
  width: 100%;
  max-width: 1350px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5rem;

  h1 {
    font-weight: 600;
    font-size: 1.5rem;
  }

  span {
    font-size: 4rem;
  }

  @media (max-width: 1089px) {
    h1 {
      font-size: 1rem;
    }

    span {
      font-size: 2rem;
    }
  }

  button {
    margin-top: 2rem;
    align-self: center;
    color: var(--fundo);
    width: 10rem;
  }
`;
