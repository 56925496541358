import React, { useEffect, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { PaginaLoading } from '../Components/PaginaLoading';
import { SideBar } from '../Components/SideBar';

import { getAuth, onAuthStateChanged } from 'firebase/auth';

interface NaoEstaAutenticadoProps {
  children: ReactNode;
}

export function NaoEstaAutenticado({ children }: NaoEstaAutenticadoProps) {
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const AuthCheck = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoading(false);
      } else {
        navigate('/login');
      }
    });

    return () => AuthCheck();
  }, [auth, navigate]);

  if (loading) return <PaginaLoading />;

  return (
    <div style={{ display: 'flex' }}>
      <SideBar />
      {children}
    </div>
  );
}
