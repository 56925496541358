import styled from 'styled-components';

export const Container = styled.div`
  background: var(--fundo);
  color: var(--branco);
  border-width: 0px;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  min-width: 20rem;
  position: relative;
  border: 1px solid var(--cinza);

  input {
    border: none;
    background: transparent;
    flex: 1;
    color: var(--branco);
    padding: 1rem;
  }

  label {
    position: absolute;
    color: var(--branco);
    opacity: 0.8;
    pointer-events: none;
    left: 1rem;
    top: 1rem;
    font-size: 0.9rem;

    transition: all 0.2s ease;
  }

  input:focus,
  input:not([value='']) {
    border-color: var(--branco);
  }

  input:focus ~ label,
  input:not([value='']) ~ label {
    transform: scale(0.9) translateY(-2.2rem);
    background-color: var(--fundo);
    padding: 0.4rem 0.6rem;
    opacity: 1;
    border-radius: 0.2rem;
  }

  svg {
    font-size: 1.3rem;
    margin: 0 1rem;
  }
`;
