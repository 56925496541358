import React, { createContext, useState, useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { Analytics } from '@vercel/analytics/react';

import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import { DarkTheme, LightTheme, GLobalStyles } from './global/styles/global';
import { Router } from './routes/router';

import 'react-toastify/dist/ReactToastify.css';

export const ThemeContext = createContext({} as any);

function App() {
  const gustavoTheme = localStorage.getItem('gustavoTheme');
  const [theme, setTheme] = useState(gustavoTheme ?? 'dark');

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <StyledComponentsThemeProvider
        theme={theme === 'dark' ? DarkTheme : LightTheme}
      >
        <div className="App">
          <GLobalStyles />
          <Analytics />
          <Router />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </div>
      </StyledComponentsThemeProvider>
    </ThemeContext.Provider>
  );
}

export default App;
