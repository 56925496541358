import styled from 'styled-components';

interface BancoProps {
  cor: string;
}

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  min-height: 100vh;
  padding: 1rem;
  align-items: center;
  flex-direction: column;
`;

export const Carregando = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BancosContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 5rem 0;
  gap: 2rem;
  flex-wrap: wrap;

  @media (max-width: 728px) {
    justify-content: center;
  }
`;

export const Banco = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.2rem;
`;

export const Fatura = styled.div<BancoProps>`
  display: flex;
  width: 100%;
  background: ${({ cor }) => cor};
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 1rem;
  border-radius: 0.2rem;

  text-transform: capitalize;

  span {
    white-space: nowrap;
    display: flex;
    align-items: center;

    svg {
      margin-left: 0.3rem;
      font-size: 1.2rem;
    }
  }

  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    filter: brightness(1.2);
  }
`;
