import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Botao } from '../../Components/Botao';
import { Titulo } from '../../Components/Titulo';
import { db } from '../../config/firebase';
import titulos from '../../utils/titulos.json';
import {
  Container,
  Form,
  Pessoas
} from './styles';
import { TiDelete } from "react-icons/ti";

import { addDoc, collection, deleteDoc, doc, getDocs, onSnapshot, query, where } from 'firebase/firestore';

interface PessoaCalProps {
  id: string;
  nome: string;
}

interface PessoasProps {
  id: string;
  pessoa: string;
  ativo: number;
}

export function PaginaGerenciarCal() {
  const [pessoasCal, setPessoasCal] = useState<PessoaCalProps[]>([]);
  const [pessoaParaAdicionar, setPessoaParaAdicionar] = useState('');
  const [estaCarregando, setEstaCarregando] = useState(false);
  const [pessoas, setPessoas] = useState<PessoasProps[]>([]);

  async function PegarPessoas() {
    const pessoasCollection = collection(db, 'pessoas');

    onSnapshot(pessoasCollection, (snapshot) => {
      const pessoasArray: PessoasProps[] = [];
      snapshot.forEach((doc) => {
        pessoasArray.push({ id: doc.id, pessoa: doc.data().pessoa, ativo: doc.data().ativo });
      });
      setPessoas(pessoasArray);
    });
  }

  async function PegarPessoasCal() {
    const pessoasCalCollection = collection(db, 'pessoasCal');

    onSnapshot(pessoasCalCollection, (snapshot) => {
      const pessoasCalArray: PessoaCalProps[] = [];
      snapshot.forEach((doc) => {
        pessoasCalArray.push({ id: doc.id, nome: doc.data().nome });
      });
      setPessoasCal(pessoasCalArray);
    });
  }

  async function verificaExistencia() {
    const pessoaParaAdicionarLowerCase = pessoaParaAdicionar.toLowerCase();
    const q = query(
      collection(db, 'pessoasCal'),
      where('nome', '==', pessoaParaAdicionarLowerCase)
    );
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  }

  async function lidarComCriacao(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setEstaCarregando(true);
    const pessoaParaAdicionarLowerCase = pessoaParaAdicionar.toLowerCase();
    if (pessoaParaAdicionar) {
      const pessoaJaExiste = await verificaExistencia();
      if (!pessoaJaExiste) {
        addDoc(collection(db, 'pessoasCal'), {
          nome: pessoaParaAdicionarLowerCase,
        })
          .then(() => {
            addDoc(collection(db, 'logs'), {
              descricao: `Adicionou <span class='titulo'>${pessoaParaAdicionarLowerCase}</span> na lista de pessoas.`,
              titulo: 'Gerencimento Cal',
              tipo: 'cal',
              data: new Date()
            }).then(() => {
              toast.success('Pessoa adicionada com sucesso.');
            });
          })
          .catch(() => {
            toast.error('Aconteceu algum erro. Tente novamente mais tarde.');
          })
          .finally(() => {
            setEstaCarregando(false);
          });
      } else {
        toast.warning(`A ${pessoaParaAdicionar} já existe.`);
        setEstaCarregando(false);
      }
    } else {
      toast.warning('Selecione uma pessoa para adicionar.');
      setEstaCarregando(false);
    }
  }

  function DeletarPessoa(id: string, nome: string) {
    const confirmacao = window.confirm('Deseja remover essa pessoa?');
    if (confirmacao) {
      const pessoaRef = doc(db, 'pessoasCal', id);
      deleteDoc(pessoaRef)
        .then(() => {
          addDoc(collection(db, 'logs'), {
            descricao: `Removeu <span class='tituloAntigo'>${nome}</span> da lista de pessoas.`,
            titulo: 'Gerencimento Cal',
            tipo: 'cal',
            data: new Date()
          }).then(() => {
            toast.success('Pessoa deletada com sucesso.');
          });
        })
        .catch(() => {
          toast.error('Aconteceu algum erro. Tente novamente mais tarde.');
        });
    }
  }

  useEffect(() => {
    document.title = titulos.texto.gerencimento_cal;
    PegarPessoas();
    PegarPessoasCal();
  }, []);

  return (
    <Container>
      <Form onSubmit={lidarComCriacao}>
        <Titulo style={{ marginBottom: '2rem' }}>Adicionar Pessoa</Titulo>
        <div className='select'>
          <select
            id="selecao"
            value={pessoaParaAdicionar}
            onChange={(e) => setPessoaParaAdicionar(e.target.value)}
          >
            {pessoas.filter((pessoa) => pessoa.ativo === 1)
              .map((pessoa: PessoasProps) => (
                <option value={pessoa.pessoa} key={pessoa.id}>
                  {pessoa.pessoa}
                </option>
              ))}
          </select>
        </div>

        <Botao
          cor="primario"
          texto="Adicionar pessoa"
          estaCarregando={estaCarregando}
        />
      </Form>
      <Titulo style={{ margin: '2rem 0' }}>Lista de pessoas</Titulo>
      <Pessoas>
        {
          pessoasCal.map((pessoa: PessoaCalProps) => (
            <h2 key={pessoa.id}>{pessoa.nome}
              <TiDelete onClick={() => DeletarPessoa(pessoa.id, pessoa.nome)} />
            </h2>
          ))
        }
      </Pessoas>
    </Container>
  );
}
