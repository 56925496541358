import React, { useEffect, useState } from 'react';
import { BiRightArrow } from 'react-icons/bi';
import { TailSpin } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

import { Titulo } from '../../Components/Titulo';
import { db } from '../../config/firebase';
import titulos from '../../utils/titulos.json';
import {
  Container,
  Carregando,
  BancosContainer,
  Banco,
  Fatura
} from './styles';

import { collection, getDocs } from 'firebase/firestore';

interface BancoProps {
  banco: string;
  id: string;
  cor: string;
}

interface FaturaProps {
  banco: string;
  mes: string;
  ano: number;
  id: string;
  transacoes: [];
}

export function PaginaFaturasFechadas() {
  const [bancos, setBancos] = useState<BancoProps[]>([]);
  const [estaCarregandoBancos, setEstaCarregandoBancos] = useState(true);
  const [faturas, setFaturas] = useState<FaturaProps[]>([]);

  const navigate = useNavigate();

  async function buscarBancos() {
    const bancosCollection = collection(db, 'bancos');
    const bancosQuerySnapshot = await getDocs(bancosCollection);
    const bancosArray: any = [];

    bancosQuerySnapshot.forEach((doc) => {
      bancosArray.push({ id: doc.id, ...doc.data() });
    });

    setBancos(bancosArray);

    setEstaCarregandoBancos(false);
  }

  async function buscarFaturasPorBanco() {
    const faturasArray: any = [];
    const nomeColecao = `faturas_fechadas`;
    const faturaCollection = collection(db, nomeColecao);
    const faturaQuerySnapshot = await getDocs(faturaCollection);

    faturaQuerySnapshot.forEach((doc) => {
      faturasArray.push({ id: doc.id, ...doc.data() });
    });

    const mesParaNumero = (mes: string): number => {
      const meses = [
        'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
        'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
      ];
      return meses.indexOf(mes) + 1;
    };

    faturasArray.sort((a: FaturaProps, b: FaturaProps) => {
      const anoDiff = b.ano - a.ano;
      if (anoDiff !== 0) return anoDiff;
      const mesA = mesParaNumero(a.mes);
      const mesB = mesParaNumero(b.mes);
      return mesB - mesA;
    });

    setFaturas(faturasArray);
    console.log(faturasArray)
  }



  useEffect(() => {
    buscarBancos();
    document.title = titulos.texto.faturas_fechadas;
  }, []);

  useEffect(() => {
    buscarFaturasPorBanco();
  }, [bancos, estaCarregandoBancos]);

  return (
    <Container>
      <Titulo>{titulos.texto.faturas_fechadas}</Titulo>

      {estaCarregandoBancos || faturas.length == 0 ? (
        <Carregando>
          <TailSpin height="50" width="50" color="#ffffff" />
        </Carregando>
      ) : (
        <BancosContainer>
          {bancos.map((banco: BancoProps) => (
            <Banco key={banco.id}>
              {faturas.map(
                (fatura: FaturaProps) =>
                  banco.banco == fatura.banco && (
                    <Fatura
                      cor={banco.cor}
                      key={fatura.id}
                      onClick={() =>
                        navigate('/faturafechada', {
                          state: {
                            nomeBanco: fatura.banco,
                            mes: fatura.mes,
                            ano: fatura.ano,
                            transacoes: fatura.transacoes
                          }
                        })
                      }
                    >
                      <h3>{banco.banco}</h3>
                      <span>
                        {fatura.mes} {fatura.ano} <BiRightArrow />
                      </span>
                    </Fatura>
                  )
              )}
            </Banco>
          ))}
        </BancosContainer>
      )}
    </Container>
  );
}
