import React, { useCallback } from 'react';
import { type Editor } from '@tiptap/react'
import {
  Bold,
  Strikethrough,
  Italic,
  ListOrdered,
  Heading2,
  Link2,
  Unlink2,
  Underline,
} from 'lucide-react'

import {
  Container,
  Botao,
  Cores
} from './styles'
import { TbBlockquote } from 'react-icons/tb';

interface ToolbarProps {
  editor: Editor | null;
}

export function Toolbar({ editor }: ToolbarProps) {
  const setLink = useCallback(() => {
    if (!editor) return

    const previousUrl = editor.getAttributes('link').href
    let url = window.prompt('URL', previousUrl)

    if (url === null) {
      return
    }

    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run()
      return
    }

    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'http://' + url;
    }

    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
  }, [editor])

  if (!editor) return null;

  return (
    <Container>
      <Botao
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        type='button'
        className={editor.isActive('heading', { level: 2 }) ? 'ativado' : ''}
      >
        <Heading2 className='h-4 w-4' />
      </Botao>
      <Botao
        onClick={() => editor.chain().focus().toggleBold().run()}
        type='button'
        className={editor.isActive('bold') ? 'ativado' : ''}
      >
        <Bold className='h-4 w-4' />
      </Botao>
      <Botao
        onClick={() => editor.chain().focus().toggleItalic().run()}
        type='button'
        className={editor.isActive('italic') ? 'ativado' : ''}
      >
        <Italic className='h-4 w-4' />
      </Botao>
      <Botao
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        type='button'
        className={editor.isActive('underline') ? 'ativado' : ''}
      >
        <Underline className='h-4 w-4' />
      </Botao>
      <Botao
        onClick={() => editor.chain().focus().toggleStrike().run()}
        type='button'
        className={editor.isActive('strike') ? 'ativado' : ''}
      >
        <Strikethrough className='h-4 w-4' />
      </Botao>
      <Botao
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        type='button'
        className={editor.isActive('blockquote') ? 'ativado' : ''}
      >
        <TbBlockquote className='h-4 w-4' />
      </Botao>

      <Botao
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        type='button'
        className={editor.isActive('orderedList') ? 'ativado' : ''}
      >
        <ListOrdered className='h-4 w-4' />
      </Botao>

      <Botao
        onClick={(event) => {
          event.preventDefault();
          setLink();
        }}
        className={editor.isActive('link') ? 'ativo' : ''}
      >
        <Link2 className='h-4 w-4' />
      </Botao>
      <Botao
        onClick={() => editor.chain().focus().unsetLink().run()}
        disabled={!editor.isActive('link')}
      >
        <Unlink2 className='h-4 w-4' />
      </Botao>

      <Botao
        onClick={() => editor.chain().focus()
          .toggleHighlight({ color: 'var(--vermelho)' })
          .run()}
        type='button'
        className={editor.isActive('highlight', { color: 'var(--vermelho)' }) ? 'ativado' : ''}
      >
        <div style={{ background: 'var(--vermelho)' }} />
      </Botao>

      <Botao
        onClick={() => editor.chain().focus()
          .toggleHighlight({ color: 'var(--azul)' })
          .run()}
        type='button'
        className={editor.isActive('highlight', { color: 'var(--azul)' }) ? 'ativado' : ''}
      >
        <div style={{ background: 'var(--azul)' }} />
      </Botao>

      <Botao
        onClick={() => editor.chain().focus()
          .toggleHighlight({ color: 'var(--primario)' })
          .run()}
        type='button'
        className={editor.isActive('highlight', { color: 'var(--primario)' }) ? 'ativado' : ''}
      >
        <div style={{ background: 'var(--primario)' }} />
      </Botao>

      <Botao
        onClick={() => editor.chain().focus()
          .toggleHighlight({ color: 'var(--amarelo)' })
          .run()}
        type='button'
        className={editor.isActive('highlight', { color: 'var(--amarelo)' }) ? 'ativado' : ''}
      >
        <div style={{ background: 'var(--amarelo)' }} />
      </Botao>
      <Cores>
        <span>Texto</span>
        <input
          type="color"
          onInput={event => editor.chain().focus().setColor((event.target as HTMLInputElement).value).run()}
          value={editor.getAttributes('textStyle').color !== undefined ? editor.getAttributes('textStyle').color : '#ffffff'}
          data-testid="setColor"
          placeholder='Texto'
        />
      </Cores>
    </Container >
  );
}