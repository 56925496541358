import styled from 'styled-components';

interface CheckProps {
  ativo: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const CheckContainer = styled.div<CheckProps>`
  height: 1.5rem;
  width: 1.5rem;

  border: ${({ ativo }) =>
    ativo ? '1px solid var(--laranja) ' : '1px solid var(--cinza)'};
  margin-right: 1rem;
  border-radius: 0.2rem;

  transition: all 0.2s ease;
  background: ${({ ativo }) => (ativo ? 'var(--laranja)' : 'var(--fundo)')};
`;

export const Label = styled.span`
  font-size: 0.9rem;
`;
