import styled from 'styled-components';

export const ConteudoContainer = styled.section`
  width: 100%;
  transition: all 0.2s ease;
  background-color: var(--fundo);
  display: flex;
  justify-content: center;
  flex-direction: column;

  .titulo {
    margin: 0 auto;
    font-weight: 600;
    font-size: 1.5rem;
    width: fit-content;
    padding: 2rem 0;
    margin-top: 6rem;
  }
`;

export const Estudos = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
