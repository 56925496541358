import React, { useEffect, useState } from 'react';
import { MdOutlineDeleteForever, MdContentCopy } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-quill/dist/quill.snow.css';

import { Botao } from '../../Components/Botao';
import { Input } from '../../Components/Input';
import { Titulo } from '../../Components/Titulo';
import { db } from '../../config/firebase';
import titulos from '../../utils/titulos.json';
import { Container, Form, Util } from './styles';

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc
} from 'firebase/firestore';
import Tiptap from '../../Components/Tiptap';

export function PaginaAnotacao() {
  const location = useLocation();
  const id = location.state.id;
  const [titulo, setTitulo] = useState(
    location.state.id ? location.state.titulo : ''
  );
  const [descricao, setDescricao] = useState(
    location.state.id ? location.state.descricao : ''
  );
  const [estaCarregando, setEstaCarregando] = useState(false);
  const [copiar, setCopiar] = useState(false);
  const navigate = useNavigate();
  const tituloOriginal = location.state.id ? location.state.titulo : '';
  const descricaoOriginal = location.state.id ? location.state.descricao : '';

  function lidarComCriação(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setEstaCarregando(true);
    if (titulo.length >= 1 || descricao.length >= 1) {
      addDoc(collection(db, `anotacoes`), {
        titulo,
        descricao
      })
        .then(() => {
          addDoc(collection(db, `logs`), {
            titulo: 'Anotação criada',
            descricao: `Nova anotação criada: <span class="titulo">${titulo}</span><br/>
            Com a descrição: <div class="descricao">${descricao}</div>`,
            tipo: 'anotação',
            data: new Date()
          }).then(() => {
            toast.success(`Anotação criada com sucesso.`);
            navigate(-1);
          });
        })
        .catch(() => {
          toast.error('Aconteceu algum erro. Tente novamente mais tarde.');
        })
        .finally(() => {
          setEstaCarregando(false);
        });
    } else {
      toast.error('Título e descrição não pode estar vazio.');
      setEstaCarregando(false);
    }
  }

  async function lidarModificarAnotacao(
    event: React.FormEvent<HTMLFormElement>,
    id: string
  ) {
    event.preventDefault();
    if (titulo !== tituloOriginal || descricao !== descricaoOriginal) {
      setEstaCarregando(true);
      try {
        const docRef = doc(db, `anotacoes`, id);
        const updateData = {
          titulo,
          descricao
        };

        await updateDoc(docRef, updateData).then(() => {
          addDoc(collection(db, `logs`), {
            titulo: `Anotação modificada <span class="info">${tituloOriginal}</span>`,
            descricao: `${titulo !== tituloOriginal ? `Título da anotação modificada: <span class="tituloAntigo">
            ${tituloOriginal}</span><span class="titulo">${titulo}</span><br/>` : ''}  ${descricao !== descricaoOriginal
                ? `Descrição<div class="descricaoAntigo">${descricaoOriginal}</div><div 
              class="descricao">${descricao}</div>` : ''}`,
            tipo: 'anotação',
            data: new Date()
          }).then(() => {
            toast.success(`Anotação modificada com sucesso.`);
            navigate(-1);
          });
        });
      } catch (error) {
        toast.error('Aconteceu algum erro. Tente novamente mais tarde.');
      } finally {
        setEstaCarregando(false);
      }
    } else {
      toast.error('Nada foi modificado.');
    }
  }

  function lidarComDeletarTransacao() {
    const confirmacao = window.confirm('Deseja deletar essa anotação?');
    if (confirmacao) {
      const documentoRef = doc(db, `anotacoes`, id);

      deleteDoc(documentoRef)
        .then(() => {
          addDoc(collection(db, `logs`), {
            titulo: 'Anotação deletada',
            descricao: `Anotação deletada: <span class="tituloAntigo">${tituloOriginal}</span><br/>Com a descrição:<div class="descricaoAntigo">${descricaoOriginal}</div>`,
            tipo: 'anotação',
            data: new Date()
          }).then(() => {
            toast.success(`Anotação deletada com sucesso.`);
            navigate(-1);
          });
        })
        .catch(() => {
          toast.error('Aconteceu algum erro, tente novamente mais tarde.');
        });
    }
  }
  useEffect(() => {
    document.title = titulos.texto.anotacoes;
  }, []);

  return (
    <Container>
      <Titulo voltar>
        {id && !copiar ? 'Modificar Anotação' : 'Nova Anotação'}
      </Titulo>

      <Util>
        {id && !copiar && <MdContentCopy onClick={() => setCopiar(true)} />}

        {id && !copiar && (
          <MdOutlineDeleteForever
            onClick={() => lidarComDeletarTransacao()}
            className="svgDelete"
          />
        )}
      </Util>

      <Form
        onSubmit={
          id && !copiar
            ? (event) => lidarModificarAnotacao(event, id)
            : lidarComCriação
        }
      >
        <Input
          titulo="Título"
          onChange={(e) => setTitulo(e.target.value)}
          value={titulo}
        />

        <Tiptap descricao={descricao} onChange={setDescricao} />
        <Botao
          texto={id && !copiar ? 'Modificar anotação' : 'Criar anotação'}
          cor="primario"
          estaCarregando={estaCarregando}
          style={{ marginTop: '1rem' }}
        />
      </Form>
    </Container>
  );
}
