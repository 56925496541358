import { createGlobalStyle } from 'styled-components';

export const DarkTheme = {
  fundo: '#171717',
  cinza: '#303134',
  vermelho: '#dc2626',
  verde: '#16a34a',
  laranja: '#ea580c',
  azul: '#2563eb',
  rosa: '#db2777',
  roxo: '#9333ea',
  amarelo: '#eab308',
  preto: '#0a0a0a',
  branco: '#f5f5f5',
  primario: '#45F882'
};

export const LightTheme = {
  fundo: '#ffffff',
  cinza: '#303134',
  vermelho: '#dc2626',
  verde: '#16a34a',
  laranja: '#ea580c',
  azul: '#2563eb',
  rosa: '#db2777',
  roxo: '#9333ea',
  amarelo: '#eab308',
  preto: '#0a0a0a',
  branco: '#171717',
  primario: '#31d669'
};

export const GLobalStyles = createGlobalStyle`
:root {
    --fundo: ${(props) => props.theme.fundo};
    --cinza: ${(props) => props.theme.cinza};
    --vermelho: ${(props) => props.theme.vermelho};
    --verde: ${(props) => props.theme.verde};
    --laranja: ${(props) => props.theme.laranja};
    --azul: ${(props) => props.theme.azul};
    --rosa: ${(props) => props.theme.rosa};
    --roxo: ${(props) => props.theme.roxo};
    --amarelo: ${(props) => props.theme.amarelo};
    --preto: ${(props) => props.theme.preto};
    --branco: ${(props) => props.theme.branco};
    --primario: ${(props) => props.theme.primario};
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 728px) {
      font-size: 87.5%;
    }
  }

  body {
    background: var(--fundo);
    color: var(--branco);
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
  }

  button {
    color: var(--branco);
    cursor: pointer;
    transition: all .2s ease-in;

    &:hover {
      filter: brightness(0.8);
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  body::-webkit-scrollbar {
    width: 0.6rem; 
  }

  body::-webkit-scrollbar-track {
    background: var(--fundo);
  }

  body::-webkit-scrollbar-thumb {
    background-color: var(--primario);
    border-radius: 1rem;
    border: 1px solid var(--fundo);


    :hover {
      border: 1px solid var(--primario);
    }
  }
`;
