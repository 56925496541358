import React, { useEffect, useState } from 'react';
import { BsArrowDownCircle, BsArrowLeftSquareFill, BsArrowRightSquareFill, BsArrowUpCircle } from 'react-icons/bs';
import { TailSpin } from 'react-loader-spinner';

import { Titulo } from '../../Components/Titulo';
import { db } from '../../config/firebase';
import titulos from '../../utils/titulos.json';
import {
  Container,
  Meses,
  BotaoPassarMes,
  MesAtual,
  Lista,
  Transacao,
  Status,
  Valor,
  Descricao,
  PAtual,
  PTotal,
  Data,
  Banco,
  Carregando,
  ValorFaturaContainer,
  ValorFaturaConteudo,
  ValorFaturaTitulo,
  Linha,
  ValorFaturaPessoa,
  Grafico
} from './styles';

import { collection, getDocs } from 'firebase/firestore';
import { currency } from 'remask';
import { FaRegMoneyBillAlt } from 'react-icons/fa';

interface BancoProps {
  banco: string;
  id: string;
  cor: string;
}

interface PessoaProps {
  id?: string;
  pessoa: string;
  cor: string;
  ativo: number;
}

interface TransacoesProps {
  id: string;
  valor: number;
  pessoa: string;
  parcela_total: number;
  mensal: boolean;
  comentario: string;
  data: string;
  status: string;
  parcela_atual: number;
  descricao: string;
  banco: string;
  banco_cor: string;
}

interface FaturaProps {
  id: string;
  ano: string;
  banco: string;
  mes: string;
  transacoes: TransacoesProps[];
}

interface PeriodoProps {
  [chave: string]: number;
}

export function PaginaPrincipal() {
  const meses = [
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'julho',
    'agosto',
    'setembro',
    'outubro',
    'novembro',
    'dezembro'
  ];

  const dataAtual = new Date();
  const [mesAtual, setMesAtual] = useState(dataAtual.getMonth());
  const [nomeMesAtual, setNomeMesAtual] = useState(meses[mesAtual]);
  const [vezesParaFrente, setVezesParaFrente] = useState(0);
  const [ano, setAno] = useState(dataAtual.getFullYear());
  const [transacoes, setTransacoes] = useState<TransacoesProps[]>([]);
  const [transacoesFiltradas, setTransacoesFiltradas] = useState<
    TransacoesProps[]
  >([]);
  const [estaCarregandoTransacoes, setEstaCarregandoTransacoes] =
    useState(true);
  const [estaCarregandoPessoas, setEstaCarregandoPessoas] = useState(true);
  const [pessoaSelecionada, setPessoaSelecionada] = useState('gustavo');
  const [pessoas, setPessoas] = useState<PessoaProps[]>([]);
  const [valores, setValores] = useState<number[]>([]);
  const [faturas, setFaturas] = useState<FaturaProps[]>([]);
  const [categoriasOrdenadas, setCategoriasOrdenadas] = useState<string[]>([]);

  async function buscarBancos() {
    const bancosCollection = collection(db, 'bancos');
    const bancosQuerySnapshot = await getDocs(bancosCollection);
    const transacoesArray: any[] = [];

    await Promise.all(
      bancosQuerySnapshot.docs.map(async (doc) => {
        const bancoData = doc.data() as BancoProps;
        const bancoName = bancoData.banco;

        const faturaCollectionName = `fatura_atual_${bancoName}`;
        const faturaCollection = collection(db, faturaCollectionName);
        const faturaQuerySnapshot = await getDocs(faturaCollection);

        const transacoes = faturaQuerySnapshot.docs.map((faturaDoc) => ({
          id: faturaDoc.id,
          ...faturaDoc.data(),
          banco: bancoName,
          banco_cor: bancoData.cor
        }));

        transacoesArray.push(...transacoes);
      })
    );

    transacoesArray.sort((dia: TransacoesProps, dia2: TransacoesProps) => dia2.data.localeCompare(dia.data));

    buscarPessoas();
    setTransacoes(transacoesArray);
    setEstaCarregandoTransacoes(false);
  }

  async function buscarPessoas() {
    const pessoasCollection = collection(db, 'pessoas');
    const pessoasQuerySnapshot = await getDocs(pessoasCollection);

    const pessoasArray: any = [];

    pessoasQuerySnapshot.forEach((doc) => {
      const pessoaData = doc.data();
      pessoasArray.push(pessoaData);
    });
    setPessoas(pessoasArray);
    setEstaCarregandoPessoas(false);
  }

  function mudarData(frente: boolean) {
    if (frente) {
      if (mesAtual == 11) {
        setNomeMesAtual(meses[0]);
        setMesAtual(0);
        setVezesParaFrente(vezesParaFrente + 1);
        setAno(ano + 1);
      } else {
        const mesTemporario = mesAtual;
        setNomeMesAtual(meses[mesTemporario + 1]);
        setMesAtual(mesTemporario + 1);
        setVezesParaFrente(vezesParaFrente + 1);
      }
    } else if (vezesParaFrente != 0) {
      if (mesAtual == 0) {
        setNomeMesAtual(meses[11]);
        setMesAtual(11);
        setVezesParaFrente(vezesParaFrente - 1);
        setAno(ano - 1);
      } else {
        const mesTemporario = mesAtual;
        setNomeMesAtual(meses[mesTemporario - 1]);
        setMesAtual(mesTemporario - 1);
        setVezesParaFrente(vezesParaFrente - 1);
      }
    }
  }

  function formatarData(data: string) {
    const partes = data.split('-');
    const dataFormatada = `${partes[2]}-${partes[1]}-${partes[0]}`;
    return dataFormatada;
  }

  function calcularSomaPorPessoa(pessoa: string): number {
    return transacoesFiltradas
      .filter((transacao) => transacao.pessoa === pessoa)
      .reduce((soma, transacao) => soma + transacao.valor, 0);
  }

  function calcularSomaPorPessoaComStatusPago(pessoa: string): number {
    return transacoesFiltradas
      .filter((transacao) => transacao.pessoa === pessoa && transacao.status === 'pago')
      .reduce((soma, transacao) => soma + transacao.valor, 0);
  }

  function calcularRestantePorPessoa(pessoa: string): number {
    const total = calcularSomaPorPessoa(pessoa);
    const pago = calcularSomaPorPessoaComStatusPago(pessoa);
    return total - pago;
  }

  const options = {
    chart: {
      fontFamily: 'Comfortaa',
      toolbar: {
        show: false,
      },
      foreColor: 'var(--primario)',
    },
    colors: ['var(--primario)'],
    dataLabels: {
      style: {
        fontSize: '16px',
        fontFamily: 'Comfortaa',
        fontWeight: 'bold',
        colors: ['var(--primario)'],
      },
      background: {
        foreColor: 'var(--fundo)'
      }
    },
    grid: {
      show: false,
    },
    xaxis: {
      axisBorder: {
        color: 'var(--primario)',
      },
      axisTicks: {
        color: 'var(--primario)',
      },
      categories: categoriasOrdenadas.slice(-12)
    },
    yaxis: {
      labels: {
        show: false,
      }
    },
    tooltip: {
      enabled: false,
    },
    fill: {
      opacity: 0.3,
      type: "gradient",
      gradient: {
        shade: "dark",
        opacityFrom: 0.7,
        opacityTo: 0.3,
      },
    },
  };

  useEffect(() => {
    document.title = titulos.texto.principal;

    buscarBancos();
  }, []);

  useEffect(() => {
    const transacoesFiltradasTemp = transacoes.filter(
      (transacao) =>
        transacao.pessoa === pessoaSelecionada &&
        (vezesParaFrente + transacao.parcela_atual <= transacao.parcela_total ||
          (vezesParaFrente + transacao.parcela_atual >=
            transacao.parcela_total &&
            transacao.mensal === true))
    );
    setTransacoesFiltradas(transacoesFiltradasTemp);
  }, [transacoes, pessoaSelecionada, vezesParaFrente]);

  useEffect(() => {
    async function fetchData() {
      const faturasCollection = collection(db, 'faturas_fechadas');
      const faturasQuerySnapshot = await getDocs(faturasCollection);
      const faturasArray: FaturaProps[] = [];

      faturasQuerySnapshot.forEach((doc) => {
        const faturaData = doc.data() as FaturaProps;
        faturasArray.push(faturaData);
      });
      setFaturas(faturasArray);
    }

    fetchData();
  }, []);

  useEffect(() => {
    const periodoTemporario: PeriodoProps = {};

    faturas.forEach((fatura) => {
      const periodo: string = `${fatura.mes}/${fatura.ano}`;

      if (!periodoTemporario[periodo]) {
        periodoTemporario[periodo] = 0;
      }

      fatura.transacoes.forEach((transacao) => {
        if (pessoaSelecionada === transacao.pessoa) {
          periodoTemporario[periodo] += Number(transacao.valor.toFixed(2));
        }
      });
    });

    const mesParaNumero = (mes: string): number => {
      const meses: { [key: string]: number } = {
        janeiro: 1,
        fevereiro: 2,
        março: 3,
        abril: 4,
        maio: 5,
        junho: 6,
        julho: 7,
        agosto: 8,
        setembro: 9,
        outubro: 10,
        novembro: 11,
        dezembro: 12,
      };
      return meses[mes.toLowerCase()];
    };

    const periodoArray = Object.entries(periodoTemporario).map(([periodo, valor]) => {
      const [mes, ano] = periodo.split('/');
      return { periodo, mes, ano: parseInt(ano), valor };
    });

    periodoArray.sort((a, b) => {
      if (a.ano !== b.ano) {
        return a.ano - b.ano;
      }
      return mesParaNumero(a.mes) - mesParaNumero(b.mes);
    });

    const periodoOrdenado: PeriodoProps = {};
    periodoArray.forEach(p => {
      periodoOrdenado[p.periodo] = parseFloat(p.valor.toFixed(2));
    });

    const categorias = Object.keys(periodoOrdenado);
    const valores = Object.values(periodoOrdenado);

    setCategoriasOrdenadas(categorias);
    setValores(valores);

  }, [faturas, pessoaSelecionada]);

  return (
    <Container>
      <Titulo>{titulos.texto.principal}</Titulo>

      <Meses>
        <BotaoPassarMes disabilitar={vezesParaFrente}>
          <BsArrowLeftSquareFill onClick={() => mudarData(false)} />
        </BotaoPassarMes>
        <MesAtual>
          <h1>
            {nomeMesAtual} {ano}
          </h1>
          {!estaCarregandoPessoas && !estaCarregandoTransacoes ? (
            <>
              <div className="select">
                <select
                  id="selecao"
                  value={pessoaSelecionada}
                  onChange={(e) => setPessoaSelecionada(e.target.value)}
                  key={pessoaSelecionada}
                >
                  {pessoas.filter((pessoa: PessoaProps) => pessoa.ativo === 1)
                    .map((pessoa: PessoaProps) => (
                      <option value={pessoa.pessoa} key={pessoa.id}>
                        {pessoa.pessoa}
                      </option>
                    ))}
                </select>
              </div>
              <ValorFaturaContainer>
                <ValorFaturaConteudo>
                  <ValorFaturaTitulo cor="azul">
                    <span>Fatura</span>
                    <FaRegMoneyBillAlt />
                  </ValorFaturaTitulo>
                  <Linha />
                  <ValorFaturaPessoa>
                    <span>
                      {currency.mask({
                        locale: 'pt-BR',
                        currency: 'BRL',
                        value: calcularSomaPorPessoa(pessoaSelecionada)
                      })}
                    </span>
                  </ValorFaturaPessoa>
                  <Linha />
                </ValorFaturaConteudo>

                <ValorFaturaConteudo>
                  <ValorFaturaTitulo cor="verde">
                    <span>Adiantamento</span>
                    <BsArrowUpCircle />
                  </ValorFaturaTitulo>
                  <Linha />
                  <ValorFaturaPessoa>
                    <span>
                      {currency.mask({
                        locale: 'pt-BR',
                        currency: 'BRL',
                        value: calcularSomaPorPessoaComStatusPago(pessoaSelecionada)
                      })}
                    </span>
                  </ValorFaturaPessoa>
                  <Linha />
                </ValorFaturaConteudo>

                <ValorFaturaConteudo>
                  <ValorFaturaTitulo cor="vermelho">
                    <span>Restante</span>
                    <BsArrowDownCircle />
                  </ValorFaturaTitulo>
                  <Linha />
                  <ValorFaturaPessoa>
                    <span>
                      {currency.mask({
                        locale: 'pt-BR',
                        currency: 'BRL',
                        value: calcularRestantePorPessoa(pessoaSelecionada)
                      })}
                    </span>
                  </ValorFaturaPessoa>
                  <Linha />
                </ValorFaturaConteudo>
              </ValorFaturaContainer>

              <Grafico options={options} series={[{ name: 'Transações', data: valores.slice(-12) }]} type="area" height={160} />
              <Lista>
                <Transacao>
                  <Status>Status</Status>
                  <Valor>Valor</Valor>
                  <Descricao>Descrição</Descricao>
                  <PAtual>P.A.</PAtual>
                  <span>x</span>
                  <PTotal>P.T.</PTotal>
                  <Data>Data</Data>
                  <Banco>Banco</Banco>
                </Transacao>
                {transacoesFiltradas.map((transacao: TransacoesProps) => (
                  <Transacao key={transacao.id}>
                    <Status status={transacao.status} />
                    <Valor>R$ {transacao.valor.toFixed(2)}</Valor>
                    <Descricao>{transacao.descricao}</Descricao>
                    <PAtual>
                      {transacao.mensal
                        ? transacao.parcela_atual
                        : transacao.parcela_atual + vezesParaFrente}
                    </PAtual>
                    <span>x</span>
                    <PTotal>{transacao.parcela_total}</PTotal>
                    <Data>{formatarData(transacao.data)}</Data>
                    <Banco cor={transacao.banco_cor}>{transacao.banco}</Banco>
                  </Transacao>
                ))}
              </Lista>
            </>
          ) : (
            <Carregando>
              <TailSpin height="50" width="50" color="#ffffff" />
            </Carregando>
          )}
        </MesAtual>
        <BotaoPassarMes>
          <BsArrowRightSquareFill onClick={() => mudarData(true)} />
        </BotaoPassarMes>
      </Meses>
    </Container>
  );
}
