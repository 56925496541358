import React, { useEffect, useState } from 'react';
import { BsArrowUpCircle, BsArrowDownCircle } from 'react-icons/bs';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { FiUsers, FiUser } from 'react-icons/fi';
import { TailSpin } from 'react-loader-spinner';
import { useLocation, useNavigate } from 'react-router-dom';

import { Titulo } from '../../Components/Titulo';
import { db } from '../../config/firebase';
import titulos from '../../utils/titulos.json';
import {
  Container,
  Pessoas,
  Pessoa,
  ValorFaturaContainer,
  ValorFaturaConteudo,
  ValorFaturaTitulo,
  Linha,
  ValorFaturaPessoa,
  Tabela,
  Status,
  TbPessoa,
  Carregando
} from './styles';

import {
  collection,
  getDocs,
  addDoc,
  doc,
  query,
  where,
  deleteDoc,
  updateDoc
} from 'firebase/firestore';
import { currency } from 'remask';

interface PessoaProps {
  id?: string;
  pessoa: string;
  cor: string;
}

export interface BancoProps {
  id: string;
  descricao: string;
  data: string;
  mensal: boolean;
  parcela_atual: number;
  parcela_total: number;
  pessoa: string;
  status: string;
  valor: number;
}

export function PaginaFaturaFechada() {
  const location = useLocation();
  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [estaCarregando, setEstaCarregando] = useState(true);
  const [pessoas, setPessoas] = useState<PessoaProps[]>([]);
  const [pessoasCompletas, setPessoasCompletas] = useState<PessoaProps[]>([]);
  const banco: BancoProps[] = location.state.transacoes;
  const nomeBanco = location.state.nomeBanco;
  const mes = location.state.mes;
  const ano = location.state.ano;
  const transacoes = location.state.transacoes;
  const [filtro, setFiltro] = useState<string[]>([]);
  const [naoEstaEscondido, setNaoEstaEscondido] = useState(
    window.innerWidth >= 728
  );

  function arrumarFiltro(pessoa: string) {
    setFiltro((prevFiltro) => {
      const pessoaJaExiste = prevFiltro.includes(pessoa);

      if (pessoaJaExiste) {
        return prevFiltro.filter((item) => item !== pessoa);
      } else {
        return [...prevFiltro, pessoa];
      }
    });
  }

  async function buscarDados() {
    const pessoasCollection = collection(db, 'pessoas');
    const pessoasQuerySnapshot = await getDocs(pessoasCollection);
    const pessoasArray: any = [];
    pessoasQuerySnapshot.forEach((doc) => {
      const pessoaData = doc.data();
      const pessoa: any = { id: doc.id, ...pessoaData };

      const pessoaExiste = banco.find(
        (item: BancoProps) => item.pessoa === pessoa.pessoa
      );

      if (pessoaExiste) {
        const pessoaExistente = pessoasArray.find(
          (pessoa: any) => pessoa.pessoa === pessoaExiste.pessoa
        );

        if (!pessoaExistente) {
          pessoasArray.push(pessoaExiste);
        }
      }
    });
    const pessoasCompletasArray: any = [];
    pessoasQuerySnapshot.forEach((doc) => {
      pessoasCompletasArray.push({ id: doc.id, ...doc.data() });
    });

    setPessoasCompletas(pessoasCompletasArray);
    setPessoas(pessoasArray);
    setEstaCarregando(false);
  }

  function calcularSomaPorPessoa(pessoa: string) {
    return banco
      .filter((item: BancoProps) => filtro.includes(item.pessoa))
      .reduce((total, item: BancoProps) => {
        if (item.pessoa === pessoa) {
          return total + item.valor;
        }
        return total;
      }, 0);
  }

  function calcularSomaPorPessoaComStatusPago(pessoa: string) {
    return banco
      .filter(
        (item: BancoProps) =>
          filtro.includes(item.pessoa) && item.status === 'pago'
      )
      .reduce((total, item: BancoProps) => {
        if (item.pessoa === pessoa) {
          return total + item.valor;
        }
        return total;
      }, 0);
  }

  function calcularValorTotal() {
    return banco
      .filter((item: BancoProps) => filtro.includes(item.pessoa))
      .reduce((total, item: BancoProps) => total + item.valor, 0);
  }

  function calcularValorTotalComStatusPago() {
    return banco
      .filter(
        (item: BancoProps) =>
          filtro.includes(item.pessoa) && item.status === 'pago'
      )
      .reduce((total, item: BancoProps) => total + item.valor, 0);
  }

  function calcularRestantePorPessoa(pessoa: string) {
    const fatura = calcularSomaPorPessoa(pessoa);
    const adiantamento = calcularSomaPorPessoaComStatusPago(pessoa);
    return fatura - adiantamento;
  }

  function calcularValorTotalRestante() {
    const faturaTotal = calcularValorTotal();
    const adiantamentoTotal = calcularValorTotalComStatusPago();
    return faturaTotal - adiantamentoTotal;
  }

  function formatarData(data: string) {
    const partes = data.split('-');
    const dataFormatada = `${partes[2]}-${partes[1]}-${partes[0]}`;
    return dataFormatada;
  }

  useEffect(() => {
    buscarDados();
    document.title = titulos.texto.faturas_fechadas;
  }, []);

  useEffect(() => {
    if (filtro.length == 0) {
      pessoas.forEach((pessoa: PessoaProps) =>
        setFiltro((prevFiltro) => {
          return [...prevFiltro, pessoa.pessoa];
        })
      );
    }
  }, [filtro, pessoas]);

  return (
    <Container>
      <Titulo voltar>
        {nomeBanco.charAt(0).toUpperCase() + nomeBanco.slice(1)}{' '}
        {mes.charAt(0).toUpperCase() + mes.slice(1)}-{ano}
      </Titulo>

      {estaCarregando ? (
        <Carregando>
          <TailSpin height="50" width="50" color="#ffffff" />
        </Carregando>
      ) : (
        <>
          <Pessoas>
            {pessoas.map((pessoa: PessoaProps) => (
              <Pessoa
                ativo={
                  filtro.includes(pessoa.pessoa)
                    ? 'ativo'
                    : filtro.length == 0
                    ? 'ativo'
                    : ''
                }
                key={pessoa.id}
                onClick={() => arrumarFiltro(pessoa.pessoa)}
              >
                <FiUser />
                <span>{pessoa.pessoa}</span>
              </Pessoa>
            ))}

            <Pessoa
              ativo={
                filtro.length == pessoas.length
                  ? 'ativo'
                  : filtro.length != 0
                  ? ''
                  : 'ativo'
              }
              onClick={() => setFiltro([])}
            >
              <FiUsers />
              <span>Todos</span>
            </Pessoa>
          </Pessoas>
          <ValorFaturaContainer>
            <ValorFaturaConteudo>
              <ValorFaturaTitulo cor="azul">
                <span>Fatura</span>
                <FaRegMoneyBillAlt />
              </ValorFaturaTitulo>
              <Linha />
              {filtro.map((pessoa) => (
                <ValorFaturaPessoa key={pessoa}>
                  <h3>{pessoa}:</h3>
                  <span>
                    {currency.mask({
                      locale: 'pt-BR',
                      currency: 'BRL',
                      value: calcularSomaPorPessoa(pessoa)
                    })}
                  </span>
                </ValorFaturaPessoa>
              ))}
              <Linha />
              <ValorFaturaPessoa>
                <h3>Total: </h3>
                <span>
                  {currency.mask({
                    locale: 'pt-BR',
                    currency: 'BRL',
                    value: calcularValorTotal()
                  })}
                </span>
              </ValorFaturaPessoa>
            </ValorFaturaConteudo>

            <ValorFaturaConteudo>
              <ValorFaturaTitulo cor="verde">
                <span>Adiantamento</span>
                <BsArrowUpCircle />
              </ValorFaturaTitulo>
              <Linha />
              {filtro.map((pessoa) => (
                <ValorFaturaPessoa key={pessoa}>
                  <h3>{pessoa}:</h3>
                  <span>
                    {currency.mask({
                      locale: 'pt-BR',
                      currency: 'BRL',
                      value: calcularSomaPorPessoaComStatusPago(pessoa)
                    })}
                  </span>
                </ValorFaturaPessoa>
              ))}
              <Linha />
              <ValorFaturaPessoa>
                <h3>Total: </h3>
                <span>
                  {currency.mask({
                    locale: 'pt-BR',
                    currency: 'BRL',
                    value: calcularValorTotalComStatusPago()
                  })}
                </span>
              </ValorFaturaPessoa>
            </ValorFaturaConteudo>

            <ValorFaturaConteudo>
              <ValorFaturaTitulo cor="vermelho">
                <span>Restante</span>
                <BsArrowDownCircle />
              </ValorFaturaTitulo>
              <Linha />
              {filtro.map((pessoa) => (
                <ValorFaturaPessoa key={pessoa}>
                  <h3>{pessoa}:</h3>
                  <span>
                    {currency.mask({
                      locale: 'pt-BR',
                      currency: 'BRL',
                      value: calcularRestantePorPessoa(pessoa)
                    })}
                  </span>
                </ValorFaturaPessoa>
              ))}
              <Linha />
              <ValorFaturaPessoa>
                <h3>Total: </h3>
                <span>
                  {currency.mask({
                    locale: 'pt-BR',
                    currency: 'BRL',
                    value: calcularValorTotalRestante()
                  })}
                </span>
              </ValorFaturaPessoa>
            </ValorFaturaConteudo>
          </ValorFaturaContainer>

          <Tabela>
            <thead>
              <tr>
                <th>{naoEstaEscondido ? 'Status' : ''}</th>
                <th>Valor</th>
                <th>Descrição</th>
                <th>{naoEstaEscondido ? 'P.Atual' : 'P.A.'}</th>
                <th></th>
                <th>{naoEstaEscondido ? 'P.Total' : 'P.T.'}l</th>
                {naoEstaEscondido && <th>Data</th>}
                <th>Pessoa</th>
                {naoEstaEscondido && <th></th>}
              </tr>
            </thead>
            <tbody>
              {banco
                .filter((banco: BancoProps) => filtro.includes(banco.pessoa))
                .map((banco: BancoProps) => (
                  <tr
                    className={checkedItems[banco.id] ? 'checked' : ''}
                    key={banco.id}
                  >
                    <td>
                      <Status status={banco.status} />
                    </td>
                    <td>
                      {currency.mask({
                        locale: 'pt-BR',
                        currency: 'BRL',
                        value: banco.valor
                      })}
                    </td>
                    <td>{banco.descricao}</td>
                    <td>{banco.parcela_atual}</td>
                    <td>x</td>
                    <td>{banco.parcela_total}</td>
                    {naoEstaEscondido && <td>{formatarData(banco.data)}</td>}
                    <td>
                      <TbPessoa
                        cor={
                          pessoasCompletas.find(
                            (p: any) => p.pessoa === banco.pessoa
                          )?.cor ?? 'laranja'
                        }
                      >
                        {banco.pessoa}
                      </TbPessoa>
                    </td>
                    {naoEstaEscondido && (
                      <td>
                        <input
                          type="checkbox"
                          checked={checkedItems[banco.id] || false}
                          onChange={(event) =>
                            setCheckedItems({
                              ...checkedItems,
                              [banco.id]: event.target.checked
                            })
                          }
                        />
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </Tabela>
        </>
      )}
    </Container>
  );
}
