import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  min-height: 100vh;
  padding: 1rem;
  align-items: center;
  flex-direction: column;

  button {
    margin: 2rem 0;
  }
`;

export const AnotacoesContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  width: 100%;
  margin: 2rem 0;
  width: 90%;

  justify-content: center;
`;

export const Anotacao = styled.div`
  min-width: 20rem;
  width: 48.8%;
  padding: 1rem;
  border-radius: 0.2rem;
  border: 1px solid var(--cinza);
`;

export const AnotacaoTitulo = styled.h3`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--cinza);
  position: relative;

  svg {
    position: absolute;
    right: 0;
    cursor: pointer;

    transition: all 0.2s ease;

    &:hover {
      color: var(--primario);
    }
  }
`;

export const AnotacaoDescricao = styled.div`
  margin-top: 1rem;
  line-height: 2rem;
  word-wrap: break-word;

  strong {
      font-weight: 700;
    }
    s {
      opacity: 0.5;
    }
    a {
      text-decoration: none;
      color: var(--primario);
    }
    blockquote {
      border-left: 4px solid var(--branco);
      padding: 0.5rem;
      background: var(--cinza);
      line-height: 1rem;
    }
    ol {
      margin-left: 2rem;

      ::marker {
        color: var(--primario);
        font-weight: 700;
      }
    }
    mark {
      padding: 0 0.5rem;
      border-radius: 0.25rem;
    }
`;

export const Carregando = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
