import styled from 'styled-components';

export const ConteudoContainer = styled.section`
  width: 100%;
  transition: all 0.2s ease;
  background-color: var(--fundo);
  display: flex;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;

  .titulo {
    max-width: 1350px;
    margin: 0 auto;
    font-weight: 600;
    font-size: 1.5rem;
    width: fit-content;
    padding: 0 5rem;
    margin-top: 6rem;
  }
`;

export const Conteudo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Experiencia = styled.div`
  display: flex;
  max-width: 1350px;
  margin: 0 auto;

  @media (max-width: 940px) {
    flex-direction: column;
  }
`;

export const Esquerda = styled.div`
  width: 20rem;
  display: flex;
  position: relative;
  padding: 2rem 2rem 0 2rem;

  .bola {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 10rem;
    background: var(--branco);
    position: absolute;
    right: -0.8rem;
    top: 2.5rem;

    @media (max-width: 940px) {
      display: none;
    }
  }

  h1 {
    font-size: 1.1rem;
    padding-top: 0.4rem;
    text-align: right;
    width: 100%;
  }

  @media (max-width: 940px) {
    .bola {
      display: none;
    }
    h1 {
      text-align: left;
    }
  }
`;

export const Meio = styled.div`
  display: flex;
  background: var(--branco);
  width: 0.4rem;

  @media (max-width: 940px) {
    display: none;
  }
`;

export const Direita = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 40rem;
  gap: 0.5rem;

  h1 {
    font-size: 1.6rem;
  }

  h3 {
    color: var(--primario);
  }

  @media (max-width: 940px) {
    width: 25rem;
  }
`;
