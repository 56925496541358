import styled from 'styled-components';

interface PessoaProps {
  ativo: string;
}

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  min-height: 100vh;
  padding: 1rem;
  align-items: center;
  flex-direction: column;

  @media (max-width: 728px) {
    padding: 1rem 0;
  }
`;

export const Pessoas = styled.div`
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
`;

export const Pessoa = styled.div<PessoaProps>`
  display: flex;
  align-items: center;
  padding: 0.2rem 1rem;
  border: ${({ ativo }) =>
    ativo ? '1px solid var(--primario)' : '1px solid var(--cinza)'};
  border-radius: 1rem;
  background: var(--fundo);
  transition: all 0.2s ease;
  opacity: ${({ ativo }) => (ativo ? '1' : '0.5')};

  svg {
    font-size: 1.2rem;
    margin-right: 0.5rem;
  }

  span {
    text-transform: capitalize;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
    filter: brightness(1.2);
  }
`;

export const PasswordContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const PasswordContent = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border: 1px solid var(--cinza);
  border-radius: 1rem;
  background: var(--fundo);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
    
  button {
      margin-top: 1rem;
  }
`;