import styled from 'styled-components';

export const ContatoContainer = styled.div`
  background: var(--branco);
  color: var(--fundo);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 5rem;
  transition: all 0.2s ease;
`;

export const Conteudo = styled.a`
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  color: var(--fundo);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    color: var(--primario);
  }
`;
