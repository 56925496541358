import React from 'react';
import { BsMouse } from 'react-icons/bs';
import { FiLinkedin, FiGithub, FiArrowDown } from 'react-icons/fi';
import { useTypewriter, Cursor } from 'react-simple-typewriter';

import { Botao } from '../../../Components/Botao';
import {
  ConteudoContainer,
  HomeContainer,
  HomeEsquerdo,
  HomeDireito,
  Socials,
  ScrollBaixo
} from './styles';

export function HomeSection() {
  const [text] = useTypewriter({
    words: [
      'Frontend Developer',
      'Backend Developer',
      'Fullstack Developer',
      'Mobile Developer'
    ],
    loop: true,
    deleteSpeed: 50,
    typeSpeed: 50
  });

  return (
    <ConteudoContainer id="home">
      <HomeContainer>
        <Socials>
          <a
            href="https://www.linkedin.com/in/gustacastro/"
            target="_blank"
            rel="noreferrer"
          >
            <FiLinkedin />
          </a>
          <a
            href="https://github.com/gustacastro"
            target="_blank"
            rel="noreferrer"
          >
            <FiGithub />
          </a>
        </Socials>
        <HomeEsquerdo>
          <h1 className="crescer">Olá, sou Gustavo Castro</h1>
          <h2 className="crescer-pouco">{text} <Cursor /></h2>
          <span className="crescer-pouco">
            Non quis sunt fugiat incididunt aliqua. Cupidatat qui ullamco sit
            dolore ipsum officia voluptate occaecat et. Quis est nostrud dolor
            aute fugiat nulla quis tempor labore do dolor eu elit.
          </span>
          <Botao
            cor="primario"
            texto="Contato"
            contato
            onClick={() =>
              window.open('mailto:gusncastro@gmail.com?subject=&body=')
            }
          />
        </HomeEsquerdo>
        <HomeDireito>
          <svg viewBox="0 0 200 187" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0" style={{ maskType: 'alpha' }}>
              <path
                fill="var(--fundo)"
                d="M34,-35.3C41.1,-26.8,42,-13.4,42.3,0.3C42.7,14.1,42.4,28.1,35.3,40.4C28.1,52.7,14.1,63.3,-1.2,64.5C-16.4,65.7,-32.8,57.4,-48.9,45.1C-65,32.8,-80.8,16.4,-77.5,3.3C-74.2,-9.8,-51.8,-19.6,-35.7,-28.1C-19.6,-36.6,-9.8,-43.7,1.8,-45.5C13.4,-47.3,26.8,-43.8,34,-35.3Z"
                transform="translate(100 100)"
              />
            </mask>
            <g mask="url(#mask0)">
              <path
                fill="var(--fundo)"
                d="M34,-35.3C41.1,-26.8,42,-13.4,42.3,0.3C42.7,14.1,42.4,28.1,35.3,40.4C28.1,52.7,14.1,63.3,-1.2,64.5C-16.4,65.7,-32.8,57.4,-48.9,45.1C-65,32.8,-80.8,16.4,-77.5,3.3C-74.2,-9.8,-51.8,-19.6,-35.7,-28.1C-19.6,-36.6,-9.8,-43.7,1.8,-45.5C13.4,-47.3,26.8,-43.8,34,-35.3Z"
                transform="translate(100 100)"
              />
              <image
                x="20"
                y="52"
                xlinkHref="https://avatars.githubusercontent.com/u/82110597?v=4"
                className="img crescer"
              />
            </g>
          </svg>
        </HomeDireito>
        <ScrollBaixo smooth spy to="sobre">
          <BsMouse />
          <span>Scroll down</span>
          <FiArrowDown />
        </ScrollBaixo>
      </HomeContainer>
    </ConteudoContainer>
  );
}
