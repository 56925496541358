import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Container } from "./styles";
import { Toolbar } from "../Toolbar";
import Link from '@tiptap/extension-link'
import Underline from "@tiptap/extension-underline";
import Highlight from '@tiptap/extension-highlight';
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'

export default function Tiptap({
  descricao, onChange
}: {
  descricao: string, onChange: (richText: string) => void

}) {
  const editor = useEditor({
    extensions: [
      StarterKit.configure(),
      Link.configure({
        HTMLAttributes: {
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      }),
      Underline.configure(),
      Highlight.configure({ multicolor: true }),
      Color,
      TextStyle
    ],
    content: descricao,
    onUpdate({ editor }) {
      onChange(editor.getHTML());
    },
  });

  return (
    <Container>
      <Toolbar editor={editor} />
      <EditorContent editor={editor} />
    </Container>
  );
}