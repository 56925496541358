import React, { useEffect, useState } from 'react';
import { BiSolidEdit } from 'react-icons/bi';
import { TailSpin } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

import { Botao } from '../../Components/Botao';
import { Procurar } from '../../Components/Procurar';
import { Titulo } from '../../Components/Titulo';
import { db } from '../../config/firebase';
import titulos from '../../utils/titulos.json';
import {
  Container,
  AnotacoesContainer,
  Anotacao,
  AnotacaoTitulo,
  AnotacaoDescricao,
  Carregando
} from './styles';

import { collection, getDocs } from 'firebase/firestore';

interface DadosProps {
  id: string;
  titulo: string;
  descricao: string;
}

export function PaginaAnotacoes() {
  const navigate = useNavigate();
  const [dados, setDados] = useState<DadosProps[]>([]);
  const [dadosFiltrados, setDadosFiltrados] = useState<DadosProps[]>([]);
  const [estaCarregando, setEstaCarregando] = useState(false);
  const [procurar, setProcurar] = useState('');

  async function buscarBancos() {
    setEstaCarregando(true);
    const bancosCollection = collection(db, 'anotacoes');
    const bancosQuerySnapshot = await getDocs(bancosCollection);
    const bancosArray: any = [];

    bancosQuerySnapshot.forEach((doc) => {
      bancosArray.push({ id: doc.id, ...doc.data() });
    });

    setEstaCarregando(false);
    setDados(bancosArray);
  }

  useEffect(() => {
    buscarBancos();
    document.title = titulos.texto.anotacoes;
  }, []);

  useEffect(() => {
    if (procurar == '') {
      setDadosFiltrados(dados);
    } else {
      const dadosFiltrados = dados.filter((item) =>
        item.titulo.toLowerCase().includes(procurar.toLowerCase())
      );
      setDadosFiltrados(dadosFiltrados);
    }
  }, [procurar, dados]);

  return (
    <Container>
      <Titulo>{titulos.texto.anotacoes}</Titulo>

      {estaCarregando ? (
        <Carregando>
          <TailSpin height="50" width="50" color="#ffffff" />
        </Carregando>
      ) : (
        <>
          <Botao
            texto="Nova anotação"
            cor="primario"
            onClick={() =>
              navigate('/anotacao', {
                state: {
                  id: ''
                }
              })
            }
          />
          <Procurar
            style={{ width: '63.5%' }}
            onChange={(e) => setProcurar(e.target.value)}
            texto="Procurar"
            value={procurar}
          />

          <AnotacoesContainer>
            {dadosFiltrados.map((item) => (
              <Anotacao key={item.id}>
                <AnotacaoTitulo>
                  {item.titulo}{' '}
                  <BiSolidEdit
                    onClick={() =>
                      navigate('/anotacao', {
                        state: {
                          id: item.id,
                          titulo: item.titulo,
                          descricao: item.descricao
                        }
                      })
                    }
                  />
                </AnotacaoTitulo>
                <AnotacaoDescricao
                  dangerouslySetInnerHTML={{ __html: item.descricao }}
                />
              </Anotacao>
            ))}
          </AnotacoesContainer>
        </>
      )}
    </Container>
  );
}
