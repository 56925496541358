import React from 'react';

import { Botao } from '../../../Components/Botao';
import { ConteudoContainer, SobreContainer } from './styles';

export function SobreSection() {
  return (
    <ConteudoContainer id="sobre">
      <SobreContainer>
        <h1 className="crescer-pouco">S O B R E</h1>
        <span className="crescer">
          Nisi reprehenderit consectetur deserunt nulla aute fugiat id
          exercitation fugiat. Sunt in consequat velit ea aute aliquip Lorem
          nisi Lorem aute duis.
        </span>
        <Botao cor="primario" texto="Baixar CV" download />
      </SobreContainer>
    </ConteudoContainer>
  );
}
