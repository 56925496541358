import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

import { Estudo, EstudoCima, EstudoBaixo } from './styles';

interface CertificadoProps {
  id: string;
  imagens: { id: string; imagem: string }[];
  nome: string;
}

export function Certificado({ id, imagens, nome }: CertificadoProps) {
  const [aberto, setAberto] = useState(false);

  return (
    <Estudo key={id} aberto={aberto}>
      <div className="centralizar">
        <EstudoCima onClick={() => setAberto(!aberto)} aberto={aberto}>
          <h1>{nome}</h1>
          <IoIosArrowDown />
        </EstudoCima>

        <EstudoBaixo aberto={aberto}>
          {imagens.map((imagem) => (
            <img src={imagem.imagem} alt={imagem.id} key={imagem.id} />
          ))}
        </EstudoBaixo>
      </div>
    </Estudo>
  );
}
