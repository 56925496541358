import React from 'react';

import { projetos } from '../../../utils/projetos';
import { ConteudoContainer, ProjetoContainer, Projeto } from './styles';

export function ProjetosSection() {
  return (
    <ConteudoContainer id="projetos">
      <h1 className="titulo crescer-pouco">PROJETOS</h1>

      {projetos.map((projeto) => (
        <ProjetoContainer key={projeto.id}>
          <Projeto tag={projeto.tag}>
            <h1>{projeto.nome}</h1>
            <span>{projeto.tag}</span>
          </Projeto>
          <div className="projetoEscondido">
            <div>
              <h1>{projeto.nome}</h1>
              <span>{projeto.tecnologias}</span>
            </div>
          </div>
        </ProjetoContainer>
      ))}
    </ConteudoContainer>
  );
}
