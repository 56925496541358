import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Titulo } from '../../Components/Titulo';
import titulos from '../../utils/titulos.json';
import {
  Container,
  ValorFaturaContainer,
  ValorFaturaConteudo,
  ValorFaturaTitulo,
  Linha,
  ValorFaturaPessoa,
  Tabela,
  Status,
  TbPessoa
} from './styles';

import { currency } from 'remask';

interface PessoaProps {
  id?: string;
  pessoa: string;
  cor: string;
}

export interface BancoProps {
  id: string;
  descricao: string;
  data: string;
  mensal: boolean;
  parcela_atual: number;
  parcela_total: number;
  pessoa: string;
  status: string;
  valor: number;
  comentario: string;
}

export function PaginaFaturaAtualBancoSimplificado() {
  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>(
    {}
  );
  const location = useLocation();
  const nomeBanco = location.state.nomeBanco;
  const banco: BancoProps[] = location.state.banco;
  const pessoas: PessoaProps[] = location.state.pessoas;
  const pessoasCompletas: PessoaProps[] = location.state.pessoasCompletas;
  const naoEstaEscondido = window.innerWidth >= 728;

  const pessoasFiltradas = pessoas.filter((pessoa) => {
    const soma = calcularValorTotal(pessoa);
    return soma !== 0;
  });

  function calcularSomaPorPessoa(pessoa: PessoaProps) {
    return banco
      .filter((item: BancoProps) => item.pessoa === pessoa.pessoa)
      .reduce((total, item: BancoProps) => {
        if (item.pessoa === pessoa.pessoa) {
          return total + item.valor;
        }
        return total;
      }, 0);
  }

  function calcularSomaPorPessoaComStatusPago(pessoa: PessoaProps) {
    return banco
      .filter(
        (item: BancoProps) =>
          item.pessoa === pessoa.pessoa && item.status === 'pago'
      )
      .reduce((total, item: BancoProps) => {
        return total + item.valor;
      }, 0);
  }

  function calcularValorTotal(pessoa: PessoaProps) {
    return banco
      .filter((item: BancoProps) => item.pessoa === pessoa.pessoa)
      .reduce((total, item: BancoProps) => total + item.valor, 0);
  }

  function calcularValorTotalComStatusPago(pessoa: PessoaProps) {
    return banco
      .filter(
        (item: BancoProps) =>
          item.pessoa === pessoa.pessoa && item.status === 'pago'
      )
      .reduce((total, item: BancoProps) => total + item.valor, 0);
  }

  function calcularRestantePorPessoa(pessoa: PessoaProps) {
    const fatura = calcularSomaPorPessoa(pessoa);
    const adiantamento = calcularSomaPorPessoaComStatusPago(pessoa);
    return fatura - adiantamento;
  }

  function calcularValorTotalRestante() {
    const faturaTotal = pessoas.reduce(
      (total, pessoa) => total + calcularValorTotal(pessoa),
      0
    );
    const adiantamentoTotal = pessoas.reduce(
      (total, pessoa) => total + calcularValorTotalComStatusPago(pessoa),
      0
    );
    return faturaTotal - adiantamentoTotal;
  }

  function formatarData(data: string) {
    const partes = data.split('-');
    const dataFormatada = `${partes[2]}-${partes[1]}-${partes[0]}`;
    return dataFormatada;
  }

  useEffect(() => {
    document.title = titulos.texto.fatura_atual;
  }, []);

  return (
    <Container>
      <Titulo voltar>
        Resumo {nomeBanco.charAt(0).toUpperCase() + nomeBanco.slice(1)}
      </Titulo>

      <ValorFaturaContainer>
        <ValorFaturaConteudo>
          <ValorFaturaTitulo cor="azul">
            <span>Fatura</span>
          </ValorFaturaTitulo>
          <Linha />
          {pessoasFiltradas.map((pessoa) => (
            <ValorFaturaPessoa key={pessoa.id}>
              <h3>{pessoa.pessoa}:</h3>
              <span>
                {currency.mask({
                  locale: 'pt-BR',
                  currency: 'BRL',
                  value: calcularSomaPorPessoa(pessoa)
                })}
              </span>
            </ValorFaturaPessoa>
          ))}
          <Linha />
          <ValorFaturaPessoa>
            <h3>Total: </h3>
            <span>
              {currency.mask({
                locale: 'pt-BR',
                currency: 'BRL',
                value: pessoas.reduce(
                  (total, pessoa) => total + calcularValorTotal(pessoa),
                  0
                )
              })}
            </span>
          </ValorFaturaPessoa>
        </ValorFaturaConteudo>

        <ValorFaturaConteudo>
          <ValorFaturaTitulo cor="verde">
            <span>Adiantamento</span>
          </ValorFaturaTitulo>
          <Linha />
          {pessoasFiltradas.map((pessoa) => (
            <ValorFaturaPessoa key={pessoa.id}>
              <h3>{pessoa.pessoa}:</h3>
              <span>
                {currency.mask({
                  locale: 'pt-BR',
                  currency: 'BRL',
                  value: calcularSomaPorPessoaComStatusPago(pessoa)
                })}
              </span>
            </ValorFaturaPessoa>
          ))}
          <Linha />
          <ValorFaturaPessoa>
            <h3>Total: </h3>
            <span>
              {currency.mask({
                locale: 'pt-BR',
                currency: 'BRL',
                value: pessoas.reduce(
                  (total, pessoa) =>
                    total + calcularValorTotalComStatusPago(pessoa),
                  0
                )
              })}
            </span>
          </ValorFaturaPessoa>
        </ValorFaturaConteudo>

        <ValorFaturaConteudo>
          <ValorFaturaTitulo cor="vermelho">
            <span>Restante</span>
          </ValorFaturaTitulo>
          <Linha />
          {pessoasFiltradas.map((pessoa) => (
            <ValorFaturaPessoa key={pessoa.id}>
              <h3>{pessoa.pessoa}:</h3>
              <span>
                {currency.mask({
                  locale: 'pt-BR',
                  currency: 'BRL',
                  value: calcularRestantePorPessoa(pessoa)
                })}
              </span>
            </ValorFaturaPessoa>
          ))}
          <Linha />
          <ValorFaturaPessoa>
            <h3>Total: </h3>
            <span>
              {currency.mask({
                locale: 'pt-BR',
                currency: 'BRL',
                value: calcularValorTotalRestante()
              })}
            </span>
          </ValorFaturaPessoa>
        </ValorFaturaConteudo>
      </ValorFaturaContainer>
      <Tabela>
        <thead>
          <tr>
            <th>{naoEstaEscondido ? 'Status' : ''}</th>
            <th>Valor</th>
            <th>Descrição</th>
            <th>{naoEstaEscondido ? 'P.Atual' : 'P.A'}</th>
            <th></th>
            <th>{naoEstaEscondido ? 'P.Total' : 'P.T'}</th>
            {naoEstaEscondido && <th>Data</th>}
            <th>Pessoa</th>
            {naoEstaEscondido && <th></th>}
          </tr>
        </thead>
        <tbody>
          {banco
            .filter((banco: BancoProps) =>
              pessoas.map((pessoa) => pessoa.pessoa).includes(banco.pessoa)
            )
            .map((banco: BancoProps) => (
              <tr
                className={checkedItems[banco.id] ? 'checked' : ''}
                key={banco.id}
              >
                <td>
                  <Status status={banco.status} />
                </td>
                <td>
                  {currency.mask({
                    locale: 'pt-BR',
                    currency: 'BRL',
                    value: banco.valor
                  })}
                </td>
                <td>{banco.descricao}</td>
                <td>{banco.parcela_atual}</td>
                <td>x</td>
                <td>{banco.parcela_total}</td>
                {naoEstaEscondido && <td>{formatarData(banco.data)}</td>}
                <td>
                  <TbPessoa
                    cor={
                      pessoasCompletas.find(
                        (p: any) => p.pessoa === banco.pessoa
                      )?.cor ?? 'azul'
                    }
                  >
                    {banco.pessoa}
                  </TbPessoa>
                </td>
                {naoEstaEscondido && (
                  <td>
                    <input
                      type="checkbox"
                      checked={checkedItems[banco.id] || false}
                      onChange={(event) =>
                        setCheckedItems({
                          ...checkedItems,
                          [banco.id]: event.target.checked
                        })
                      }
                    />
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </Tabela>
    </Container>
  );
}
