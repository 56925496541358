import styled from 'styled-components';

interface CorProps {
  ativo: string;
  cor: string;
}

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  min-height: 100vh;
  padding: 1rem;
  align-items: center;
  flex-direction: column;
`;

export const Form = styled.form`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;

  .select {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--branco);

    select {
      margin-left: 1rem;
      color: var(--branco);
      background-color: var(--fundo);
      text-transform: capitalize;
      height: 3.3rem;
      width: 9.4rem;
      border-radius: 0.2rem;
      border: none;
      text-align: center;
      margin-left: 0rem;
      border: 1px solid var(--cinza);
    }
  }

  button {
    margin-top: 1rem;
    width: 15rem;
  }
`;

export const Pessoas = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
  align-items: center;

  h2 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid var(--cinza);
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    text-transform: capitalize;

    svg {
      cursor: pointer;
      &:hover {
        color: var(--vermelho);
      }
    }
  }
`;