import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 1rem;
`;

export const Conteudo = styled.div`
  background: var(--fundo);
  border-radius: 10px;
  padding: 2rem;
  min-width: 30rem;

  form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h1 {
    font-size: 1.5rem;
  }

  span {
    cursor: pointer;
    font-size: 1.5rem;
  }
`;


export const Checkbox = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;

    input[type="radio"] {
      margin-right: 10px;
      appearance: none;
      width: 20px;
      height: 20px;
      border: 2px solid #ccc;
      border-radius: 50%;
      position: relative;
      cursor: pointer;
    }

    input[type="radio"]:checked {
      border: 2px solid var(--primario);
    }

    input[type="radio"]:checked::before {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: var(--primario);
      position: absolute;
      top: 2px;
      left: 2px;
    }
  }
`;

export const Status = styled.div`
  display: flex;
  cursor: pointer;
  width: 100%;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  
  &.naoFinalizado {
    background: var(--vermelho);
  }

  &.finalizado {
    background: var(--primario);
    color: var(--fundo);
  }
`;

export const Botoes = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`;
