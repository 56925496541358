import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 1rem 0;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.3rem;
`;

export const Botao = styled.button`
  display: flex;
  width: 3rem;
  height: 3rem;;
  background: none;
  color: var(--branco);
  border-radius: 0.5rem;
  border: none;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;

  &.ativado {
    background: var(--primario);
    color: var(--preto);
  }

  div {
    width: 1rem;
    height: 1rem;
    border-radius: 2rem;
    border: 1px solid var(--fundo);
  }
`;

export const Cores = styled.div`
  display: flex;
  gap: 0.3rem;
  align-items: center;
  flex-direction: column;
`;