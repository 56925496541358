import React, { useEffect, useState } from 'react';
import titulos from '../../utils/titulos.json';
import { FaArrowTrendUp } from "react-icons/fa6";
import { CiSquarePlus } from "react-icons/ci";
import {
  Container,
  Conteudos,
  Conteudo,
  Menu,
  MenuItem,
  Itens,
  Item
} from './styles';
import { Titulo } from '../../Components/Titulo';
import { ModalConta } from '../../Components/ModalConta';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../config/firebase';

export interface DadosProps {
  id: string;
  nome: string;
  valor: number;
  data: string;
  tipo: 'aReceber' | 'aPagar';
  status: 'finalizado' | 'naoFinalizado';
  dataCriacao: string;
  dataAtualizacao: string;
}

export function PaginaContas() {
  const [aPagarAtivo, setAPagarAtivo] = useState<'emAberto' | 'fechados' | 'geral'>('emAberto');
  const [mostrarModalNovaConta, setMostrarModalNovaConta] = useState(false);
  const [carregando, setCarregando] = useState(true);
  const [dados, setDados] = useState<DadosProps[]>([]);
  const [dadosFiltrados, setDadosFiltrados] = useState<DadosProps[]>([]);
  const [itemParaEditar, setItemParaEditar] = useState<DadosProps | null>(null);

  function fecharModal() {
    setMostrarModalNovaConta(false);
    itemParaEditar && setItemParaEditar(null);
  }

  useEffect(() => {
    const bancosCollection = collection(db, 'contas');

    onSnapshot(bancosCollection, (snapshot) => {
      const dados: DadosProps[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data();

        dados.push({
          id: doc.id,
          ...data
        } as DadosProps);
      });

      dados.sort((a, b) => new Date(b.dataAtualizacao).getTime() - new Date(a.dataAtualizacao).getTime());

      setDados(dados);
      setCarregando(false);
    });
  }, []);

  useEffect(() => {
    document.title = titulos.texto.principal;
  }, []);

  useEffect(() => {
    if (aPagarAtivo === 'emAberto') {
      setDadosFiltrados(dados.filter(dado => dado.status === 'naoFinalizado'));
    } else if (aPagarAtivo === 'fechados') {
      setDadosFiltrados(dados.filter(dado => dado.status === 'finalizado'));
    } else {
      setDadosFiltrados(dados);
    }
  }, [aPagarAtivo, dados]);

  return (
    <>
      <Container>
        <Titulo>{titulos.texto.conta}</Titulo>

        <Conteudos>
          <Conteudo>
            <Menu>
              <MenuItem className={aPagarAtivo === 'emAberto' ? 'ativo' : ''} onClick={() => setAPagarAtivo('emAberto')}>
                Em aberto
              </MenuItem>
              <MenuItem className={aPagarAtivo === 'fechados' ? 'ativo' : ''} onClick={() => setAPagarAtivo('fechados')}>
                Fechados
              </MenuItem>
              <MenuItem className={aPagarAtivo === 'geral' ? 'ativo' : ''} onClick={() => setAPagarAtivo('geral')}>
                Geral
              </MenuItem>
            </Menu>

            <Itens>
              <Item className='novo' onClick={() => setMostrarModalNovaConta(true)}>
                <CiSquarePlus />
                <span>Nova conta</span>
              </Item>
              {carregando ? <Item>Carregando...</Item> : dadosFiltrados.map((dado: DadosProps) => (
                <Item key={dado.id} className={dado.status === 'finalizado' ? 'finalizado' : dado.tipo} onClick={() => {
                  setItemParaEditar(dado);
                  setMostrarModalNovaConta(true);
                }}>
                  <FaArrowTrendUp />
                  <span>{dado.data}</span>
                  <span>{dado.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                  <span>{dado.nome}</span>
                  <span className={'dataConcluida'}>{new Date(dado.dataAtualizacao).toLocaleString()}</span>
                </Item>
              ))}
            </Itens>
          </Conteudo>
        </Conteudos>
      </Container>

      {mostrarModalNovaConta && (
        <ModalConta onClose={fecharModal} itemParaEditar={itemParaEditar} />
      )}
    </>
  );
}
