import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { PaginaAdicionaBanco } from '../Pages/PaginaAdicionaBanco';
import { PaginaAdicionarPessoa } from '../Pages/PaginaAdicionarPessoa';
import { PaginaAnotacao } from '../Pages/PaginaAnotacao';
import { PaginaAnotacoes } from '../Pages/PaginaAnotacoes';
import { PaginaFaturaAtual } from '../Pages/PaginaFaturaAtual';
import { PaginaFaturaAtualBanco } from '../Pages/PaginaFaturaAtualBanco';
import { PaginaFaturaAtualBancoSimplificado } from '../Pages/PaginaFaturaAtualBancoSimplificado';
import { PaginaFaturaFechada } from '../Pages/PaginaFaturaFechada';
import { PaginaFaturasFechadas } from '../Pages/PaginaFaturasFechadas';
import { PaginaLandingPage } from '../Pages/PaginaLandingPage';
import { PaginaLogin } from '../Pages/PaginaLogin';
import { PaginaPrincipal } from '../Pages/PaginaPrincipal';
import { PaginaTransacao } from '../Pages/PaginaTransacao';
import { JaEstaAutenticado } from './JaEstaAutenticado';
import { NaoEstaAutenticado } from './NaoEstaAutenticado';
import { PaginaCal } from '../Pages/PaginaCal';
import { PaginaTransacaoCal } from '../Pages/PaginaTransacaoCal';
import { PaginaGerenciarCal } from '../Pages/PaginaGerenciarCal';
import { PaginaLog } from '../Pages/PaginaLog';
import { PaginaGerenciamento } from '../Pages/PaginaGerenciamento';
import { PaginaContas } from '../Pages/PaginaContas';

export function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PaginaLandingPage />} />
        <Route path="/cal" element={<PaginaCal />} />
        <Route path="/transacaocal" element={<PaginaTransacaoCal />} />
        <Route
          path="/principal"
          element={
            <NaoEstaAutenticado>
              <PaginaPrincipal />
            </NaoEstaAutenticado>
          }
        />
        <Route
          path="/login"
          element={
            <JaEstaAutenticado>
              <PaginaLogin />
            </JaEstaAutenticado>
          }
        />
        <Route
          path="/adicionarbanco"
          element={
            <NaoEstaAutenticado>
              <PaginaAdicionaBanco />
            </NaoEstaAutenticado>
          }
        />
        <Route
          path="/adicionarpessoa"
          element={
            <NaoEstaAutenticado>
              <PaginaAdicionarPessoa />
            </NaoEstaAutenticado>
          }
        />
        <Route
          path="/faturaatual"
          element={
            <NaoEstaAutenticado>
              <PaginaFaturaAtual />
            </NaoEstaAutenticado>
          }
        />
        <Route
          path="/faturasfechadas"
          element={
            <NaoEstaAutenticado>
              <PaginaFaturasFechadas />
            </NaoEstaAutenticado>
          }
        />
        <Route
          path="/faturaatual/:id"
          element={
            <NaoEstaAutenticado>
              <PaginaFaturaAtualBanco />
            </NaoEstaAutenticado>
          }
        />
        <Route
          path="/faturaatual/simplificado"
          element={
            <NaoEstaAutenticado>
              <PaginaFaturaAtualBancoSimplificado />
            </NaoEstaAutenticado>
          }
        />
        <Route
          path="/transacao"
          element={
            <NaoEstaAutenticado>
              <PaginaTransacao />
            </NaoEstaAutenticado>
          }
        />
        <Route
          path="/faturafechada"
          element={
            <NaoEstaAutenticado>
              <PaginaFaturaFechada />
            </NaoEstaAutenticado>
          }
        />
        <Route
          path="/anotacoes"
          element={
            <NaoEstaAutenticado>
              <PaginaAnotacoes />
            </NaoEstaAutenticado>
          }
        />
        <Route
          path="/anotacao"
          element={
            <NaoEstaAutenticado>
              <PaginaAnotacao />
            </NaoEstaAutenticado>
          }
        />
        <Route
          path="/gerenciamentocal"
          element={
            <NaoEstaAutenticado>
              <PaginaGerenciarCal />
            </NaoEstaAutenticado>
          }
        />
        <Route
          path="/log"
          element={
            <NaoEstaAutenticado>
              <PaginaLog />
            </NaoEstaAutenticado>
          }
        />
        <Route
          path="/gerenciamento"
          element={
            <NaoEstaAutenticado>
              <PaginaGerenciamento />
            </NaoEstaAutenticado>
          }
        />
        <Route
          path="/contas"
          element={
            <NaoEstaAutenticado>
              <PaginaContas />
            </NaoEstaAutenticado>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
