import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

import {
  Container,
  Linha,
  Pesquisa,
  Status,
  Tabela,
  TbPessoa,
  ValorFaturaContainer,
  ValorFaturaConteudo,
  ValorFaturaPessoa,
  ValorFaturaTitulo
} from "./styles";
import { Input } from "../Input";
import { currency } from "remask";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { BsArrowDownCircle, BsArrowUpCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { PaginaTransacaoCal } from "../../Pages/PaginaTransacaoCal";
import { Botao } from "../Botao";

interface BancoProps {
  banco: {
    id: string;
    banco: string;
    cor: string;
  };
}

export interface FaturaProps {
  tempFatura: {
    id: string;
    descricao: string;
    data: string;
    mensal: boolean;
    parcela_atual: number;
    parcela_total: number;
    pessoa: string;
    status: string;
    valor: number;
    comentario: string;
  }[];
}

interface PessoaVisivelProps {
  id: string;
  pessoa: string;
  cor: string;
}

interface PessoaProps {
  pessoasVisiveis: PessoaVisivelProps[];
}

export function BancoHeader({ banco, tempFatura, pessoasVisiveis }: BancoProps & FaturaProps & PessoaProps) {
  const [aberto, setAberto] = useState(true);
  const [dadosFiltrados, setDadosFiltrados] = useState(tempFatura);
  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({});
  const naoEstaEscondido = window.innerWidth >= 728;
  const [mostrarModal, setMostrarModal] = useState(false);
  const [fatura, setFatura] = useState<FaturaProps['tempFatura'][0]>();
  const [bancoParaCriar, setBancoParaCriar] = useState('');

  function checkFormatoData(input: string) {
    return /^\d{2}-\d{2}-\d{4}$/.test(input);
  }

  function converterFormatoData(data: string) {
    return data.split('-').reverse().join('-');
  }

  function checkSomenteNumericos(valor: string) {
    const numero = parseFloat(valor);
    return !isNaN(numero);
  }

  function moveParaPaginaEditarTransacao(fatura: FaturaProps['tempFatura'][0], banco: string) {
    setFatura(fatura);
    setMostrarModal(true);
    setBancoParaCriar(banco);
  }

  function formatarData(data: string) {
    const partes = data.split('-');
    const dataFormatada = `${partes[2]}-${partes[1]}-${partes[0]}`;
    return dataFormatada;
  }

  function calcularSomaPorPessoa(pessoa: string) {
    let soma = 0;

    const faturasDaPessoa = tempFatura.filter((fatura) => fatura.pessoa === pessoa);

    faturasDaPessoa.forEach((fatura) => {
      soma += fatura.valor;
    });

    return soma;
  }

  function calcularValorTotal() {
    let total = 0;

    pessoasVisiveis.forEach((pessoa) => {
      total += calcularSomaPorPessoa(pessoa.pessoa);
    });

    return total;
  }

  function calcularSomaPorPessoaComStatusPago(pessoa: string) {
    let soma = 0;

    const faturasDaPessoa = tempFatura.filter((fatura) => fatura.pessoa === pessoa && fatura.status === 'pago');

    faturasDaPessoa.forEach((fatura) => {
      soma += fatura.valor;
    });

    return soma;
  }

  function calcularValorTotalComStatusPago() {
    let total = 0;

    pessoasVisiveis.forEach((pessoa) => {
      total += calcularSomaPorPessoaComStatusPago(pessoa.pessoa);
    });

    return total;
  }

  function calcularRestantePorPessoa(pessoa: PessoaVisivelProps) {
    const faturasDaPessoa = tempFatura.filter((fatura) => fatura.pessoa === pessoa.pessoa);

    const somaFaturasPessoa = faturasDaPessoa.reduce((soma, fatura) => soma + fatura.valor, 0);

    const somaFaturasPagas = faturasDaPessoa
      .filter((fatura) => fatura.status === 'pago')
      .reduce((soma, fatura) => soma + fatura.valor, 0);

    return somaFaturasPessoa - somaFaturasPagas;
  }

  function calcularValorTotalRestante() {
    let total = 0;

    pessoasVisiveis.forEach((pessoa) => {
      total += calcularRestantePorPessoa(pessoa);
    });

    return total;
  }

  function closeModal() {
    setMostrarModal(false);
  };

  useEffect(() => {
    if (pessoasVisiveis.length > 0) {
      const nomesVisiveis = pessoasVisiveis.map((pessoa) => pessoa.pessoa);

      const faturasFiltradas = tempFatura.filter((item) => nomesVisiveis.includes(item.pessoa));
      setDadosFiltrados(faturasFiltradas);
    } else {
      setDadosFiltrados(tempFatura);
    }
  }, [pessoasVisiveis, tempFatura]);

  useEffect(() => {
    if (mostrarModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Limpeza na desmontagem
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [mostrarModal]);


  return (
    <>
      <Container key={banco.id} cor={banco.cor} aberto={aberto.toString()} onClick={() => setAberto(!aberto)}>
        <h2>{banco.banco}</h2>
        <IoIosArrowDown className={'setaAberto'} />
      </Container>
      {
        aberto && (
          <>
            <ValorFaturaContainer>
              <ValorFaturaConteudo>
                <ValorFaturaTitulo cor="azul">
                  <span>Fatura</span>
                  <FaRegMoneyBillAlt />
                </ValorFaturaTitulo>
                <Linha />
                {pessoasVisiveis.map((pessoa) => (
                  <ValorFaturaPessoa key={pessoa.id}>
                    <h4>{pessoa.pessoa}:</h4>
                    <span>
                      {currency.mask({
                        locale: 'pt-BR',
                        currency: 'BRL',
                        value: calcularSomaPorPessoa(pessoa.pessoa)
                      })}
                    </span>
                  </ValorFaturaPessoa>
                ))}
                <Linha />
                <ValorFaturaPessoa>
                  <h4>Total: </h4>
                  <span>
                    {currency.mask({
                      locale: 'pt-BR',
                      currency: 'BRL',
                      value: calcularValorTotal()
                    })}
                  </span>
                </ValorFaturaPessoa>
              </ValorFaturaConteudo>

              <ValorFaturaConteudo>
                <ValorFaturaTitulo cor="verde">
                  <span>Adiantamento</span>
                  <BsArrowUpCircle />
                </ValorFaturaTitulo>
                <Linha />
                {pessoasVisiveis.map((pessoa) => (
                  <ValorFaturaPessoa key={pessoa.id}>
                    <h4>{pessoa.pessoa}:</h4>
                    <span>
                      {currency.mask({
                        locale: 'pt-BR',
                        currency: 'BRL',
                        value: calcularSomaPorPessoaComStatusPago(pessoa.pessoa)
                      })}
                    </span>
                  </ValorFaturaPessoa>
                ))}
                <Linha />
                <ValorFaturaPessoa>
                  <h4>Total: </h4>
                  <span>
                    {currency.mask({
                      locale: 'pt-BR',
                      currency: 'BRL',
                      value: calcularValorTotalComStatusPago()
                    })}
                  </span>
                </ValorFaturaPessoa>
              </ValorFaturaConteudo>

              <ValorFaturaConteudo>
                <ValorFaturaTitulo cor="vermelho">
                  <span>Restante</span>
                  <BsArrowDownCircle />
                </ValorFaturaTitulo>
                <Linha />
                {pessoasVisiveis.map((pessoa) => (
                  <ValorFaturaPessoa key={pessoa.id}>
                    <h4>{pessoa.pessoa}:</h4>
                    <span>
                      {currency.mask({
                        locale: 'pt-BR',
                        currency: 'BRL',
                        value: calcularRestantePorPessoa(pessoa)
                      })}
                    </span>
                  </ValorFaturaPessoa>
                ))}
                <Linha />
                <ValorFaturaPessoa>
                  <h4>Total: </h4>
                  <span>
                    {currency.mask({
                      locale: 'pt-BR',
                      currency: 'BRL',
                      value: calcularValorTotalRestante()
                    })}
                  </span>
                </ValorFaturaPessoa>
              </ValorFaturaConteudo>
            </ValorFaturaContainer>

            <Botao
              cor="primario"
              texto={`Nova transação no ${banco.banco}`}
              onClick={() => { setMostrarModal(true); setFatura(undefined); setBancoParaCriar(banco.banco); }}
              style={{ marginTop: '1rem', width: '89%' }}
            />

            <Pesquisa>
            </Pesquisa>
            <Tabela>
              <thead>
                <tr>
                  <th>{naoEstaEscondido ? 'Status' : ''}</th>
                  <th>Valor</th>
                  <th>Descrição</th>
                  <th>{naoEstaEscondido ? 'P.Atual' : 'P.A'}</th>
                  <th></th>
                  <th>{naoEstaEscondido ? 'P.Total' : 'P.T'}</th>
                  {naoEstaEscondido && <th>Data</th>}
                  <th>Pessoa</th>
                  {naoEstaEscondido && <th></th>}
                </tr>
              </thead>
              <tbody>
                {dadosFiltrados.map((fatura: any) => (
                  <tr
                    className={checkedItems[fatura.id] ? 'checked' : ''}
                    key={fatura.id}
                  >
                    <td onClick={() => moveParaPaginaEditarTransacao(fatura, banco.banco)}>
                      <Status status={fatura.status} />
                    </td>
                    <td onClick={() => moveParaPaginaEditarTransacao(fatura, banco.banco)}>
                      {currency.mask({
                        locale: 'pt-BR',
                        currency: 'BRL',
                        value: fatura.valor
                      })}
                    </td>
                    <td onClick={() => moveParaPaginaEditarTransacao(fatura, banco.banco)}>
                      {fatura.descricao}
                    </td>
                    <td onClick={() => moveParaPaginaEditarTransacao(fatura, banco.banco)}>
                      {fatura.parcela_atual}
                    </td>
                    <td onClick={() => moveParaPaginaEditarTransacao(fatura, banco.banco)}>
                      x
                    </td>
                    <td onClick={() => moveParaPaginaEditarTransacao(fatura, banco.banco)}>
                      {fatura.parcela_total}
                    </td>
                    {naoEstaEscondido && (
                      <td onClick={() => moveParaPaginaEditarTransacao(fatura, banco.banco)}>
                        {formatarData(fatura.data)}
                      </td>
                    )}
                    <td onClick={() => moveParaPaginaEditarTransacao(fatura, banco.banco)}>
                      <TbPessoa
                        cor={
                          pessoasVisiveis.find(
                            (p: any) => p.pessoa === fatura.pessoa
                          )?.cor ?? 'azul'
                        }
                      >
                        {fatura.pessoa}
                      </TbPessoa>
                    </td>
                    {naoEstaEscondido && (
                      <td>
                        <input
                          type="checkbox"
                          checked={checkedItems[fatura.id] || false}
                          onChange={(event) =>
                            setCheckedItems({
                              ...checkedItems,
                              [fatura.id]: event.target.checked
                            })
                          }
                        />
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Tabela>
          </>
        )
      }
      {mostrarModal && (
        <PaginaTransacaoCal banco={fatura} pessoas={pessoasVisiveis} nomeBanco={bancoParaCriar} closeModal={closeModal} />
      )}
    </>
  )
}
