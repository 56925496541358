import React from 'react';

import { Certificado } from '../../../Components/Certificado';
import { certificados } from '../../../utils/estudos';
import { ConteudoContainer, Estudos } from './styles';

export function EstudoSection() {
  return (
    <ConteudoContainer id="estudos">
      <h1 className="titulo crescer-pouco">ESTUDOS</h1>
      <Estudos>
        {certificados.map((certificado) => (
          <Certificado
            id={certificado.id}
            nome={certificado.nome}
            imagens={certificado.imagens}
            key={certificado.id}
          />
        ))}
      </Estudos>
    </ConteudoContainer>
  );
}
