import csj_academy from '../assets/csj-academy.jpg';
import csj_gamedesign from '../assets/csj-gamedesign.jpg';
import origamid_css_avancado_posicionamento from '../assets/origamid-css-avancado-posicionamento.png';
import origamid_css_flexbox from '../assets/origamid-css-flexbox.png';
import origamid_css_grid_layout from '../assets/origamid-css-grid-layout.png';
import origamid_html_css_iniciantes from '../assets/origamid-html-css-iniciantes.png';
import origamid_javascript_completo_es6 from '../assets/origamid-javascript-completo-es6.png';
import origamid_javascript_jquery from '../assets/origamid-javascript-jquery.png';
import origamid_react_completo from '../assets/origamid-react-completo.png';
import origamid_redux_react from '../assets/origamid-redux-react.png';
import origamid_tipografia_avancada_tipografia_avancadactjs from '../assets/origamid-tipografia-avancada.png';
import origamid_ui_design_avancado from '../assets/origamid-ui-design-avancado.png';
import origamid_ui_design_para_iniciantes from '../assets/origamid-ui-design-para-iniciantes.png';
import origamid_ux_design_heuristicas from '../assets/origamid-ux-design-heuristicas.png';
import rocketseat_reactnative from '../assets/rocketseat-react-native.png';
import rocketseat_reactjs from '../assets/rocketseat-reactjs.png';

interface CertificadoProps {
  id: string;
  imagens: { id: string; imagem: string }[];
  nome: string;
}

export const certificados: CertificadoProps[] = [
  {
    id: '1',
    nome: 'Rocketseat',
    imagens: [
      {
        id: '1',
        imagem: rocketseat_reactjs
      },
      {
        id: '2',
        imagem: rocketseat_reactnative
      }
    ]
  },
  {
    id: '2',
    nome: 'Origamid',
    imagens: [
      {
        id: '1',
        imagem: origamid_css_avancado_posicionamento
      },
      {
        id: '2',
        imagem: origamid_css_flexbox
      },
      {
        id: '3',
        imagem: origamid_css_grid_layout
      },
      {
        id: '4',
        imagem: origamid_html_css_iniciantes
      },
      {
        id: '5',
        imagem: origamid_javascript_completo_es6
      },
      {
        id: '6',
        imagem: origamid_javascript_jquery
      },
      {
        id: '7',
        imagem: origamid_tipografia_avancada_tipografia_avancadactjs
      },
      {
        id: '8',
        imagem: origamid_ui_design_avancado
      },
      {
        id: '9',
        imagem: origamid_ui_design_para_iniciantes
      },
      {
        id: '10',
        imagem: origamid_ux_design_heuristicas
      },
      {
        id: '11',
        imagem: origamid_react_completo
      },
      {
        id: '12',
        imagem: origamid_redux_react
      }
    ]
  },
  {
    id: '3',
    nome: 'CrieSeusJogos',
    imagens: [
      {
        id: '1',
        imagem: csj_academy
      },
      {
        id: '2',
        imagem: csj_gamedesign
      }
    ]
  }
];
