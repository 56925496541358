import React, { useEffect, useState, useContext } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiMenu } from 'react-icons/bi';
import { FiSun, FiMoon } from 'react-icons/fi';
import { Link } from 'react-scroll';

import { ThemeContext } from '../../App';
import { Container, Logo, Conteudo } from './styles';

export function Header() {
  const [moveu, setMoveu] = useState(false);
  const [aberto, setAberto] = useState(false);
  const { theme, setTheme } = useContext(ThemeContext);

  useEffect(() => {
    document.title = 'Gustavo Castro';
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setMoveu(true);
      } else {
        setMoveu(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (aberto) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [aberto]);

  return (
    <Container moveu={moveu}>
      <Conteudo>
        <Logo moveu={moveu}>Gustavo</Logo>
        <menu className="menu-primario">
          <ul>
            <li>
              <Link smooth spy to="home" activeClass="ativo">
                Home
              </Link>
            </li>
            <li>
              <Link smooth spy to="sobre" activeClass="ativo">
                Sobre
              </Link>
            </li>
            <li>
              <Link smooth spy to="estudos" activeClass="ativo">
                Estudos
              </Link>
            </li>
            <li>
              <Link smooth spy to="projetos" activeClass="ativo">
                Projetos
              </Link>
            </li>
            <li>
              <Link smooth spy to="experiencia" activeClass="ativo">
                Experiência
              </Link>
            </li>
            <li>
              <Link smooth spy to="contato" activeClass="ativo">
                Contato
              </Link>
            </li>
          </ul>
          {theme == 'dark' ? (
            <FiSun
              onClick={() => {
                setTheme('light');
                localStorage.setItem('gustavoTheme', 'light');
              }}
            />
          ) : (
            <FiMoon
              onClick={() => {
                setTheme('dark');
                localStorage.setItem('gustavoTheme', 'dark');
              }}
            />
          )}
        </menu>
        <BiMenu className="icone-menu" onClick={() => setAberto(true)} />
        {aberto && (
          <menu className="menu-secundario">
            <ul>
              <li>
                <Link
                  smooth
                  spy
                  to="home"
                  activeClass="ativo"
                  onClick={() => setAberto(false)}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  smooth
                  spy
                  to="sobre"
                  activeClass="ativo"
                  onClick={() => setAberto(false)}
                >
                  Sobre
                </Link>
              </li>
              <li>
                <Link
                  smooth
                  spy
                  to="estudos"
                  activeClass="ativo"
                  onClick={() => setAberto(false)}
                >
                  Estudos
                </Link>
              </li>
              <li>
                <Link
                  smooth
                  spy
                  to="projetos"
                  activeClass="ativo"
                  onClick={() => setAberto(false)}
                >
                  Projetos
                </Link>
              </li>
              <li>
                <Link
                  smooth
                  spy
                  to="experiencia"
                  activeClass="ativo"
                  onClick={() => setAberto(false)}
                >
                  Experiência
                </Link>
              </li>
              <li>
                <Link
                  smooth
                  spy
                  to="contato"
                  activeClass="ativo"
                  onClick={() => setAberto(false)}
                >
                  Contato
                </Link>
              </li>
            </ul>
            {theme == 'dark' ? (
              <FiSun
                onClick={() => {
                  setTheme('light');
                  localStorage.setItem('gustavoTheme', 'light');
                }}
              />
            ) : (
              <FiMoon
                onClick={() => {
                  setTheme('dark');
                  localStorage.setItem('gustavoTheme', 'dark');
                }}
              />
            )}
            <AiOutlineCloseCircle
              className="fechar"
              onClick={() => setAberto(false)}
            />
          </menu>
        )}
      </Conteudo>
    </Container>
  );
}
