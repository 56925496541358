import React, { useEffect, useState } from 'react';
import { MdOutlineDeleteForever, MdContentCopy } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Botao } from '../../Components/Botao';
import { Check } from '../../Components/Check';
import { Input } from '../../Components/Input';
import { TextArea } from '../../Components/Textarea';
import { Titulo } from '../../Components/Titulo';
import { db } from '../../config/firebase';
import titulos from '../../utils/titulos.json';
import {
  Container,
  Form,
  InputParcela,
  TipoTransacao,
  Tipo,
  Checks,
  Util
} from './styles';

import { format } from 'date-fns';
import {
  addDoc,
  collection,
  updateDoc,
  doc,
  deleteDoc
} from 'firebase/firestore';
import { currency } from 'remask';

interface PessoaProps {
  id?: string;
  pessoa: string;
}

interface PaginaTransacaoCalProps {
  banco?: any;
  pessoas?: any[];
  nomeBanco?: string;
  closeModal?: () => void;
}

export function PaginaTransacaoCal({ pessoas, nomeBanco, banco, closeModal }: PaginaTransacaoCalProps) {
  const [descricao, setDescricao] = useState(banco ? banco.descricao : '');
  const [valor, setValor] = useState(
    banco ? formatarValor(String(banco.valor)) : '0'
  );
  const [parcelaAtual, setParcelaAtual] = useState(
    banco ? banco.parcela_atual : 1
  );
  const [parcelaTotal, setParcelaTotal] = useState(
    banco ? banco.parcela_total : 1
  );
  const [data, setData] = useState(
    banco ? banco.data : format(new Date(), 'yyyy-MM-dd')
  );
  const [valorFormatado, setValorFormatado] = useState('');
  const [selecao, setSelecao] = useState(banco ? banco.pessoa : 'gustavo');
  const [tipoPagamento, setTipoPagamento] = useState(
    banco ? banco.status : 'compra'
  );
  const [comentario, setComentario] = useState(banco ? banco.comentario : '');
  const [estaCarregando, setEstaCarregando] = useState(false);
  const [mensal, setMensal] = useState(banco ? banco.mensal : false);
  const [copiar, setCopiar] = useState(false);
  const descricaoOriginal = banco ? banco.descricao : '';
  const valorOriginal = banco ? formatarValor(String(banco.valor)) : '';
  const parcelaAtualOriginal = banco ? banco.parcela_atual : 1;
  const parcelaTotalOriginal = banco ? banco.parcela_total : 1;
  const dataOriginal = banco ? banco.data : '';
  const pessoaOriginal = banco ? banco.pessoa : '';
  const statusOriginal = banco ? banco.status : '';
  const comentarioOriginal = banco ? banco.comentario : '';
  const mensalOriginal = banco ? banco.mensal : false;

  const handleCloseModal = () => {
    if (closeModal) {
      closeModal();
    }
  };

  async function lidarCriacaoTransacao(
    event: React.FormEvent<HTMLFormElement>
  ) {
    event.preventDefault();
    setEstaCarregando(true);
    const caixaBaixaPessoa = selecao.toLowerCase();
    if (descricao.length >= 1 && valor != '0') {
      const formatadoTemp = currency.unmask({
        locale: 'pt-BR',
        currency: 'BRL',
        value: valor
      });

      addDoc(collection(db, `fatura_atual_${nomeBanco}`), {
        descricao,
        valor: formatadoTemp,
        pessoa: caixaBaixaPessoa,
        parcela_atual: parcelaAtual,
        parcela_total: parcelaTotal,
        data: data,
        status: tipoPagamento,
        comentario,
        mensal
      })
        .then(() => {
          addDoc(collection(db, 'logs'), {
            titulo: `Transação criada no banco <span class='info'>${nomeBanco}</span> por <span class='info'>Cal</span>`,
            descricao: `
            <div class="descricao">
              Título: <span class='info'>${descricao}</span><br/>
              Comentário: <span class='info'>${comentario}</span><br/>
              Valor: <span class='info'>R$ ${formatadoTemp}</span><br/>
              pessoa: <span class='info'>${caixaBaixaPessoa}</span><br/>
              Parcela: <span class='info'>${parcelaAtual}/${parcelaTotal}</span><br/>
              Data: <span class='info'>${data}</span><br/>
              Status: <span class='info'>${tipoPagamento}</span><br/>
              Mensal: <span class='info'>${mensal ? 'Sim' : 'Não'}</span>
            </div>
            `,
            tipo: 'transacao',
            data: new Date()
          }).then(() => {
            toast.success('Transação criada com sucesso.');
            window.location.reload();
          });
        })
        .catch(() => {
          toast.error('Aconteceu algum erro. Tente novamente mais tarde.');
        })
        .finally(() => {
          setEstaCarregando(false);
        });
    } else {
      toast.warning('Descrição não pode ser vazia, ou valor igual a R$0');
      setEstaCarregando(false);
    }
  }

  async function lidarModificarTransacao(
    event: React.FormEvent<HTMLFormElement>,
    id: string
  ) {
    event.preventDefault();
    if (descricao !== descricaoOriginal || valor !== valorOriginal || parcelaAtual !== parcelaAtualOriginal ||
      parcelaTotal !== parcelaTotalOriginal || data !== dataOriginal || selecao !== pessoaOriginal || tipoPagamento
      !== statusOriginal || comentario !== comentarioOriginal || mensal !== mensalOriginal) {
      setEstaCarregando(true);

      const caixaBaixaPessoa = selecao.toLowerCase();

      if (descricao.length >= 1 && valor !== '0') {
        const formatadoTemp = currency.unmask({
          locale: 'pt-BR',
          currency: 'BRL',
          value: valor
        });

        try {
          const docRef = doc(db, `fatura_atual_${nomeBanco}`, id);
          const updateData = {
            descricao,
            valor: formatadoTemp,
            pessoa: caixaBaixaPessoa,
            parcela_atual: parcelaAtual,
            parcela_total: parcelaTotal,
            data: data,
            status: tipoPagamento,
            comentario,
            mensal
          };

          await updateDoc(docRef, updateData).then(() => {
            addDoc(collection(db, 'logs'), {
              titulo: `Transação modificada <span class='info'>${descricaoOriginal !== descricao ? `${descricaoOriginal}`
                : `${descricao}</span> do banco <span class='info'>${nomeBanco}</span> por <span class='info'>Cal</span>`}`,
              descricao: `
              <div class="descricaoAntigo">
                ${descricaoOriginal !== descricao ? `Título: <span class='info'>${descricaoOriginal}</span><br/>` : ''}
                ${valorOriginal !== valor ? `Valor: <span class='info'>R$ ${valorOriginal}</span><br/>` : ''}
                ${comentarioOriginal !== comentario ? `Comentário: <span class='info'>${comentarioOriginal}</span><br/>` : ''}
                ${parcelaAtualOriginal !== parcelaAtual ? `Parcela: <span class='info'>${parcelaAtualOriginal}/${parcelaTotalOriginal}</span><br/>` : ''}
                ${dataOriginal !== data ? `Data: <span class='info'>${dataOriginal}</span><br/>` : ''}
                ${statusOriginal !== tipoPagamento ? `Status: <span class='info'>${statusOriginal}</span><br/>` : ''}
                ${mensalOriginal !== mensal ? `Mensal: <span class='info'>${mensalOriginal ? 'Sim' : 'Não'}</span><br/>` : ''}
                ${pessoaOriginal !== caixaBaixaPessoa ? `pessoa: <span class='info'>${pessoaOriginal}</span><br/>` : ''}
              </div>
              <div class="descricao">
                ${descricao !== descricaoOriginal ? `Título: <span class='info'>${descricao}</span><br/>` : ''}
                ${valor !== valorOriginal ? `Valor: <span class='info'>R$ ${formatadoTemp}</span><br/>` : ''}
                ${comentario !== comentarioOriginal ? `Comentário: <span class='info'>${comentario}</span><br/>` : ''}
                ${parcelaAtual !== parcelaAtualOriginal ? `Parcela: <span class='info'>${parcelaAtual}/${parcelaTotal}</span><br/>` : ''}
                ${data !== dataOriginal ? `Data: <span class='info'>${data}</span><br/>` : ''}
                ${tipoPagamento !== statusOriginal ? `Status: <span class='info'>${tipoPagamento}</span><br/>` : ''}
                ${mensal !== mensalOriginal ? `Mensal: <span class='info'>${mensal ? 'Sim' : 'Não'}</span><br/>` : ''}
                ${caixaBaixaPessoa !== pessoaOriginal ? `pessoa: <span class='info'>${caixaBaixaPessoa}</span><br/>` : ''}
              </div>
              `,
              tipo: 'transacao',
              data: new Date()
            }).then(() => {
              toast.success('Transação modificada com sucesso.');
              window.location.reload();
            });
          });
        } catch (error) {
          toast.error('Aconteceu algum erro. Tente novamente mais tarde.');
        } finally {
          setEstaCarregando(false);
        }
      } else {
        toast.warning('Descrição não pode ser vazia, ou valor igual a R$0');
        setEstaCarregando(false);
      }
    } else {
      toast.warning('Nenhum dado foi modificado.');
    }
  }

  function formatarValor(valor: string) {
    if (valor.indexOf('.') === -1) {
      return valor + '.00';
    } else if (valor.match(/\.\d$/)) {
      return valor + '0';
    } else {
      return valor;
    }
  }

  function lidarComDeletarTransacao() {
    const confirmacao = window.confirm('Deseja deletar essa transação?');
    if (confirmacao) {
      const documentoRef = doc(db, `fatura_atual_${nomeBanco}`, banco.id);

      deleteDoc(documentoRef)
        .then(() => {
          addDoc(collection(db, 'logs'), {
            titulo: `Transação deletada <span class='tituloAntigo'>${descricaoOriginal}</span> do banco <span class='info'>
            ${nomeBanco}</span> por <span class='info'>Cal</span>`,
            descricao: `
            <div class="descricaoAntigo">
              Título: <span class='info'>${descricaoOriginal}</span><br/>
              Comentário: <span class='info'>${comentarioOriginal}</span><br/>
              Valor: <span class='info'>R$ ${valorOriginal}</span><br/>
              pessoa: <span class='info'>${pessoaOriginal}</span><br/>
              Parcela: <span class='info'>${parcelaAtualOriginal}/${parcelaTotalOriginal}</span><br/>
              Data: <span class='info'>${dataOriginal}</span><br/>
              Status: <span class='info'>${statusOriginal}</span><br/>
              Mensal: <span class='info'>${mensalOriginal ? 'Sim' : 'Não'}</span>
            </div>
            `,
            tipo: 'transacao',
            data: new Date()
          }).then(() => {
            toast.success('Transação deletada com sucesso.');
            window.location.reload();
          });
        })
        .catch(() => {
          toast.error('Aconteceu algum erro, tente novamente mais tarde.');
        });
    }
  }

  useEffect(() => {
    document.title = titulos.texto.fatura_atual;
  }, []);

  useEffect(() => {
    const formatadoTemp = currency.unmask({
      locale: 'pt-BR',
      currency: 'BRL',
      value: valor
    });

    setValorFormatado(
      currency.mask({
        locale: 'pt-BR',
        currency: 'BRL',
        value: formatadoTemp
      })
    );
  }, [valor]);

  return (
    <Container>
      <Titulo style={{ marginBottom: banco ? '4rem' : '5rem', fontSize: '0.7rem' }}>
        {banco && !copiar
          ? 'Modificar transação'
          : `${titulos.texto.nova_transacao} ${nomeBanco ? nomeBanco.charAt(0).toUpperCase() + nomeBanco.slice(1) : ''
          }`}
      </Titulo>
      {banco ? (
        <Util>
          {!copiar && <MdContentCopy onClick={() => setCopiar(true)} />}

          <MdOutlineDeleteForever
            onClick={() => lidarComDeletarTransacao()}
            className="svgDelete"
          />
        </Util>
      ) : (
        <></>
      )}

      <Form
        onSubmit={
          banco?.id && !copiar
            ? (event) => lidarModificarTransacao(event, banco.id)
            : lidarCriacaoTransacao
        }
      >
        <Input
          value={descricao}
          onChange={(e) => setDescricao(e.target.value)}
          titulo="Descrição"
        />
        <Input
          value={valorFormatado}
          onChange={(e) => setValor(e.target.value)}
          titulo="Valor"
          className="meio"
        />
        <TextArea
          texto="Comentário"
          value={comentario}
          onChange={(e) => setComentario(e.target.value)}
          style={{ width: '85%' }}
        />
        <InputParcela>
          <span>Parcela Atual</span>
          <div>
            <button
              onClick={() => {
                if (parcelaAtual != 1) {
                  setParcelaAtual(parcelaAtual - 1);
                }
              }}
              type="button"
            >
              -
            </button>
            <input value={parcelaAtual} disabled />
            <button
              onClick={() => {
                if (parcelaAtual != parcelaTotal) {
                  setParcelaAtual(parcelaAtual + 1);
                }
              }}
              type="button"
            >
              +
            </button>
          </div>
        </InputParcela>
        <InputParcela>
          <span>Parcela Total</span>
          <div>
            <button
              onClick={() => {
                if (parcelaTotal != 1) {
                  setParcelaTotal(parcelaTotal - 1);
                  if (parcelaAtual == parcelaTotal) {
                    setParcelaAtual(parcelaAtual - 1);
                  }
                }
              }}
              type="button"
            >
              -
            </button>
            <input value={parcelaTotal} disabled />
            <button
              onClick={() => setParcelaTotal(parcelaTotal + 1)}
              type="button"
            >
              +
            </button>
          </div>
        </InputParcela>
        <Input
          type="date"
          value={data}
          onChange={(e) => setData(e.target.value)}
          titulo="Data"
          className="meio"
        />
        <div className="select">
          <select
            id="selecao"
            value={selecao}
            onChange={(e) => setSelecao(e.target.value)}
          >
            {pessoas?.map((pessoa: PessoaProps) => (
              <option value={pessoa.pessoa} key={pessoa.id}>
                {pessoa.pessoa}
              </option>
            ))}
          </select>
        </div>
        <TipoTransacao>
          <Tipo
            cor="vermelho"
            ativo={tipoPagamento == 'compra' ? 'ativo' : ''}
            onClick={() => setTipoPagamento('compra')}
            type="button"
          >
            Compra
          </Tipo>
          <Tipo
            cor="verde"
            ativo={tipoPagamento == 'pago' ? 'ativo' : ''}
            onClick={() => setTipoPagamento('pago')}
            type="button"
          >
            Adiantamento
          </Tipo>
        </TipoTransacao>

        <Checks>
          <Check
            onClick={() => setMensal(!mensal)}
            ativo={mensal}
            texto="Mensal (Não remover quando fechar fatura)"
          />
        </Checks>

        <Botao
          cor="primario"
          texto={banco && !copiar ? 'Modificar Transação' : 'Criar Transação'}
          estaCarregando={estaCarregando}
          style={{ width: '8.5rem' }}
        />

        <Botao
          cor="vermelho"
          texto="Cancelar"
          style={{ width: '8.5rem', marginBottom: '1rem', color: 'var(--branco)' }}
          onClick={() => handleCloseModal()}
          type="button"
        />
      </Form>
    </Container>
  );
}
