import React, { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';

import { Titulo } from '../../Components/Titulo';
import { db } from '../../config/firebase';
import titulos from '../../utils/titulos.json';
import {
  Container,
  Carregando,
  Logs,
  Log
} from './styles';

import { Timestamp, collection, getDocs } from 'firebase/firestore';
import { Botao } from '../../Components/Botao';

interface LogProps {
  id: string;
  descricao: string;
  titulo: string;
  tipo: string;
  data: Timestamp;
}

export function PaginaLog() {
  const [dados, setDados] = useState<LogProps[]>([]);
  const [dadosFiltrados, setDadosFiltrados] = useState<LogProps[]>([]);
  const [numLogs, setNumLogs] = useState(10);
  const [selecao, setSelecao] = useState('Todos');
  const [carregando, setCarregando] = useState(true);

  useEffect(() => {
    if (selecao === 'Data Decrecente') {
      const logsArray = [...dados];
      logsArray.sort((a, b) => b.data.toMillis() - a.data.toMillis());
      setDadosFiltrados(logsArray);
    } else if (selecao === 'Data Crescente') {
      const logsArray = [...dados];
      logsArray.sort((a, b) => a.data.toMillis() - b.data.toMillis());
      setDadosFiltrados(logsArray);
    } else if (selecao === 'transacao' || selecao === 'banco' || selecao === 'pessoa' || selecao === 'cal' || selecao === 'anotação' || selecao === 'fatura') {
      const logsArray = dados.filter(item => item.tipo === selecao);
      setDadosFiltrados(logsArray);
    } else {
      setDadosFiltrados(dados);
    }
  }, [selecao]);

  async function buscarDados() {
    const logsCollection = collection(db, 'logs');
    const logsQuerySnapshot = await getDocs(logsCollection);
    const logsArray: any[] = [];

    logsQuerySnapshot.forEach((doc) => {
      logsArray.push({ id: doc.id, ...doc.data() });
    });

    logsArray.sort((a, b) => b.data.toMillis() - a.data.toMillis());

    setDados(logsArray);
    setDadosFiltrados(logsArray);
    setCarregando(false);
  }

  useEffect(() => {
    document.title = titulos.texto.log;
    buscarDados();
  }, []);

  return (
    <Container>
      <Titulo style={{ marginBottom: '2rem' }}>{titulos.texto.log}</Titulo>
      {carregando ? (
        <Carregando>
          <TailSpin height="50" width="50" color="#ffffff" />
        </Carregando>
      ) : (
        <>
          <div className="select">
            Filtrar por:
            <select
              id="selecao"
              value={selecao}
              onChange={(e) => setSelecao(e.target.value)}
            >
              <option value='Todos'>
                Todos
              </option>
              <option value='Data Decrecente'>
                Data decrecente
              </option>
              <option value='Data Crescente'>
                Data crescente
              </option>
              <option value='transacao'>
                Transações
              </option>
              <option value='banco'>
                Bancos
              </option>
              <option value='pessoa'>
                Pessoas
              </option>
              <option value='cal'>
                Cal
              </option>
              <option value='anotação'>
                Anotações
              </option>
              <option value='fatura'>
                Faturas
              </option>
            </select>
          </div>
          <Logs>
            {dadosFiltrados.slice(0, numLogs)
              .map((item) => (
                <Log key={item.id}>
                  <div className='logTitulo' dangerouslySetInnerHTML={{ __html: item.titulo }} />
                  <div dangerouslySetInnerHTML={{ __html: item.descricao }} />
                  <span className='data'>{item.data.toDate().toLocaleString()}</span>
                </Log>
              ))}
          </Logs>
        </>
      )}
      {dadosFiltrados.length > numLogs && (
        <Botao
          onClick={() => setNumLogs(numLogs + 10)}
          texto="Carregar mais"
          cor="primario"
          style={{ marginTop: '2rem' }}
        />
      )}
    </Container>
  );
}
