import React, { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { MdWorkOutline } from "react-icons/md";
import { SiFreelancer } from "react-icons/si";
import { GiPerspectiveDiceSixFacesRandom } from "react-icons/gi";
import { FaArrowTrendUp } from "react-icons/fa6";
import { TiDelete } from "react-icons/ti";

import { Titulo } from '../../Components/Titulo';
import { db } from '../../config/firebase';
import titulos from '../../utils/titulos.json';
import {
  Container,
  Carregando,
  Cards,
  Card,
  Esquerda,
  Direita,
  Icon,
  Form,
  TipoTransacao,
  Tipo,
  CardContainer,
  Data
} from './styles';

import { addDoc, collection, deleteDoc, doc, getDocs, onSnapshot, Timestamp } from 'firebase/firestore';
import { Input } from '../../Components/Input';
import { currency } from 'remask';
import { Botao } from '../../Components/Botao';
import { toast } from 'react-toastify';

interface DadosProps {
  id: string;
  valor: number;
  data: Timestamp;
  tipo: string;
  status: string;
}

interface ServicosProps {
  id: string;
  servico: string;
  icon: React.ReactNode;
}

export function PaginaGerenciamento() {
  const [carregando, setCarregando] = useState(true);
  const [dados, setDados] = useState<DadosProps[]>([]);
  const [valor, setValor] = useState('0');
  const [valorFormatado, setValorFormatado] = useState('');
  const [tipoPagamento, setTipoPagamento] = useState('entrada');
  const [estaCarregando, setEstaCarregando] = useState(false);
  const [selecao, setSelecao] = useState('Serviço');
  const [quantidadeMostrada, setQuantidadeMostrada] = useState(10);

  const ServicosData: ServicosProps[] = [
    {
      id: '1',
      servico: 'Serviço',
      icon: <MdWorkOutline />
    },
    {
      id: '2',
      servico: 'Freelance',
      icon: <SiFreelancer />
    },
    {
      id: '3',
      servico: 'Outros',
      icon: <GiPerspectiveDiceSixFacesRandom />
    }
  ]

  useEffect(() => {
    const bancosCollection = collection(db, 'gerenciamento');

    onSnapshot(bancosCollection, (snapshot) => {
      const dados: DadosProps[] = [];
      snapshot.forEach((doc) => {
        dados.push({ id: doc.id, ...doc.data() } as DadosProps);
      });

      dados.sort((a, b) => b.data.toMillis() - a.data.toMillis());
      setDados(dados);
      setCarregando(false);
    });
  }, []);

  useEffect(() => {
    const formatadoTemp = currency.unmask({
      locale: 'pt-BR',
      currency: 'BRL',
      value: valor
    });

    setValorFormatado(
      currency.mask({
        locale: 'pt-BR',
        currency: 'BRL',
        value: formatadoTemp
      })
    );
  }, [valor]);

  async function lidarCriacaoTransacao(
    event: React.FormEvent<HTMLFormElement>
  ) {
    event.preventDefault();
    setEstaCarregando(true);
    if (valor != '0') {
      const formatadoTemp = currency.unmask({
        locale: 'pt-BR',
        currency: 'BRL',
        value: valor
      });

      addDoc(collection(db, `gerenciamento`), {
        valor: formatadoTemp,
        status: tipoPagamento,
        tipo: selecao,
        data: new Date()
      })
        .then(() => {
          toast.success('Transação adicionada com sucesso.');
          setValor('0');
          setTipoPagamento('entrada');
          setSelecao('Serviço');
        })
        .catch(() => {
          toast.error('Aconteceu algum erro. Tente novamente mais tarde.');
        })
        .finally(() => {
          setEstaCarregando(false);
        });
    } else {
      toast.warning('Valor não pode ser igual a R$ 0');
      setEstaCarregando(false);
    }
  }

  async function deletarDocumento(id: string) {
    const confirmacao = window.confirm('Tem certeza que deseja deletar?');
    if (!confirmacao) {
      return;
    }
    try {
      await deleteDoc(doc(db, 'gerenciamento', id));
      toast.success('Documento deletado com sucesso.');
    } catch (error) {
      toast.error('Erro ao deletar. Tente novamente mais tarde.');
    }
  }


  useEffect(() => {
    document.title = titulos.texto.gerencimento;
  }, []);

  return (
    <Container>
      <Titulo>{titulos.texto.gerencimento}</Titulo>
      {carregando ? (
        <Carregando>
          <TailSpin color="#ffffff" height={50} width={50} />
        </Carregando>
      ) : (
        <div>
          <Cards>
            {ServicosData.map((servico) => {
              return (
                <Card key={servico.id}>
                  <Esquerda>
                    <h1>
                      {currency.mask({
                        locale: 'pt-BR',
                        currency: 'BRL',
                        value: dados
                          .filter(dado => dado.tipo === servico.servico)
                          .reduce((accumulator, currentValue) => {
                            if (currentValue.status === 'entrada') {
                              return accumulator + currentValue.valor;
                            } else {
                              return accumulator - currentValue.valor;
                            }
                          }, 0)
                      })}
                    </h1>
                    <span>{servico.servico}</span>
                    <span className='ultimo'>Último <span className={
                      dados.find(dado => dado.tipo === servico.servico)?.status === 'entrada' ? 'entrada' : 'saida'}>
                      <FaArrowTrendUp />
                      {
                        currency.mask({
                          locale: 'pt-BR',
                          currency: 'BRL',
                          value: dados.find(dado => dado.tipo === servico.servico)?.valor || 0
                        })
                      }
                    </span></span>
                  </Esquerda>
                  <Direita>
                    <Icon>
                      {servico.icon}
                    </Icon>
                  </Direita>
                </Card>
              );
            })}
          </Cards>
          <Form onSubmit={lidarCriacaoTransacao}>
            <Input
              value={valorFormatado}
              onChange={(e) => setValor(e.target.value)}
              titulo="Valor"
            />
            <TipoTransacao>
              <select
                id="selecao"
                value={selecao}
                onChange={(e) => setSelecao(e.target.value)}
              >
                <option value='Serviço'>
                  Serviço
                </option>
                <option value='Freelance'>
                  Freelance
                </option>
                <option value='Outros'>
                  Outros
                </option>
              </select>
              <Tipo
                cor="verde"
                ativo={tipoPagamento == 'entrada' ? 'ativo' : ''}
                onClick={() => setTipoPagamento('entrada')}
                type="button"
              >
                Entrada
              </Tipo>
              <Tipo
                cor="vermelho"
                ativo={tipoPagamento == 'saida' ? 'ativo' : ''}
                onClick={() => setTipoPagamento('saida')}
                type="button"
              >
                Saida
              </Tipo>
            </TipoTransacao>
            <Botao
              cor="primario"
              texto="Adicionar"
              estaCarregando={estaCarregando}
              style={{ width: '10rem' }}
              type='submit'
            />
          </Form>
          <h2>Extrato</h2>
          <Cards style={{ gap: '0.25rem', marginTop: '0.25rem' }}>
            {dados.slice(0, quantidadeMostrada)
              .map((dado) => {
                return (
                  <CardContainer cor={dado.status}>
                    <FaArrowTrendUp />
                    <span>{dado.tipo}</span>
                    <span>{currency.mask({
                      locale: 'pt-BR',
                      currency: 'BRL',
                      value: dado.valor
                    })}</span>
                    <Data>
                      <span>{dado.data.toDate().toLocaleString()}</span>
                      <TiDelete onClick={() => deletarDocumento(dado.id)} />
                    </Data>
                  </CardContainer>
                );
              })}
            {dados.length > quantidadeMostrada && (
              <Botao
                cor="primario"
                texto="Carregar mais"
                style={{ marginTop: '1rem' }}
                onClick={() => setQuantidadeMostrada(quantidadeMostrada + 10)}
              />
            )}
          </Cards>
        </div>
      )}
    </Container>
  );
}
