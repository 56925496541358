import React, { useRef } from 'react';
import { BiSearchAlt } from 'react-icons/bi';

import { Container } from './styles';

interface ProcurarProps {
  style: React.CSSProperties;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  texto: string;
  value: string;
}

export function Procurar({ style, onChange, texto, value }: ProcurarProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  function handleClick() {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  return (
    <Container onClick={handleClick} style={style}>
      <input ref={inputRef} onChange={onChange} value={value} />
      <label>{texto}</label>
      <BiSearchAlt onClick={handleClick} />
    </Container>
  );
}
