import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Botao } from '../../Components/Botao';
import { Input } from '../../Components/Input';
import { auth } from '../../config/firebase';
import titulos from '../../utils/titulos.json';
import { Container, Titulo, LoginContainer } from './styles';

import { signInWithEmailAndPassword } from 'firebase/auth';

export function PaginaLogin() {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [estaCarregando, setEstaCarregando] = useState(false);
  const navigate = useNavigate();

  function lidarComAcesso(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setEstaCarregando(true);
    signInWithEmailAndPassword(auth, email, senha)
      .then((userCredential) => {
        const usuarioParaSalvar = { email: userCredential.user.email };
        localStorage.setItem(
          'cartaoGustavoUsuario',
          JSON.stringify(usuarioParaSalvar)
        );
        navigate('/principal');
      })
      .catch((error) => {
        toast.error('Usuário ou senha incorreta.');
        setEstaCarregando(false);
      });
  }

  useEffect(() => {
    document.title = titulos.texto.login;
  }, []);

  return (
    <Container>
      <Titulo>LOGIN</Titulo>
      <LoginContainer onSubmit={lidarComAcesso}>
        <Input
          titulo="E-mail"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          titulo="Senha"
          type="password"
          value={senha}
          onChange={(e) => setSenha(e.target.value)}
        />

        <Botao texto="Acessar" cor="primario" estaCarregando={estaCarregando} />
      </LoginContainer>
    </Container>
  );
}
