import React, { useEffect, useState } from 'react';

import { Header } from '../../Components/Header';
import { ContatoSection } from '../LandingpageSections/ContatoSection';
import { EstudoSection } from '../LandingpageSections/EstudoSection';
import { ExperienciaSection } from '../LandingpageSections/ExperienciaSection';
import { HomeSection } from '../LandingpageSections/HomeSection';
import { ProjetosSection } from '../LandingpageSections/ProjetosSection';
import { SobreSection } from '../LandingpageSections/SobreSection';
import { TecIconsSection } from '../LandingpageSections/TecIconsSection';
import { Container } from './styles';

interface MousePosition {
  x: number;
  y: number;
}

export function PaginaLandingPage() {
  const [mousePosition, setMousePosition] = useState<MousePosition>({
    x: 0,
    y: 0
  });
  const [isCursorGrowing, setIsCursorGrowing] = useState<boolean | 'pouco'>(
    false
  );

  useEffect(() => {
    document.title = 'Gustavo Castro';
  }, []);

  useEffect(() => {
    function handleMouseEnterGrow() {
      setIsCursorGrowing(true);
    }

    function handleMouseEnterGrowLittle() {
      setIsCursorGrowing('pouco');
    }

    function handleMouseLeave() {
      setIsCursorGrowing(false);
    }

    function handleMouseMove(event: MouseEvent) {
      setMousePosition({ x: event.clientX, y: event.clientY });
    }

    document.querySelectorAll('.crescer').forEach((element) => {
      element.addEventListener('mouseenter', handleMouseEnterGrow);
      element.addEventListener('mouseleave', handleMouseLeave);
    });

    document.querySelectorAll('.crescer-pouco').forEach((element) => {
      element.addEventListener('mouseenter', handleMouseEnterGrowLittle);
      element.addEventListener('mouseleave', handleMouseLeave);
    });

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.querySelectorAll('.crescer').forEach((element) => {
        element.removeEventListener('mouseenter', handleMouseEnterGrow);
        element.removeEventListener('mouseleave', handleMouseLeave);
      });
      document.querySelectorAll('.crescer-pouco').forEach((element) => {
        element.removeEventListener('mouseenter', handleMouseEnterGrowLittle);
        element.removeEventListener('mouseleave', handleMouseLeave);
      });
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <Container>
      <Header />
      <div
        className={`curso ${
          isCursorGrowing === 'pouco'
            ? 'crescer-pouco'
            : isCursorGrowing
            ? 'crescer'
            : ''
        }`}
        style={{
          left: `${mousePosition.x}px`,
          top: `${mousePosition.y}px`
        }}
      ></div>

      <HomeSection />
      <SobreSection />
      <TecIconsSection />
      <EstudoSection />
      <ProjetosSection />
      <ExperienciaSection />
      <ContatoSection />
    </Container>
  );
}
