import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
`;

export const LoginContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 20rem;

  input + input {
    margin-top: 1rem;
  }

  button {
    margin-top: 2rem;
  }
`;

export const Titulo = styled.h1`
  margin-bottom: 1rem;
  color: var(--primario);
`;
