import styled from 'styled-components';

interface CorProps {
  ativo?: string;
  cor: string;
}

export const Container = styled.div`
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
  display: flex;
  min-height: 100vh;
  padding: 1rem;
  align-items: center;
  flex-direction: column;

  @media (max-width: 728px) {
    padding: 1rem 0;
  }

  h2 {
    margin-top: 1rem;
  }
`;

export const Carregando = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 1rem;
  justify-content: center;
`;

export const Card = styled.div`
  width: 100%;
  width: 20rem;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--cinza);
  gap: 1rem;
  margin-top: 2rem;
`;

export const Esquerda = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 1rem;
  }
  
  .ultimo {
    color: var(--cinza);
    filter: brightness(2);
    display: flex;
    gap: 0.5rem;
    
    span {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

  .entrada {
      color: var(--primario);
      filter: brightness(0.5);
    }
    
    .saida {
      color: var(--vermelho);
      filter: brightness(0.5);

      svg {
        rotate: 180deg;
      }
    }
  }
`;

export const Direita = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  padding: 1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(69, 248, 130, 0.5);
  
  svg {
    color: var(--primario);
    font-size: 2rem;
  }
`;

export const Checks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  select {
    margin-left: 1rem;
    color: var(--branco);
    background-color: var(--fundo);
    text-transform: capitalize;
    height: 3.3rem;
    width: 9.4rem;
    border-radius: 0.25rem;
    border: none;
    text-align: center;
    margin-left: 0rem;
    border: 1px solid var(--cinza);
    cursor: pointer;
  }
`;

export const TipoTransacao = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const Tipo = styled.button<CorProps>`
  margin-top: 0 !important;
  width: 10rem;
  height: 3rem;
  background: ${({ ativo, cor }) =>
    ativo == 'ativo' ? `var(--${cor})` : 'var(--fundo)'};

  border: ${({ ativo, cor }) =>
    ativo == 'ativo' ? `1px solid var(--${cor})` : '1px solid var(--cinza)'};
  border-radius: 0.2rem;
`;

export const Data = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  opacity: 0.5;
  font-size: 0.9rem;
  color: var(--branco);
  align-items: center;
  gap: 0.25rem;

  svg {
    font-size: 1.2rem !important;
    margin-bottom: 0.1rem;
    cursor: pointer;

    &:hover {
      color: var(--vermelho);
    }
  }
`;

export const CardContainer = styled.div<CorProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  border: ${({ cor }) => cor == 'entrada' ? '1px dotted rgba(0, 255, 0, 0.1);' : '1px dotted rgba(255, 0, 0, 0.1)'};
  padding: 1rem;
  border-radius: 0.5rem;
  background: ${({ cor }) => cor == 'entrada' ? 'rgba(0, 255, 0, 0.1);' : 'rgba(255, 0, 0, 0.1)'};
  color: ${({ cor }) => cor == 'entrada' ? 'var(--primario);' : 'var(--vermelho);'};

  svg {
    font-size: 1.5rem;
    transform: ${({ cor }) => cor == 'entrada' ? 'rotate(0deg);' : 'rotate(180deg);'};
  }
`;
