import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  min-height: 100vh;
  padding: 1rem;
  align-items: center;
  flex-direction: column;

  @media (max-width: 728px) {
    padding: 1rem 0;
  }

  &:first-child {
    h2 {
      margin-top: 1rem;
    }
  }
`;

export const Carregando = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Conteudos = styled.div`
  display: flex;
  gap: 0 1rem;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  padding: 1rem;
`;

export const Conteudo = styled.div`
  display: flex;
  flex: 1;
  padding: 1rem;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
`;

export const Menu = styled.div`
  display: flex;
  border-bottom: 1px solid var(--cinza);
  width: 100%;
  align-items: center;

  span {
    margin-left: auto;
  }
`;

export const MenuItem = styled.div`
  padding: 1rem;
  color: var(--branco);
  font-weight: bold;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: var(--cinza);
  }

  &+& {
    border-left: 1px solid var(--cinza);
  }

  &.ativo {
    background-color: var(--cinza);
  }
`;

export const Itens = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-top: 1rem;
  width: 100%;
`;

export const Item = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  align-items: center;
  cursor: pointer;

  .dataConcluida {
    margin-left: auto;
    opacity: 0.5;
    font-size: 0.9rem;

    @media (max-width: 728px) {
      display: none;
    }
  }

  &.aPagar {
    background-color: rgba(255, 0, 0, 0.1);
    border: 1px solid rgba(255, 0, 0, 0.1);
    svg {
      rotate: 180deg;
    }
  }

  &.aReceber {
    background-color: rgba(0, 255, 0, 0.1);
    border: 1px solid rgba(0, 255, 0, 0.1);
  }

  &.finalizado {
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.05);
  }

  &.novo {
    display: flex;
    border: 2px dotted rgba(255, 255, 255, 0.5);
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.5);

    svg {
      font-size: 2rem;
    }
  }

  &:hover {
    opacity: 0.5;
  }
`;