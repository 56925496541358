import styled from 'styled-components';

interface ProjetoProps {
  tag: string;
}

export const ConteudoContainer = styled.section`
  width: 100%;
  transition: all 0.2s ease;
  background-color: var(--fundo);
  display: flex;
  justify-content: center;
  flex-direction: column;

  .titulo {
    margin: 0 auto;
    font-weight: 600;
    font-size: 1.5rem;
    width: fit-content;
    padding: 2rem 0;
    margin-top: 6rem;
  }
`;

export const ProjetoContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;

  border-top: 2px solid var(--branco);

  &:hover {
    .projetoEscondido {
      transform: scaleY(1);
    }
  }

  .projetoEscondido {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: var(--branco);
    z-index: 1;
    transform: scaleY(0);
    transition: all 0.4s ease-out;
    font-size: 2rem;

    @media (max-width: 1089px) {
      font-size: 1rem;
    }

    div {
      display: flex;
      align-items: center;
      color: var(--fundo);
      max-width: 1350px;
      justify-content: space-between;
      width: 100%;
      padding: 5rem;

      span {
        font-size: 1.5rem;

        @media (max-width: 1089px) {
          font-size: 0.8rem;
        }
      }
    }
  }
`;

export const Projeto = styled.div<ProjetoProps>`
  max-width: 1350px;
  margin: 0 auto;
  height: 10rem;
  display: flex;
  align-items: center;
  padding: 5rem;
  font-size: 2rem;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 1089px) {
    font-size: 1rem;
  }

  span {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 0.2rem;
    background: ${({ tag }) =>
      tag == 'Game'
        ? 'var(--azul)'
        : tag == 'Mobile'
        ? 'var(--rosa)'
        : 'var(--amarelo)'};
  }
`;
