import React, { useEffect, useState } from 'react';
import { BsArrowUpCircle, BsArrowDownCircle } from 'react-icons/bs';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { FiUsers, FiUser } from 'react-icons/fi';
import { TailSpin } from 'react-loader-spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Botao } from '../../Components/Botao';
import { Titulo } from '../../Components/Titulo';
import { db } from '../../config/firebase';
import titulos from '../../utils/titulos.json';
import {
  Container,
  Pessoas,
  Pessoa,
  ValorFaturaContainer,
  ValorFaturaConteudo,
  ValorFaturaTitulo,
  Linha,
  ValorFaturaPessoa,
  Tabela,
  Status,
  TbPessoa,
  Carregando,
  Botoes,
  Simplificado,
  NaoHaTransacoes,
  Pesquisa
} from './styles';

import {
  collection,
  getDocs,
  addDoc,
  doc,
  query,
  deleteDoc,
  updateDoc
} from 'firebase/firestore';
import { currency } from 'remask';
import { Input } from '../../Components/Input';

interface PessoaProps {
  id?: string;
  pessoa: string;
  cor: string;
}

export interface BancoProps {
  id: string;
  descricao: string;
  data: string;
  mensal: boolean;
  parcela_atual: number;
  parcela_total: number;
  pessoa: string;
  status: string;
  valor: number;
  comentario: string;
}

export function PaginaFaturaAtualBanco() {
  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [estaCarregando, setEstaCarregando] = useState(true);
  const [banco, setBanco] = useState<BancoProps[]>([]);
  const [pessoas, setPessoas] = useState<PessoaProps[]>([]);
  const [pessoasCompletas, setPessoasCompletas] = useState<PessoaProps[]>([]);
  const { id }: any = useParams();
  const [filtro, setFiltro] = useState<string[]>([]);
  const navigate = useNavigate();
  const [fechandoFatura, setFechandoFatura] = useState(false);
  const naoEstaEscondido = window.innerWidth >= 728;
  const [InputPesquisa, setInputPesquisa] = useState('');
  const [dadosFiltrados, setDadosFiltrados] = useState<BancoProps[]>([]);

  function arrumarFiltro(pessoa: string) {
    setFiltro((prevFiltro) => {
      const pessoaJaExiste = prevFiltro.includes(pessoa);

      if (pessoaJaExiste) {
        return prevFiltro.filter((item) => item !== pessoa);
      } else {
        return [...prevFiltro, pessoa];
      }
    });
  }

  async function buscarDados() {
    setEstaCarregando(true);
    const dadosCollection = collection(db, `fatura_atual_${id}`);
    const dadosQuerySnapshot = await getDocs(dadosCollection);
    const dadosArray: any = [];
    dadosQuerySnapshot.forEach((doc) => {
      dadosArray.push({ id: doc.id, ...doc.data() });
    });

    dadosArray.sort((dia: BancoProps, dia2: BancoProps) => dia2.data.localeCompare(dia.data));

    setBanco(dadosArray);

    const pessoasCollection = collection(db, 'pessoas');
    const pessoasQuerySnapshot = await getDocs(pessoasCollection);
    const pessoasArray: any = [];
    pessoasQuerySnapshot.forEach((doc) => {
      const pessoaData = doc.data();
      const pessoa: any = { id: doc.id, ...pessoaData };

      const pessoaExiste = dadosArray.find(
        (item: BancoProps) => item.pessoa === pessoa.pessoa
      );

      if (pessoaExiste) {
        const pessoaExistente = pessoasArray.find(
          (pessoa: any) => pessoa.pessoa === pessoaExiste.pessoa
        );

        if (!pessoaExistente) {
          pessoasArray.push(pessoaExiste);
        }
      }
    });

    const pessoasCompletasArray: any = [];
    pessoasQuerySnapshot.forEach((doc) => {
      pessoasCompletasArray.push({ id: doc.id, ...doc.data() });
    });

    setPessoasCompletas(pessoasCompletasArray);
    setPessoas(pessoasArray);
    setEstaCarregando(false);
  }

  function calcularSomaPorPessoa(pessoa: string) {
    return banco
      .filter((item: BancoProps) => filtro.includes(item.pessoa))
      .reduce((total, item: BancoProps) => {
        if (item.pessoa === pessoa) {
          return total + item.valor;
        }
        return total;
      }, 0);
  }

  function calcularSomaPorPessoaComStatusPago(pessoa: string) {
    return banco
      .filter(
        (item: BancoProps) =>
          filtro.includes(item.pessoa) && item.status === 'pago'
      )
      .reduce((total, item: BancoProps) => {
        if (item.pessoa === pessoa) {
          return total + item.valor;
        }
        return total;
      }, 0);
  }

  function calcularValorTotal() {
    return banco
      .filter((item: BancoProps) => filtro.includes(item.pessoa))
      .reduce((total, item: BancoProps) => total + item.valor, 0);
  }

  function calcularValorTotalComStatusPago() {
    return banco
      .filter(
        (item: BancoProps) =>
          filtro.includes(item.pessoa) && item.status === 'pago'
      )
      .reduce((total, item: BancoProps) => total + item.valor, 0);
  }

  function calcularRestantePorPessoa(pessoa: string) {
    const fatura = calcularSomaPorPessoa(pessoa);
    const adiantamento = calcularSomaPorPessoaComStatusPago(pessoa);
    return fatura - adiantamento;
  }

  function calcularValorTotalRestante() {
    const faturaTotal = calcularValorTotal();
    const adiantamentoTotal = calcularValorTotalComStatusPago();
    return faturaTotal - adiantamentoTotal;
  }

  function formatarData(data: string) {
    const partes = data.split('-');
    const dataFormatada = `${partes[2]}-${partes[1]}-${partes[0]}`;
    return dataFormatada;
  }

  async function fecharFatura(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();
    setFechandoFatura(true);

    const confirmacao = window.confirm(
      'Tem certeza de que deseja fechar a fatura?'
    );

    if (confirmacao) {
      const dataAtual = new Date();
      dataAtual.setMonth(dataAtual.getMonth() - 1);

      const nomeMes = new Intl.DateTimeFormat('pt-BR', {
        month: 'long'
      }).format(dataAtual);

      const nomeAno = new Intl.DateTimeFormat('pt-BR', {
        year: 'numeric'
      }).format(dataAtual);

      const faturaFechadaCollection = collection(db, `faturas_fechadas`);

      const querySnapshot = await getDocs(faturaFechadaCollection);

      let jaFoiFechada = false;

      for (const doc of querySnapshot.docs) {
        const data = doc.data();
        if (data.banco === id && data.mes === nomeMes && data.ano === nomeAno) {
          toast.warning('A fatura já foi fechada anteriormente.');
          setFechandoFatura(false);
          jaFoiFechada = true;
          break;
        }
      }

      if (jaFoiFechada) {
        return;
      }

      try {
        await addDoc(faturaFechadaCollection, {
          banco: id,
          mes: nomeMes,
          ano: nomeAno,
          transacoes: banco
        }).then(() => {
          addDoc(collection(db, 'logs'), {
            descricao: `Fechou a fatura do banco <span class='info'>${id}</span> referente ao mês de <span class='info'>${nomeMes}</span> de <span class='info'>${nomeAno}</span>.`,
            titulo: 'Fechamento de fatura',
            tipo: 'fatura',
            data: new Date()
          });
        }).then(() => {
          toast.success('Fatura fechada com sucesso.');
        });
      } catch (error) {
        toast.error('Aconteceu algum erro. Tente novamente mais tarde.');
        return;
      }
      const faturaAtualCollection = collection(db, `fatura_atual_${id}`);
      const q = query(faturaAtualCollection);
      const documentosParaAtualizarOuRemover = await getDocs(q);

      documentosParaAtualizarOuRemover.forEach(async (documento) => {
        const data = documento.data();

        if (data.parcela_atual === data.parcela_total && !data.mensal) {
          await deleteDoc(doc(faturaAtualCollection, documento.id));
        } else if (!data.mensal) {
          await updateDoc(doc(faturaAtualCollection, documento.id), {
            parcela_atual: data.parcela_atual + 1
          });
        }
      });
      await buscarDados();
    }
    setFechandoFatura(false);
  }

  function moveParaPaginaEditarTransacao(banco: BancoProps) {
    navigate('/transacao', {
      state: {
        banco,
        pessoas: pessoasCompletas,
        nomeBanco: id
      }
    });
  }

  function checkSomenteNumericos(valor: string) {
    const numero = parseFloat(valor);
    return !isNaN(numero);
  }

  function checkFormatoData(input: string) {
    return /^\d{2}-\d{2}-\d{4}$/.test(input);
  }

  function converterFormatoData(data: string) {
    return data.split('-').reverse().join('-');
  }

  useEffect(() => {
    buscarDados();
    document.title = titulos.texto.fatura_atual;
  }, []);

  useEffect(() => {
    if (filtro.length === 0) {
      pessoas.forEach((pessoa: PessoaProps) =>
        setFiltro((prevFiltro) => {
          return [...prevFiltro, pessoa.pessoa];
        })
      );
    }
  }, [filtro, pessoas]);


  useEffect(() => {
    if (InputPesquisa == '') {
      setDadosFiltrados(banco);
    } else if (checkFormatoData(InputPesquisa)) {
      const dataFormatada = converterFormatoData(InputPesquisa);
      const dadosFiltrados = banco.filter((item) =>
        item.data === dataFormatada
      );
      setDadosFiltrados(dadosFiltrados);
    } else if (checkSomenteNumericos(InputPesquisa)) {
      const dadosFiltrados = banco.filter((item) =>
        item.valor.toString().includes(InputPesquisa)
      );
      setDadosFiltrados(dadosFiltrados);
    } else {
      const dadosFiltrados = banco.filter((item) =>
        item.descricao.toLowerCase().includes(InputPesquisa.toLowerCase())
      );
      setDadosFiltrados(dadosFiltrados);
    }
  }, [InputPesquisa, banco]);

  return (
    <Container>
      <Titulo voltar>{id.charAt(0).toUpperCase() + id.slice(1)}</Titulo>

      {estaCarregando ? (
        <Carregando>
          <TailSpin height="50" width="50" color="#ffffff" />
        </Carregando>
      ) : (
        <>
          <Pessoas>
            {pessoas.map((pessoa: PessoaProps) => (
              <Pessoa
                ativo={
                  filtro.includes(pessoa.pessoa)
                    ? 'ativo'
                    : filtro.length == 0
                      ? 'ativo'
                      : ''
                }
                key={pessoa.id}
                onClick={() => arrumarFiltro(pessoa.pessoa)}
              >
                <FiUser />
                <span>{pessoa.pessoa}</span>
              </Pessoa>
            ))}

            <Pessoa
              ativo={
                filtro.length == pessoas.length
                  ? 'ativo'
                  : filtro.length != 0
                    ? ''
                    : 'ativo'
              }
              onClick={() => setFiltro([])}
            >
              <FiUsers />
              <span>Todos</span>
            </Pessoa>
          </Pessoas>
          <ValorFaturaContainer>
            <ValorFaturaConteudo>
              <ValorFaturaTitulo cor="azul">
                <span>Fatura</span>
                <FaRegMoneyBillAlt />
              </ValorFaturaTitulo>
              <Linha />
              {filtro.map((pessoa) => (
                <ValorFaturaPessoa key={pessoa}>
                  <h3>{pessoa}:</h3>
                  <span>
                    {currency.mask({
                      locale: 'pt-BR',
                      currency: 'BRL',
                      value: calcularSomaPorPessoa(pessoa)
                    })}
                  </span>
                </ValorFaturaPessoa>
              ))}
              <Linha />
              <ValorFaturaPessoa>
                <h3>Total: </h3>
                <span>
                  {currency.mask({
                    locale: 'pt-BR',
                    currency: 'BRL',
                    value: calcularValorTotal()
                  })}
                </span>
              </ValorFaturaPessoa>
            </ValorFaturaConteudo>

            <ValorFaturaConteudo>
              <ValorFaturaTitulo cor="verde">
                <span>Adiantamento</span>
                <BsArrowUpCircle />
              </ValorFaturaTitulo>
              <Linha />
              {filtro.map((pessoa) => (
                <ValorFaturaPessoa key={pessoa}>
                  <h3>{pessoa}:</h3>
                  <span>
                    {currency.mask({
                      locale: 'pt-BR',
                      currency: 'BRL',
                      value: calcularSomaPorPessoaComStatusPago(pessoa)
                    })}
                  </span>
                </ValorFaturaPessoa>
              ))}
              <Linha />
              <ValorFaturaPessoa>
                <h3>Total: </h3>
                <span>
                  {currency.mask({
                    locale: 'pt-BR',
                    currency: 'BRL',
                    value: calcularValorTotalComStatusPago()
                  })}
                </span>
              </ValorFaturaPessoa>
            </ValorFaturaConteudo>

            <ValorFaturaConteudo>
              <ValorFaturaTitulo cor="vermelho">
                <span>Restante</span>
                <BsArrowDownCircle />
              </ValorFaturaTitulo>
              <Linha />
              {filtro.map((pessoa) => (
                <ValorFaturaPessoa key={pessoa}>
                  <h3>{pessoa}:</h3>
                  <span>
                    {currency.mask({
                      locale: 'pt-BR',
                      currency: 'BRL',
                      value: calcularRestantePorPessoa(pessoa)
                    })}
                  </span>
                </ValorFaturaPessoa>
              ))}
              <Linha />
              <ValorFaturaPessoa>
                <h3>Total: </h3>
                <span>
                  {currency.mask({
                    locale: 'pt-BR',
                    currency: 'BRL',
                    value: calcularValorTotalRestante()
                  })}
                </span>
              </ValorFaturaPessoa>
            </ValorFaturaConteudo>
          </ValorFaturaContainer>

          <Botoes>
            <Botao
              cor="azul"
              texto="Fechar fatura atual"
              onClick={(event) => fecharFatura(event)}
              estaCarregando={fechandoFatura}
              style={{ color: 'var(--branco)' }}
            />
            <Botao
              cor="primario"
              texto="Nova transação"
              onClick={() =>
                navigate(`/transacao`, {
                  state: {
                    nomeBanco: id,
                    pessoas: pessoasCompletas
                  }
                })
              }
              estaCarregando={fechandoFatura}
            />
          </Botoes>
          <Simplificado
            onClick={() =>
              navigate(`/faturaatual/simplificado`, {
                state: {
                  banco: banco.filter((banco: BancoProps) =>
                    filtro.includes(banco.pessoa)
                  ),
                  pessoas,
                  nomeBanco: id,
                  pessoasCompletas
                }
              })
            }
          >
            Pagina Simplificada
          </Simplificado>
          <Pesquisa>
            <Input
              value={InputPesquisa}
              onChange={(e) => setInputPesquisa(e.target.value)}
              titulo="Pesquisar por valor, descrição ou data"
            />
          </Pesquisa>
          <Tabela>
            <thead>
              <tr>
                <th>{naoEstaEscondido ? 'Status' : ''}</th>
                <th>Valor</th>
                <th>Descrição</th>
                <th>{naoEstaEscondido ? 'P.Atual' : 'P.A'}</th>
                <th></th>
                <th>{naoEstaEscondido ? 'P.Total' : 'P.T'}</th>
                {naoEstaEscondido && <th>Data</th>}
                <th>Pessoa</th>
                {naoEstaEscondido && <th></th>}
              </tr>
            </thead>
            <tbody>
              {dadosFiltrados.length !== 0 &&
                <>
                  {dadosFiltrados
                    .filter((banco: BancoProps) => filtro.includes(banco.pessoa))
                    .map((banco: BancoProps) => (
                      <tr
                        className={checkedItems[banco.id] ? 'checked' : ''}
                        key={banco.id}
                      >
                        <td onClick={() => moveParaPaginaEditarTransacao(banco)}>
                          <Status status={banco.status} />
                        </td>
                        <td onClick={() => moveParaPaginaEditarTransacao(banco)}>
                          {currency.mask({
                            locale: 'pt-BR',
                            currency: 'BRL',
                            value: banco.valor
                          })}
                        </td>
                        <td onClick={() => moveParaPaginaEditarTransacao(banco)}>
                          {banco.descricao}
                        </td>
                        <td onClick={() => moveParaPaginaEditarTransacao(banco)}>
                          {banco.parcela_atual}
                        </td>
                        <td onClick={() => moveParaPaginaEditarTransacao(banco)}>
                          x
                        </td>
                        <td onClick={() => moveParaPaginaEditarTransacao(banco)}>
                          {banco.parcela_total}
                        </td>
                        {naoEstaEscondido && (
                          <td onClick={() => moveParaPaginaEditarTransacao(banco)}>
                            {formatarData(banco.data)}
                          </td>
                        )}
                        <td onClick={() => moveParaPaginaEditarTransacao(banco)}>
                          <TbPessoa
                            cor={
                              pessoasCompletas.find(
                                (p: any) => p.pessoa === banco.pessoa
                              )?.cor ?? 'azul'
                            }
                          >
                            {banco.pessoa}
                          </TbPessoa>
                        </td>
                        {naoEstaEscondido && (
                          <td>
                            <input
                              type="checkbox"
                              checked={checkedItems[banco.id] || false}
                              onChange={(event) =>
                                setCheckedItems({
                                  ...checkedItems,
                                  [banco.id]: event.target.checked
                                })
                              }
                            />
                          </td>
                        )}
                      </tr>
                    ))
                  }
                </>
              }
            </tbody>
          </Tabela>
          {dadosFiltrados.length === 0 && <NaoHaTransacoes>Não possui transações nessa fatura.</NaoHaTransacoes>}
        </>
      )}
    </Container>
  );
}
