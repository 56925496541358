import React from 'react';
import { ProgressBar } from 'react-loader-spinner';

import { Container } from './styles';

export function PaginaLoading() {
  return (
    <Container>
      <ProgressBar
        height="80"
        width="80"
        borderColor="#ffffff"
        barColor="#ffffff"
      />
    </Container>
  );
}
