import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 63.5%;

  textarea {
    padding: 1rem;
    background: var(--fundo);
    color: var(--branco);
    border-width: 0px;
    border-radius: 0.2rem;
    border: 1px solid var(--cinza);
    width: 100%;
  }

  label {
    position: absolute;
    color: var(--branco);
    opacity: 0.8;
    pointer-events: none;
    left: 1rem;
    top: 1rem;
    font-size: 0.9rem;

    transition: all 0.2s ease;
  }

  textarea:focus,
  textarea:not([value='']) {
    border-color: var(--branco);
  }

  textarea:focus ~ label,
  textarea:not([value='']) ~ label {
    transform: scale(0.9) translateY(-2.2rem);
    background-color: var(--fundo);
    padding: 0.4rem 0.6rem;
    opacity: 1;
    border-radius: 0.2rem;
  }
`;
