import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  .tiptap {
    border: 1px solid var(--cinza);
    border-radius: 0.5rem;
    padding: 1rem;
    min-height: 20rem;
    width: 100%;

    strong {
      font-weight: 700;
    }
    s {
      opacity: 0.5;
    }
    a {
      text-decoration: none;
      color: var(--primario);
    }
    blockquote {
      border-left: 4px solid var(--branco);
      padding: 0.5rem;
      background: var(--cinza);
      line-height: 1rem;
    }
    ol {
      margin-left: 2rem;

      ::marker {
        color: var(--primario);
        font-weight: 700;
      }
    }

    mark {
      padding: 0 0.5rem;
      border-radius: 0.25rem;
    }

    @media (max-width: 900px) {
      width: 100%;
    }
  }
`;