import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import { BiSend, BiDownload } from 'react-icons/bi';
import { TailSpin } from 'react-loader-spinner';

import { Container } from './styles';

interface BotaoProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  texto: string | ReactNode;
  cor: 'vermelho' | 'laranja' | 'verde' | 'azul' | 'cinza' | 'primario';
  estaCarregando?: boolean;
  contato?: boolean;
  download?: boolean;
}

export function Botao({
  texto,
  cor,
  estaCarregando,
  contato,
  download,
  ...rest
}: BotaoProps) {
  return (
    <Container {...rest} cor={cor} disabled={estaCarregando}>
      {estaCarregando ? (
        <TailSpin height="30" width="30" color="#ffffff" />
      ) : (
        texto
      )}

      {contato && <BiSend />}
      {download && <BiDownload />}
    </Container>
  );
}
