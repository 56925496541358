import React from 'react';
import { BiLogoCss3, BiLogoTypescript } from 'react-icons/bi';
import { FaAngular, FaPython, FaReact } from 'react-icons/fa';
import { RiJavascriptFill } from 'react-icons/ri';
import { SiNextdotjs } from "react-icons/si";

import { ConteudoContainer, TecIconsContainer } from './styles';

export function TecIconsSection() {
  return (
    <ConteudoContainer>
      <TecIconsContainer className="crescer">
        <FaReact />
        <SiNextdotjs />
        <FaAngular />
        <BiLogoTypescript />
        <RiJavascriptFill />
        <BiLogoCss3 />
        <FaPython />
      </TecIconsContainer>
    </ConteudoContainer>
  );
}
