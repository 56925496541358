import styled from 'styled-components';

interface CorProps {
  ativo: string;
  cor: string;
}

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  min-height: 100vh;
  padding: 1rem;
  align-items: center;
  flex-direction: column;
  position: fixed;
  background: var(--fundo);
  z-index: 999;
  overflow: auto;
  margin-top: -1rem;
`;

export const Form = styled.form`
  display: flex;
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;

  .meio {
    text-align: center;
  }

  .select {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    select {
      margin-left: 1rem;
      color: var(--branco);
      background-color: var(--fundo);
      text-transform: capitalize;
      height: 3.3rem;
      width: 9.4rem;
      border-radius: 0.2rem;
      border: none;
      text-align: center;
      margin-left: 0rem;
      border: 1px solid var(--cinza);
    }
  }
`;

export const InputParcela = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: calc(50% - 10px);
  align-items: center;

  div {
    display: flex;
    flex: 1;
    background: var(--fundo);
    border-radius: 0.2rem;
    border: 1px solid var(--cinza);

    input {
      text-align: center;
      background-color: transparent;
      color: var(--branco);
      font-size: 1rem;
      border: none;
      width: 2.5rem;
    }

    button {
      height: 100%;
      margin-top: -0.001rem;
      width: 2.2rem;
      height: 2.2rem;
      background: transparent;
      border-radius: 100%;
      border: none;
      font-size: 1.5rem;
    }
  }
`;

export const TipoTransacao = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1rem;
`;

export const Tipo = styled.button<CorProps>`
  margin-top: 0 !important;
  width: 8rem;
  height: 3rem;
  margin: 1rem;
  background: ${({ ativo, cor }) =>
    ativo == 'ativo' ? `var(--${cor})` : 'var(--fundo)'};

  border: ${({ ativo, cor }) =>
    ativo == 'ativo' ? `1px solid var(--${cor})` : '1px solid var(--cinza)'};
  border-radius: 0.2rem;
`;

export const Checks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 1.3rem;
`;

export const Util = styled.div`
  display: flex;
  width: 63.5%;
  align-items: center;
  justify-content: right;
  gap: 1rem;
  margin-bottom: 1rem;

  svg {
    font-size: 1.2rem;
    color: var(--azul);

    cursor: pointer;

    transition: all 0.2s ease;

    &:hover {
      filter: brightness(1.4);
    }
  }

  .svgDelete {
    color: var(--vermelho);
    font-size: 1.4rem;
  }
`;
