import React, { useEffect, useState } from 'react';
import {
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
  AiOutlineBook
} from 'react-icons/ai';
import { BiHomeAlt2 } from 'react-icons/bi';
import { CiBank, CiCreditCard2 } from 'react-icons/ci';
import { FiUserPlus } from 'react-icons/fi';
import { RiLogoutCircleLine } from 'react-icons/ri';
import { TbCards, TbHome } from 'react-icons/tb';
import { SlUserFemale } from "react-icons/sl";
import { HiOutlineClipboardList } from "react-icons/hi";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { IoWalletSharp } from "react-icons/io5";

import { auth } from '../../config/firebase';
import titulos from '../../utils/titulos.json';
import { Botao } from '../Botao';
import {
  Container,
  ParteDeCima,
  EsquerdaLogo,
  DireitaLogo,
  ParteDoMeio,
  ParteDeBaixo,
  Linha,
  Link
} from './styles';

import { signOut } from 'firebase/auth';

export function SideBar() {
  const [tituloDaPagina, setTituloDaPagina] = useState('');
  useEffect(() => {
    setTituloDaPagina(document.title);
  }, []);
  const [naoEstaEscondido, setNaoEstaEscondido] = useState(
    window.innerWidth >= 728
  );

  return (
    <Container>
      <ParteDeCima>
        <EsquerdaLogo>GC</EsquerdaLogo>
        {naoEstaEscondido && (
          <DireitaLogo>
            <h3>Gustavo Castro</h3>
            <h5>Gerenciamento cartão</h5>
          </DireitaLogo>
        )}

        {naoEstaEscondido ? (
          <AiOutlineArrowLeft
            onClick={() => setNaoEstaEscondido(!naoEstaEscondido)}
          />
        ) : (
          <AiOutlineArrowRight
            onClick={() => setNaoEstaEscondido(!naoEstaEscondido)}
          />
        )}
      </ParteDeCima>
      <Linha />
      <ParteDoMeio>
        <Link
          to="/principal"
          ativo={tituloDaPagina === titulos.texto.principal ? 'ativo' : ''}
          onClick={() => setTituloDaPagina(titulos.texto.principal)}
        >
          <BiHomeAlt2 />

          {naoEstaEscondido && <span>Principal</span>}
        </Link>
        <Link
          to="/faturaatual"
          ativo={tituloDaPagina === titulos.texto.fatura_atual ? 'ativo' : ''}
          onClick={() => setTituloDaPagina(titulos.texto.fatura_atual)}
        >
          <CiCreditCard2 />
          {naoEstaEscondido && <span>Fatura atual</span>}
        </Link>
        <Link
          to="/faturasfechadas"
          ativo={
            tituloDaPagina === titulos.texto.faturas_fechadas ? 'ativo' : ''
          }
          onClick={() => setTituloDaPagina(titulos.texto.faturas_fechadas)}
        >
          <TbCards />
          {naoEstaEscondido && <span>Faturas fechadas</span>}
        </Link>
        <Link
          to="/anotacoes"
          ativo={tituloDaPagina === titulos.texto.anotacoes ? 'ativo' : ''}
          onClick={() => setTituloDaPagina(titulos.texto.anotacoes)}
        >
          <AiOutlineBook />
          {naoEstaEscondido && <span>Anotações</span>}
        </Link>
        <Linha />
        <Link
          to="/adicionarbanco"
          ativo={
            tituloDaPagina === titulos.texto.adicionar_banco ? 'ativo' : ''
          }
          onClick={() => setTituloDaPagina(titulos.texto.adicionar_banco)}
        >
          <CiBank />
          {naoEstaEscondido && <span>Gerenciar banco</span>}
        </Link>
        <Link
          to="/adicionarpessoa"
          ativo={
            tituloDaPagina === titulos.texto.adicionar_pessoa ? 'ativo' : ''
          }
          onClick={() => setTituloDaPagina(titulos.texto.adicionar_pessoa)}
        >
          <FiUserPlus />
          {naoEstaEscondido && <span>Gerenciar pessoas</span>}
        </Link>
        <Link
          to="/gerenciamento"
          ativo={
            tituloDaPagina === titulos.texto.gerencimento ? 'ativo' : ''
          }
          onClick={() => setTituloDaPagina(titulos.texto.gerencimento)}
        >
          <IoWalletSharp />
          {naoEstaEscondido && <span>Gerenciamento</span>}
        </Link>
        <Link
          to="/log"
          ativo={
            tituloDaPagina === titulos.texto.log ? 'ativo' : ''
          }
          onClick={() => setTituloDaPagina(titulos.texto.log)}
        >
          <HiOutlineClipboardList />
          {naoEstaEscondido && <span>Logs</span>}
        </Link>
        <Link
          to="/contas"
          ativo={
            tituloDaPagina === titulos.texto.conta ? 'ativo' : ''
          }
          onClick={() => setTituloDaPagina(titulos.texto.conta)}
        >
          <FaMoneyBillTransfer />
          {naoEstaEscondido && <span>Contas</span>}
        </Link>
        <Link
          to="/gerenciamentocal"
          ativo={
            tituloDaPagina === titulos.texto.gerencimento_cal ? 'ativo' : ''
          }
          onClick={() => setTituloDaPagina(titulos.texto.gerencimento_cal)}
        >
          <SlUserFemale />
          {naoEstaEscondido && <span>Gerenciamento Cal</span>}
        </Link>
        <Linha />
        <Link to="/">
          <TbHome />
          {naoEstaEscondido && <span>Site</span>}
        </Link>
        <Link to="/cal">
          <SlUserFemale />
          {naoEstaEscondido && <span>Cal</span>}
        </Link>
      </ParteDoMeio>
      <ParteDeBaixo>
        <Linha />
        <Botao
          cor="primario"
          texto={naoEstaEscondido ? 'Deslogar' : <RiLogoutCircleLine />}
          onClick={() => signOut(auth)}
        />
      </ParteDeBaixo>
    </Container>
  );
}
