import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

import { BsBoxArrowInUpRight } from "react-icons/bs";

import {
  Container,
  Conteudo,
  Title,
  Checkbox,
  Status,
  Botoes
} from './styles'
import { Input } from '../Input';
import { Botao } from '../Botao';
import { currency } from 'remask';
import { toast } from 'react-toastify';
import { addDoc, collection, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { DadosProps } from '../../Pages/PaginaContas';

interface ModalNovaContaProps {
  itemParaEditar?: DadosProps | null;
  onClose: () => void;
}

export function ModalConta({ itemParaEditar, onClose }: ModalNovaContaProps) {
  const [nome, setNome] = useState(itemParaEditar ? itemParaEditar.nome : '');
  const [valor, setValor] = useState(
    itemParaEditar ? formatarValor(String(itemParaEditar.valor)) : '0'
  );
  const [data, setData] = useState(itemParaEditar ? itemParaEditar.data : format(new Date(), 'yyyy-MM-dd'));
  const [valorFormatado, setValorFormatado] = useState('');
  const [tipo, setTipo] = useState<'aReceber' | 'aPagar'>(itemParaEditar ? itemParaEditar.tipo : 'aReceber');
  const [status, setStatus] = useState<'finalizado' | 'naoFinalizado'>(itemParaEditar ? itemParaEditar.status : 'naoFinalizado');
  const [enviando, setEnviando] = useState(false);

  function lindarComEnvio(e: React.FormEvent) {
    e.preventDefault();

    const valorNormal = currency.unmask({
      locale: 'pt-BR',
      currency: 'BRL',
      value: String(valor)
    });

    if (!nome) {
      toast.error('Nome é obrigatório');
      return;
    } else if (valorNormal === 0) {
      toast.error('Valor é obrigatório');
      return;
    }

    setEnviando(true);
    addDoc(collection(db, 'contas'), {
      nome,
      valor: valorNormal,
      data,
      tipo,
      status,
      dataCriacao: new Date().toISOString(),
      dataAtualizacao: new Date().toISOString()
    }).then(() => {
      toast.success('Conta adicionada com sucesso');
      setEnviando(false);
      onClose();
    }
    ).catch((error) => {
      toast.error('Erro ao adicionar conta');
      console.error("Error adding document: ", error);
      setEnviando(false);
    });
  }

  async function lidarComEdicao(e: React.FormEvent) {
    e.preventDefault(); if (!itemParaEditar) {
      return;
    }

    setEnviando(true);
    try {
      const docRef = doc(db, `contas`, itemParaEditar.id);
      const updateData = {
        nome,
        valor: currency.unmask({
          locale: 'pt-BR',
          currency: 'BRL',
          value: String(valor)
        }),
        data,
        tipo,
        status,
        dataAtualizacao: new Date().toISOString()
      };

      await updateDoc(docRef, updateData).then(() => {
        toast.success('Conta modificada com sucesso');
        setEnviando(false);
        onClose();
      }
      ).catch((error) => {
        toast.error('Erro ao modificar conta');
        console.error("Error updating document: ", error);
        setEnviando(false);
      });
    } catch (error) {
      toast.error('Erro ao modificar conta');
      console.error("Error updating document: ", error);
      setEnviando(false);
    };
  }

  function lidarComDelete() {
    if (!itemParaEditar) return;
    setEnviando(true);

    const confirmar = window.confirm('Tem certeza que deseja excluir esta conta?');

    if (!confirmar) {
      setEnviando(false);
      return;
    }

    const docRef = doc(db, `contas`, itemParaEditar.id);

    deleteDoc(docRef).then(() => {
      toast.success('Conta excluída com sucesso');
      setEnviando(false);
      onClose();
    }
    ).catch((error) => {
      toast.error('Erro ao excluir conta');
      console.error("Error deleting document: ", error);
      setEnviando(false);
    });
  }


  function formatarValor(valor: string) {
    if (valor.indexOf('.') === -1) {
      return valor + '.00';
    } else if (valor.match(/\.\d$/)) {
      return valor + '0';
    } else {
      return valor;
    }
  }

  useEffect(() => {
    const formatadoTemp = currency.unmask({
      locale: 'pt-BR',
      currency: 'BRL',
      value: String(valor)
    });

    setValorFormatado(
      currency.mask({
        locale: 'pt-BR',
        currency: 'BRL',
        value: formatadoTemp
      })
    );
  }, [valor]);

  return (
    <Container>
      <Conteudo>
        <Title>
          <h1>{itemParaEditar ? 'Editar' : 'Nova'} conta</h1>
          <span onClick={onClose}><BsBoxArrowInUpRight /></span>
        </Title>

        <form onSubmit={itemParaEditar ? lidarComEdicao : lindarComEnvio}>
          <Input titulo='Nome' value={nome} onChange={(e) => setNome(e.target.value)} />
          <Input titulo='Valor' value={valorFormatado} onChange={(e) => setValor(e.target.value)} />
          <Input
            type="date"
            value={data}
            onChange={(e) => setData(e.target.value)}
            titulo="Data agendada"
            className="meio"
          />

          <Checkbox>
            <label>
              <input
                type="radio"
                value="aReceber"
                checked={tipo === 'aReceber'}
                onChange={(e) => setTipo(e.target.value as 'aReceber' | 'aPagar')}
              />
              A receber
            </label>
            <label>
              <input
                type="radio"
                value="aPagar"
                checked={tipo === 'aPagar'}
                onChange={(e) => setTipo(e.target.value as 'aReceber' | 'aPagar')}
              />
              A pagar
            </label>
          </Checkbox>

          <Status className={status} onClick={() => {
            if (status === 'finalizado') {
              setStatus('naoFinalizado');
            } else {
              setStatus('finalizado');
            }

          }}>
            <h2>{status === 'finalizado' ? 'Finalizado' : 'Não finalizado'}</h2>
          </Status>

          <Botoes>
            <Botao onClick={onClose} texto='Cancelar' cor='azul' style={{ flex: '1', color: 'var(--branco)' }} estaCarregando={enviando} />
            {itemParaEditar && (
              <Botao type='button' cor='vermelho' texto={'Deletar'} style={{ flex: '1', color: 'var(--branco)' }} estaCarregando={enviando} onClick={() => lidarComDelete()} />
            )}
            <Botao type="submit" cor='primario' texto={itemParaEditar ? 'Modificar' : 'Salvar'} style={{ flex: '1' }} estaCarregando={enviando} />
          </Botoes>
        </form>
      </Conteudo>

    </Container>
  );
}