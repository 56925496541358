import React, { useEffect, useState } from 'react';
import bcrypt from 'bcryptjs';

import {
  Container,
  Pessoa,
  Pessoas,
  PasswordContainer,
  PasswordContent
} from './styles'
import { Titulo } from '../../Components/Titulo';
import titulos from '../../utils/titulos.json';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { Carregando } from '../PaginaAnotacoes/styles';
import { TailSpin } from 'react-loader-spinner';
import { FiUser, FiUsers } from 'react-icons/fi';
import { BancoHeader } from "../../Components/BancoHeader";
import { Input } from "../../Components/Input";
import { Botao } from "../../Components/Botao";
import { toast } from "react-toastify";

interface PaginaCalProps { }

interface BancoProps {
  id: string;
  banco: string;
  cor: string;
  valor: string;
  pessoa: string;
}

interface PessoaProps {
  id?: string;
  pessoa: string;
  cor: string;
}

interface PessoaCalProps {
  id: string;
  nome: string;
}

interface FaturaProps {
  id: string;
  descricao: string;
  data: string;
  mensal: boolean;
  parcela_atual: number;
  parcela_total: number;
  pessoa: string;
  status: string;
  valor: number;
  comentario: string;
  banco: string;
}

export function PaginaCal({ }: PaginaCalProps) {
  const [bancos, setBancos] = useState<BancoProps[]>([]);
  const [estaCarregando, setEstaCarregando] = useState(true);
  const [pessoas, setPessoas] = useState<PessoaProps[]>([]);
  const [pessoasVisiveis, setPessoasVisiveis] = useState<PessoaProps[]>([]);
  const [filtro, setFiltro] = useState<string[]>([]);
  const [faturas, setFaturas] = useState<{ [key: string]: FaturaProps[] }>({});
  const [senha, setSenha] = useState('');
  const [calLogada, setCalLogada] = useState(localStorage.getItem('calLogada') === 'true' ? true : false);

  async function buscarBancos() {
    const pessoasCalColletion = collection(db, 'pessoasCal');
    const pessoasCalQuerySnapshot = await getDocs(pessoasCalColletion);
    const pessoasCalArray: any = [];
    pessoasCalQuerySnapshot.forEach((doc) => {
      pessoasCalArray.push({ id: doc.id, ...doc.data() });
    });

    const nomes = pessoasCalArray.map((obj: PessoaCalProps) => obj.nome);
    const pessoasColletion = collection(db, 'pessoas');
    const pessoasQuerySnapshot = await getDocs(query(pessoasColletion, where('pessoa', 'in', nomes)));
    const pessoasArray: any = [];
    pessoasQuerySnapshot.forEach((doc) => {
      pessoasArray.push({ id: doc.id, ...doc.data() });
    });
    setPessoas(pessoasArray);

    const bancosCollection = collection(db, 'bancos');
    const bancosQuerySnapshot = await getDocs(bancosCollection);
    const bancosArray: any = [];
    bancosQuerySnapshot.forEach((doc) => {
      bancosArray.push({ id: doc.id, ...doc.data() });
    });

    const tempBancos: BancoProps[] = [];
    const tempFaturas: any = []
    for (const banco of bancosArray) {
      const bancoFaturaCollection = collection(db, `fatura_atual_${banco.banco}`);
      const bancoFaturaQuerySnapshot = await getDocs(query(bancoFaturaCollection, where('pessoa', 'in', nomes)));

      if (bancoFaturaQuerySnapshot.docs.length === 0) {
        continue;
      } else {
        tempBancos.push(banco);

        bancoFaturaQuerySnapshot.forEach((doc) => {
          if (!tempFaturas.hasOwnProperty(banco.banco)) {
            tempFaturas[banco.banco] = [];
          }

          tempFaturas[banco.banco].push({
            id: doc.id, ...doc.data()
          });
        });

        tempFaturas[banco.banco].sort((a: FaturaProps, b: FaturaProps) => {
          return new Date(b.data).getTime() - new Date(a.data).getTime();
        });
      }
    }
    setBancos(tempBancos);
    setFaturas(tempFaturas);

    setEstaCarregando(false);
  }

  function arrumarFiltro(pessoa: string) {
    setFiltro((prevFiltro) => {
      const pessoaJaExiste = prevFiltro.includes(pessoa);

      if (pessoaJaExiste) {
        const novoFiltro = prevFiltro.filter((item) => item !== pessoa);
        setPessoasVisiveis((prevPessoas) => {
          if (novoFiltro.length === 0) {
            return pessoas;
          }
          return pessoas.filter((p) => novoFiltro.includes(p.pessoa));
        });
        return novoFiltro;
      } else {
        const novoFiltro = [...prevFiltro, pessoa];
        setPessoasVisiveis((prevPessoas) => {
          return pessoas.filter((p) => novoFiltro.includes(p.pessoa));
        });
        return novoFiltro;
      }
    });
  }

  async function hashPassword(e: any, tempSenha: string) {
    e.preventDefault();
    try {
      const calCollection = collection(db, 'cal');
      const calQuerySnapshot = await getDocs(calCollection);
      const calCalArray: any = [];
      calQuerySnapshot.forEach((doc) => {
        calCalArray.push({ id: doc.id, ...doc.data() });
      });

      if (calCalArray.length === 0) {
        toast.error("Nenhuma senha encontrado.");
        return;
      }

      const hashedPasswordFromDB = calCalArray[0].senha;

      bcrypt.compare(tempSenha, hashedPasswordFromDB).then((result) => {
        if (result) {
          toast.success("Senha correta.");
          localStorage.setItem('calLogada', 'true');
          setCalLogada(true);
        } else {
          toast.error("Senha incorreta.");
        }
      }).catch((error) => {
        toast.error('Aconteceu algum erro, tente novamente.');
      });
    } catch (error) {
      toast.error('Aconteceu algum erro, tente novamente.');
    }
  }

  useEffect(() => {
    buscarBancos();
    document.title = titulos.texto.cal;
  }, []);

  useEffect(() => {
    if (filtro.length === 0) {
      setPessoasVisiveis(pessoas);
    }
  }, [filtro, pessoas]);

  if (!calLogada) return (
    <PasswordContainer>
      <PasswordContent onSubmit={(e) => hashPassword(e, senha)}>
        <h1>Digite a senha de acesso.</h1>
        <Input titulo={"Senha"} type={"password"} onChange={(e) => setSenha(e.target.value)} />
        <Botao cor={"primario"} texto={"Acessar"} type={"submit"} />
      </PasswordContent>
    </PasswordContainer>
  );

  return (
    <Container>
      <Titulo style={{ marginTop: '1rem' }}>{titulos.texto.cal}</Titulo>

      {estaCarregando ? (
        <Carregando>
          <TailSpin height="50" width="50" color="#ffffff" />
        </Carregando>
      ) : (
        <>
          <Pessoas>
            {pessoas.map((pessoa: PessoaProps) => (
              <Pessoa
                ativo={
                  filtro.includes(pessoa.pessoa)
                    ? 'ativo'
                    : filtro.length === 0
                      ? 'ativo'
                      : ''
                }
                key={pessoa.id}
                onClick={() => arrumarFiltro(pessoa.pessoa)}
              >
                <FiUser />
                <span>{pessoa.pessoa}</span>
              </Pessoa>
            ))}

            <Pessoa
              ativo={
                filtro.length === pessoas.length
                  ? 'ativo'
                  : filtro.length !== 0
                    ? ''
                    : 'ativo'
              }
              onClick={() => setFiltro([])}
            >
              <FiUsers />
              <span>Todos</span>
            </Pessoa>
          </Pessoas>

          {bancos.map((banco: BancoProps) => (
            <BancoHeader
              key={banco.id}
              banco={banco}
              tempFatura={faturas[banco.banco]}
              pessoasVisiveis={pessoasVisiveis.map((pessoa) => ({
                ...pessoa,
                id: pessoa.id || ""
              }))}
            />
          ))}
        </>
      )}

      <Botao
        cor="vermelho"
        texto="Sair"
        onClick={() => { localStorage.setItem('calLogada', 'false'); setCalLogada(false); }}
        style={{ width: '10rem', color: 'var(--branco)', height: '2rem' }}
      />
    </Container>
  );
}