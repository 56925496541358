import React from 'react';

import {
  ConteudoContainer,
  Conteudo,
  Esquerda,
  Meio,
  Direita,
  Experiencia
} from './styles';

export function ExperienciaSection() {
  return (
    <ConteudoContainer id="experiencia">
      <h1 className="titulo">Experiencia</h1>
      <Conteudo>
        <Experiencia className="crescer">
          <Esquerda>
            <h1>05/2023 ATÉ O MOMENTO</h1>
            <div className="bola" />
          </Esquerda>
          <Meio />
          <Direita>
            <h1>DESENVOLVEDOR ESTAGIÁRIO BACK-END</h1>
            <h3>NEXERRA</h3>
            <span>
              DAR APOIO NAS DEMANDAS A SEREM DESENVOLVIDAS POR MEIO DO SISTEMA
              JIRA. AUXILIAR NO DESENVOLVIMENTO DE ACORDO COM A DESCRIÇÃO E A
              LINGUAGEM INDICADA. DAR APOIO AOS ANALISTAS, DESENVOLVEDORES E
              DEMAIS ÁREAS. AUXILIAR AS REUNIÕES COM OUTROS SETORES PARA
              ALINHAMENTO TÉCNICO. DAR APOIO NAS DEMAIS ATIVIDADES REFERENTES AO
              CARGO.
            </span>
          </Direita>
        </Experiencia>
        <Experiencia className="crescer">
          <Esquerda>
            <h1>03/2020 ATÉ 03/2022</h1>
            <div className="bola" />
          </Esquerda>
          <Meio />
          <Direita>
            <h1>TECNOLOGIA DA INFORMAÇÃO</h1>
            <h3>SECRETÁRIA DA SAÚDE DE IMBITUBA</h3>
            <span>RESPONSÁVEL PELO T.I. DO SISTEMA DE SAÚDE DO MUNICÍPIO.</span>
          </Direita>
        </Experiencia>
      </Conteudo>
    </ConteudoContainer>
  );
}
