import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  min-height: 100vh;
  padding: 1rem;
  align-items: center;
  flex-direction: column;

  @media (max-width: 728px) {
    padding: 1rem 0;
  }

  .select {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;

    select {
      color: var(--branco);
      background-color: var(--fundo);
      text-transform: capitalize;
      height: 2rem;
      width: 9rem;
      border-radius: 0.2rem;
      border: none;
      text-align: center;
      margin-left: 0rem;
      border: 1px solid var(--cinza);
    }
  }
`;

export const Carregando = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const Logs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Log = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--cinza);  

  .data {
    font-size: 0.9rem;
    margin-top: 0.5rem;
    opacity: 0.5;
    text-align: right;
    border-top: 1px solid var(--cinza);
    padding-top: 0.5rem;
  }

  .logTitulo {
    font-size: 1.5rem;
    border-bottom: 1px solid var(--cinza);
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .tituloAntigo{
    text-decoration: line-through;
    padding: 0 0.5rem;
    background: rgba(255, 0, 0, 0.1);
    border: 1px dotted var(--vermelho);
    text-transform: capitalize;
  }

  .titulo {
      padding: 0 0.5rem;
      background: rgba(0, 255, 0, 0.1);
      border: 1px dotted var(--verde);
      text-transform: capitalize;
  }

  .info {
      padding: 0 0.5rem;
      background: rgba(0, 0, 255, 0.1);
      border: 1px dotted var(--azul);
      text-transform: capitalize;
  }

  .descricaoAntigo {
    text-decoration: line-through;
    padding: 0.5rem;
    border: 1px dotted var(--vermelho);
    opacity: 0.8;
    border-radius: 0.25rem;
    background: rgba(255, 0, 0, 0.1);
  }

  .descricao {
    padding: 0.5rem;
    border: 1px dotted var(--verde);
    border-radius: 0.25rem;
    background: rgba(0, 255, 0, 0.1);
  }

  .descricaoAntigo + .descricao {
    margin-top: 0.5rem;
  }

  strong {
      font-weight: 700;
    }
    s {
      opacity: 0.5;
    }
    a {
      text-decoration: none;
      color: var(--primario);
    }
    blockquote {
      border-left: 4px solid var(--branco);
      padding: 0.5rem;
      background: var(--cinza);
      line-height: 1rem;
    }
    ol {
      margin-left: 2rem;

      ::marker {
        color: var(--primario);
        font-weight: 700;
      }
    }
    mark {
      padding: 0 0.5rem;
      border-radius: 0.25rem;
    }
`;