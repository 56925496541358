import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;

  h1 {
    border-bottom: 1px solid var(--primario);
    height: fit-content;
    padding: 0 2rem;
    width: fit-content;
    text-align: center;
    color: var(--primario);
  }

  svg {
    position: absolute;
    left: 0;
    font-size: 2rem;
    top: 0.5rem;
    transition: all 0.2s ease;

    cursor: pointer;

    &:hover {
      color: var(--primario);
    }

    @media (max-width: 728px) {
      left: 0.8rem;
    }
  }
`;
