import React, { useEffect, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { PaginaLoading } from '../Components/PaginaLoading';

import { getAuth, onAuthStateChanged } from 'firebase/auth';

interface JaEstaAutenticadoProps {
  children: ReactNode;
}

export function JaEstaAutenticado({ children }: JaEstaAutenticadoProps) {
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const AuthCheck = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/principal');
      } else {
        setLoading(false);
      }
    });

    return () => AuthCheck();
  }, [auth, navigate]);

  if (loading) return <PaginaLoading />;

  return <>{children}</>;
}
