import React from 'react';

import { Container, CheckContainer, Label } from './styles';

interface CheckProps {
  onClick: () => void;
  ativo: boolean;
  texto?: string;
}

export function Check({ onClick, ativo, texto }: CheckProps) {
  return (
    <Container>
      <CheckContainer onClick={onClick} ativo={ativo} />
      <Label onClick={onClick}>{texto}</Label>
    </Container>
  );
}
