interface ProjetosProps {
  id: string;
  nome: string;
  tecnologias: string;
  tag: string;
}

export const projetos: ProjetosProps[] = [
  {
    id: '1',
    nome: 'Pokédex',
    tecnologias: 'ReactJs - ReactNative',
    tag: 'Web'
  },
  {
    id: '2',
    nome: 'Dashcard',
    tecnologias: 'ReactJs - Firebase',
    tag: 'Mobile'
  },
  {
    id: '3',
    nome: 'Zimbituba',
    tecnologias: 'Unity - C#',
    tag: 'Game'
  },
  {
    id: '4',
    nome: 'Origamid Dogs',
    tecnologias: 'Reactjs',
    tag: 'Web'
  },
  {
    id: '5',
    nome: 'Portfolio Antigo',
    tecnologias: 'Reactjs',
    tag: 'Web'
  }
];
